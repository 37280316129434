import React from "react";
import { useTranslation } from "react-i18next";
import Meta from "../../../components/common/Meta";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Top from "./components/Top";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Typography from "@mui/material/Typography";
import { ColumnCenteredBox } from "../../../utils/styledBox";
import Section3 from "./components/Section3";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { GradationButton } from "../../../utils/gradationButton";
import Section4 from "./components/Section4";

const AboutJuno = () => {
  const { t } = useTranslation();
  const loginContext = useLoginModalContext();
  const meta = [
    { name: "robots", content: "index, follow" },
    { name: "keywords", content: t("meta.juno.keywords") },
    { name: "description", content: t("meta.juno.description") },
  ];

  return (
    <>
      <Meta title={t("meta.juno.title")} meta={meta} />
      <Top />
      <Container maxWidth="lg">
        <Box sx={{ my: { xs: 2, md: 4 }, width: "100%" }}>
          <ColumnCenteredBox sx={{ width: "100%", gap: { xs: 8, md: 12 } }}>
            <Box
              sx={{
                backgroundImage: "url(/images/juno/about/juno-subtitle.jpg)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: 400,
                width: "100%",
                borderRadius: 2,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  flexDirection: "column",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  borderRadius: 2,
                  p: 2,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                }}
              >
                <Typography
                  variant={"h4"}
                  component={"h1"}
                  fontFamily={"'Noto Serif JP', roboto"}
                  fontWeight={"bold"}
                  mb={2}
                >
                  {t("aboutJuno.subtitle")}
                </Typography>
                <Typography
                  variant={"h6"}
                  component={"p"}
                  fontFamily={"'Noto Serif JP', roboto"}
                  fontWeight={"bold"}
                  sx={{ textAlign: "center" }}
                >
                  {t("aboutJuno.description")}
                </Typography>
              </Box>
            </Box>

            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />

            <GradationButton
              onClick={() => loginContext.setActiveModal("login")}
              sx={{ width: 200, alignSelf: "center", mb: 4 }}
            >
              {t("aboutJuno.try")}
            </GradationButton>
          </ColumnCenteredBox>
        </Box>
      </Container>
    </>
  );
};

export default AboutJuno;
