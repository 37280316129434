import Quill from "quill";
import Delta from "quill-delta";
import React, { useState } from "react";
import { Modal, Paper, TextField, Button, IconButton, Tooltip, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../context/AlertContext";
import { RowCenteredBox } from "../../../../utils/styledBox";
import InstagramIcon from "@mui/icons-material/Instagram";

interface QuillInterface extends Quill {
  import: (path: string) => any;
}

type InstagramEmbedProps = {
  quill: Quill | null;
};

type Range = {
  index: number;
  length: number;
};

const QuillClass = Quill as unknown as QuillInterface;
const BlockEmbed = QuillClass.import("blots/block/embed");

export class InstagramEmbedBlot extends BlockEmbed {
  static blotName = "instagram-embed";
  static tagName = "div";
  static className = "instagram-embed";

  static create(value: any) {
    const node = super.create();
    const iframe = document.createElement("iframe");
    iframe.setAttribute("width", "340");
    iframe.setAttribute("height", "520");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("scrolling", "no");
    iframe.setAttribute("allowtransparency", "true");
    iframe.setAttribute("allow", "encrypted-media");
    iframe.src = InstagramEmbedBlot.extractEmbedUrl(value.url);
    node.appendChild(iframe);
    return node;
  }

  static value(node: HTMLElement) {
    const iframe = node.querySelector("iframe");
    return {
      url: iframe ? iframe.src : "",
    };
  }

  static extractEmbedUrl(url: string): string {
    // Instagramの投稿およびリールのURLを抽出する
    const regExp = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/(p|reel|tv)\/([a-zA-Z0-9_-]+)/;
    const match = url.match(regExp);
    // 投稿またはリールにマッチした場合、埋め込みURLを返す
    if (match) {
      return `https://www.instagram.com/${match[1]}/${match[2]}/embed`;
    }
    return url;
  }
}

const InstagramEmbed: React.FC<InstagramEmbedProps> = ({ quill }) => {
  const [instaUrl, setInstaUrl] = useState("");
  const [embedModalOpen, setEmbedModalOpen] = useState(false);
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [savedRange, setSavedRange] = useState<Range | null>(null);

  const handleClick = () => {
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        setSavedRange(range);
      }
    }
    setEmbedModalOpen(true);
  };

  const insertInstagramEmbed = () => {
    if (!savedRange || !quill) return;

    const index = savedRange.index;
    const embedUrl = InstagramEmbedBlot.extractEmbedUrl(instaUrl);
    if (!embedUrl) {
      setAlert("error", "Invalid Instagram URL");
      return;
    }

    const delta = new Delta().retain(index).insert({ "instagram-embed": { url: instaUrl } });
    quill.updateContents(delta, "user");
    quill.setSelection(index + 1, 0);
    setInstaUrl("");
    setEmbedModalOpen(false);
  };

  return (
    <>
      <Tooltip title={t("textEditor.toolbars.instagram")}>
        <button onClick={handleClick}>
          <InstagramIcon fontSize={"small"} className={"ql-fill"} />
        </button>
      </Tooltip>

      <Modal open={embedModalOpen} onClose={() => setEmbedModalOpen(false)}>
        <Paper sx={{ p: 2, maxWidth: 400, margin: "auto", mt: 10, position: "relative" }}>
          <IconButton
            onClick={() => setEmbedModalOpen(false)}
            sx={{ position: "absolute", top: 5, right: 5 }}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <RowCenteredBox mb={2}>
              <InstagramIcon color="primary" fontSize={"large"} sx={{ mr: 1 }} />
              <Typography variant="h6">Instagram Embed</Typography>
            </RowCenteredBox>
            <TextField
              label="Instagram Post URL"
              placeholder="https://www.instagram.com/p/example or https://www.instagram.com/reel/example"
              value={instaUrl}
              onChange={(e) => setInstaUrl(e.target.value)}
              InputLabelProps={{ shrink: true }}
              autoFocus
            />
            <Button variant="contained" color="primary" onClick={insertInstagramEmbed}>
              Insert Instagram Embed
            </Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default InstagramEmbed;
