import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import ButtonBase from "@mui/material/ButtonBase";
import ArticleIcon from "@mui/icons-material/Article";

const NotesNebula = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }} mb={1}>
          <Typography
            variant={"h6"}
            component={"h3"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ArticleIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
            Notes Nebula
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Grid>
        <Grid item xs={12}>
          <ButtonBase
            sx={{
              width: "100%",
              height: 150,
              borderRadius: 2,
              backgroundImage: `url(/images/notes-nebula/top.jpg)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflow: "hidden",
              transition: "all 0.3s ease",
              position: "relative",
              "&:hover": {
                transform: "translateY(-5px)",
                "& div": {
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
              },
              "& div": {
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                transition: "all 0.3s ease",
              },
            }}
            onClick={() => navigate("/notes-nebula")}
          >
            {/* 暗いオーバーレイを追加 */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                transition: "all 0.3s ease",
              }}
            />
            <Box
              component={"div"}
              sx={{
                position: "relative",
                height: "100%",
                width: "100%",
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box color={"white"} mb={1}>
                <ArticleIcon />
              </Box>
              <Typography
                variant={"subtitle1"}
                component={"p"}
                sx={{
                  color: "white",
                  whiteSpace: "break-spaces",
                }}
              >
                {t("dashboard.notesNebula")}
              </Typography>
            </Box>
          </ButtonBase>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotesNebula;
