const textEditor = {
  title: "Tenak AI Text Editor",
  library: "Library (Unsaved content will be lost)",
  thumbnail: "Set the thumbnail image to display it",
  preview: "Preview",
  sidebar: {
    aiWritingAssistant: "Writing Assistant",
    aiWritingAssistantDescription: "Use the AI Writing Assistant to create text.",
    publicSettings: "Public Settings",
    publicSettingsDescription: "Change the public settings to share with other users.",
    publicStatus: {
      public: "Public",
      publicDescription: "It will be made public in the Notes Nebula.",
      private: "Private",
      privateDescription: "It will not be disclosed to anyone.",
      shared: "Shared Link",
      sharedDescription: "It will be made public only to those who know the shared link.",
    },
    thumbnail: "Thumbnail image",
    thumbnailDescription: "The thumbnail image is displayed at the top of the article. (1200 * 630px recommended)",
    upload: "Upload an image",
    generated: "Generated images",
    unsplash: "Unsplash",
    tags: "Hashtags",
    tagsPlaceholder: "Add with Enter key",
    language: "Language setting",
    languageDescription: "Please select the language of the article. This is the target user's language.",
    tagsDescription: "Add tags to share with other users.",
    target: "Target Audience (Optional)",
    additional: "Additional Instructions (Optional)",
    createTitles: "Titles",
    createNarration: "Narration",
    createIntroduction: "Introduction",
    createConclusion: "Conclusion",
    createTags: "Hashtags",
    createCustom: "Custom Messages",
    createContinue: "Continue text",
    createSummary: "Summary",
    createVoice: "Voice",
    createRephrase: "Rephrase",
    createLonger: "Extend Text",
    createText: "Text from Heading",
    createImages: "Image Generation",
    createTranslate: "AI Translation",
    createTone: "Tone Adjustment",
    createCopyWriting: "Copywriting",
    toneType: {
      typeFormal: "Formal",
      typeCasual: "Casual",
      typeHumorous: "Humorous",
      typeProfessional: "Professional",
      typePassionate: "Passionate",
      typeMen: "Masculine",
      typeWomen: "Feminine",
    },
    tagType: {
      instagram: "Instagram",
      twitter: "Twitter",
      facebook: "Facebook",
      linkedin: "LinkedIn",
      tiktok: "TikTok",
      youtube: "YouTube",
      pinterest: "Pinterest",
      x: "X (Twitter)",
      threads: "Threads",
    },
    customLabel: "Message",
    createTitlesDescription: "Generate multiple title ideas",
    createNarrationDescription: "Generate narration for videos from text",
    createIntroductionDescription: "Generate the introduction of the article",
    createConclusionDescription: "Generate the summary and conclusion of the article",
    createTagsDescription:
      "Please select the social media platform where you plan to post the tags or hashtags you're generating.",
    createCustomDescription: "Tenak AI can generate text for you.",
    createContinueDescription: "Generate continuation from the cursor position in the editor.",
    createSummaryDescription: "For high-precision summaries of long texts, use the summary generation tool.",
    createTextDescription:
      "Generate body text from headings and keywords. Please enter the headings or keywords below.",
    createRephraseDescription: "Please insert the text you want to rewrite.",
    createLongerDescription: "Extend short text (up to approximately 1,000 characters) into long text.",
    createTranslateDescription:
      "Unlike traditional translations, this understands the context. Please select the language for translation.",
    createToneDescription: "Please select the tone for the changed text.",
    createCheckText: "Check Text",
    createFAQ: "FAQ",
    createCopyWritingDescription: "Generate several headlines and Slogan from the text.",
    createVoiceDescription: "Please enter the text you want to vocalize.",
    createImagesDescription: "Automatically generate image prompts from text and generate a image.",
    createVoiceButton: "Save and move to the voice generator",
    createImagesButton: "Image Generator",
    maxLengthInfo: "For highly accurate results, messages should be under 1,000 characters.",
    copy: "Copy to Clipboard",
    replace: "Replace Selection",
    selection: "Source Text",
    response: "AI ANSWER",
    copyFromSelection: "Automatically insert selected text",
    generate: "Generate with Tenak AI",
    generateImage: "Generate with STUDIO JUNO",
    imageModel: "Image Generation AI Model",
    imageAnswer: {
      info: "Please note that the generated image is a low-resolution preview.",
      info2: "The actual image will be higher resolution.",
      goJuno: "Save and Go to STUDIO JUNO",
      insert: "Insert at Cursor Position",
    },
    error: {
      message: "Required fields have not been entered or selected.'",
    },
    clear: "Clear",
  },
  kreaVerse: {
    title: "KreaVerse",
    description:
      "KreaVerse is a text completion AI. Use [Alt + /] to understand the context from the cursor position and the AI will generate the completion text. (Consumes credits)\nThe generated text can be inserted with [TAB] or [Enter] key.",
    ready: "KreaVerse V1.1 Is Ready!",
    generating: "KreaVerse Is Generating...",
    waiting: "KreaVerse Generation Complete!",
    error: "Please enter a sentence of at least one sentence before using KreaVerse.",
  },
  unsplash: {
    title: "Add Image From Unsplash",
    description: "1,000,000+ free images",
    search: "Search",
    noResults: "No results found.",
  },
  toolbars: {
    heading: {
      lg: "Large Heading",
      md: "Medium Heading",
      sm: "Small Heading",
      normal: "Normal",
    },
    linkCard: "Link Card",
    upload: "Upload Image",
    unsplash: "Search images on Unsplash",
    generated: "My Generated Image",
    video: "YouTube or Video URL",
    instagram: "Instagram Embed",
    twitter: "X Embed",
  },
  processing: {
    title: "Generating...",
    description: "It will be displayed automatically once the generation is complete.",
  },
  save: "Save (cmd + s)",
};
export default textEditor;
