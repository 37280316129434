import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSubscription from "../../../../hooks/useSubscription";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
import { useParamsContext } from "../../../../context/juno/ParamsContext";

export default function SelectPublic() {
  const { t } = useTranslation();
  const { isPublic, setIsPublic } = useParamsContext();
  const [currentPlan, setCurrentPlan] = React.useState("free");
  const { getSubscription } = useSubscription();

  useEffect(() => {
    getSubscription().then((res) => {
      setCurrentPlan(res.subscription?.lookup_key);
    });
  }, []);

  return (
    <Box mb={4}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="body2">{t("juno.input.publicStatus")}</Typography>
        <Tooltip title={t("juno.input.publicDescription")} placement="top">
          <IconButton size="small" sx={{ ml: 2 }}>
            <InfoIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </Box>
      <ButtonGroup disableElevation variant="contained" aria-label="Disabled button group" fullWidth>
        <Button
          variant={isPublic ? "outlined" : "contained"}
          onClick={() => setIsPublic(false)}
          disabled={!["standard", "premium"].includes(currentPlan)}
        >
          {t("juno.input.private")}
        </Button>
        <Button variant={isPublic ? "contained" : "outlined"} onClick={() => setIsPublic(true)}>
          {t("juno.input.public")}
        </Button>
      </ButtonGroup>
    </Box>
  );
}
