import React from "react";
import { Box, Grid, Paper, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import ChatIcon from "@mui/icons-material/Chat";
import Grow from "@mui/material/Grow";
import ButtonBase from "@mui/material/ButtonBase";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { useAlert } from "../../../context/AlertContext";
import { useBrowsingMethods } from "../../../hooks/useBrowsingMethods";

const Browsing = ({ data, getData }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();
  const { addNewWorkspace } = useBrowsingMethods();

  const handleAddWorkspace = async () => {
    try {
      const res = await addNewWorkspace();
      if (!res) return;
      getData().then(() => {
        setAlert("success", t("browsing.createWorkspace"));
        navigate(res.data.path);
      });
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h6"}
          component={"h3"}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            mb: 2,
            width: "100%",
          }}
        >
          <ChatIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
          {t("dashboard.search")}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {data &&
          data.work_spaces.map((ws: any, index: number) => {
            return (
              <Grid item xs={6} sm={2.4} key={index}>
                <ButtonBase
                  onClick={() => navigate("/workspace/" + ws.uuid)}
                  sx={{
                    transition: "0.3s",
                    width: "100%",
                    "&:hover": {
                      transform: "translateY(-5px)",
                    },
                  }}
                >
                  <Grow in={true} timeout={(index + 1) * 500}>
                    <Paper
                      elevation={theme.palette.mode === "dark" ? 1 : 0}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: 150,
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          height: "5px",
                          width: "100%",
                          background: theme.custom.gradient.main,
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          height: 140,
                          p: 1,
                          mt: 1,
                        }}
                      >
                        <Typography
                          variant={"subtitle2"}
                          component={"p"}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            mb: 1,
                            textAlign: "left",
                          }}
                        >
                          {ws.name}
                        </Typography>
                        <Typography
                          variant={"caption"}
                          component={"p"}
                          sx={{
                            mb: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                            textAlign: "left",
                          }}
                        >
                          {ws.latest_history.replace(/#|\*|--|\|/g, "")}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grow>
                </ButtonBase>
              </Grid>
            );
          })}
        {data && data.work_spaces.length < 5 && (
          <Grid item xs={6} sm={2.4}>
            <ButtonBase
              onClick={handleAddWorkspace}
              sx={{
                transition: "0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Grow in={true} timeout={500 * (data.work_spaces.length + 1)}>
                <Paper
                  elevation={theme.palette.mode === "dark" ? 1 : 0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 150,
                    border: "2px dashed" + theme.palette.primary.main,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      m: 1,
                    }}
                  >
                    <Typography variant={"subtitle1"} textAlign={"center"} component={"p"} sx={{ mb: 1 }}>
                      Add a new Workspace
                    </Typography>
                    <AddIcon sx={{ mb: 1, color: theme.palette.primary.main }} />
                    <Typography variant={"caption"} component={"p"}>
                      {t("dashboard.addWorkspace")}
                    </Typography>
                  </Box>
                </Paper>
              </Grow>
            </ButtonBase>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default Browsing;
