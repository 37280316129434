import React from "react";

import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import { ColumnCenteredBox, RowCenteredBox } from "../../../../utils/styledBox";
import Typography from "@mui/material/Typography";
import { GradientTypography } from "../../../../utils/gradientTypography";
import Box from "@mui/material/Box";
import i18n from "i18next";
import Paper from "@mui/material/Paper";

const WhatIs = () => {
  const { t } = useTranslation();
  const lang = i18n.language;

  return (
    <Container maxWidth="lg" sx={{ my: 8 }}>
      <ColumnCenteredBox my={4}>
        <RowCenteredBox mb={4} alignItems={"baseline!important"}>
          <Typography variant={"h5"} component={"h2"} fontFamily={"'Noto Serif JP', roboto"} fontWeight={"bold"} mr={2}>
            What is
          </Typography>
          <GradientTypography
            variant={"h4"}
            component={"h2"}
            fontFamily={"'Pacifico',cursive"}
            fontWeight={"500"}
            pr={1}
          >
            Tenak.ai
          </GradientTypography>
          <Typography variant={"h5"} component={"h2"} fontFamily={"'Noto Serif JP', roboto"} fontWeight={"bold"}>
            ?
          </Typography>
        </RowCenteredBox>
        <img src={"/images/logo/logoZI-small.png"} alt={"tenakai"} style={{ width: "64px", height: "64px" }} />
        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "center", mt: 4 }}>
          <Box
            sx={{
              width: { xs: "100%", sm: "66%" },
              borderRadius: { xs: "8px 8px 0 0", sm: "8px 0 0 8px" },
              overflow: "hidden",
              height: "auto",
            }}
          >
            <img
              src={`/images/top/what_is_${lang}.jpg`}
              alt={"about tenak ai"}
              style={{ width: "100%", height: "auto", display: "block" }}
            />
          </Box>
          <Paper
            elevation={0}
            sx={{
              width: { xs: "100%", sm: "34%" },
              bgcolor: (theme) => theme.palette.background.paper,
              borderRadius: { xs: "0 0 8px 8px", sm: "0 8px 8px 0" },
              p: { xs: 2, sm: 3 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "auto",
            }}
          >
            <Typography variant={"h5"} component={"h3"} fontWeight={"bold"} fontFamily={"Noto serif JP"} mb={4}>
              {t("top.fv.whatIsTenak")}
            </Typography>
            <Typography paragraph>{t("top.fv.detail")}</Typography>
          </Paper>
        </Box>
      </ColumnCenteredBox>
    </Container>
  );
};
export default WhatIs;
