import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Box, Button, Divider, IconButton, Popover, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleRecraftV3 } from "../../../../types/junoTypes";
import SelectPublic from "./SelectPublic";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParamsContext } from "../../../../context/juno/ParamsContext";
import CropDinIcon from "@mui/icons-material/CropDin";
import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import RenderAspectRatioIcons from "./RenderAspectRatioIcons";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { ChromePicker } from "react-color";
import { alpha } from "@mui/system";

export default function ParamsRecraft() {
  const { t } = useTranslation();
  const { style, setStyle, colorsRecraft, setColorsRecraft } = useParamsContext();

  const params = new URLSearchParams(window.location.search);
  const ai_engine = params.get("ai_engine") || "";
  const menu = params.get("menu") || "txt2img";

  const aspectRatios = [
    { ratio: "1:1", icon: CropDinIcon },
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "4:3", icon: Crop32Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
    { ratio: "3:4", icon: Crop32Icon, rotate: "90deg" },
  ];

  const styleGroups = [
    {
      options: [{ value: "any", label: t("juno.input.style.recraft.any") }],
    },
    {
      options: [
        { value: "realistic_image", label: t("juno.input.style.recraft.realisticImage") },
        { value: "realistic_image/b_and_w", label: t("juno.input.style.recraft.realisticImageBW") },
        { value: "realistic_image/hard_flash", label: t("juno.input.style.recraft.realisticImageHardFlash") },
        { value: "realistic_image/hdr", label: t("juno.input.style.recraft.realisticImageHDR") },
        { value: "realistic_image/natural_light", label: t("juno.input.style.recraft.realisticImageNaturalLight") },
        { value: "realistic_image/studio_portrait", label: t("juno.input.style.recraft.realisticImageStudioPortrait") },
        { value: "realistic_image/enterprise", label: t("juno.input.style.recraft.realisticImageEnterprise") },
        { value: "realistic_image/motion_blur", label: t("juno.input.style.recraft.realisticImageMotionBlur") },
      ],
    },
    {
      options: [
        { value: "digital_illustration", label: t("juno.input.style.recraft.digitalIllustration") },
        { value: "digital_illustration/pixel_art", label: t("juno.input.style.recraft.digitalIllustrationPixelArt") },
        { value: "digital_illustration/hand_drawn", label: t("juno.input.style.recraft.digitalIllustrationHandDrawn") },
        { value: "digital_illustration/grain", label: t("juno.input.style.recraft.digitalIllustrationGrain") },
        {
          value: "digital_illustration/infantile_sketch",
          label: t("juno.input.style.recraft.digitalIllustrationInfantileSketch"),
        },
        {
          value: "digital_illustration/2d_art_poster",
          label: t("juno.input.style.recraft.digitalIllustration2DArtPoster"),
        },
        {
          value: "digital_illustration/2d_art_poster_2",
          label: t("juno.input.style.recraft.digitalIllustration2DArtPoster2"),
        },
        {
          value: "digital_illustration/handmade_3d",
          label: t("juno.input.style.recraft.digitalIllustrationHandmade3D"),
        },
        {
          value: "digital_illustration/hand_drawn_outline",
          label: t("juno.input.style.recraft.digitalIllustrationHandDrawnOutline"),
        },
        {
          value: "digital_illustration/engraving_color",
          label: t("juno.input.style.recraft.digitalIllustrationEngravingColor"),
        },
      ],
    },
    {
      options: [
        { value: "vector_illustration", label: t("juno.input.style.recraft.vectorIllustration") },
        { value: "vector_illustration/engraving", label: t("juno.input.style.recraft.vectorIllustrationEngraving") },
        { value: "vector_illustration/line_art", label: t("juno.input.style.recraft.vectorIllustrationLineArt") },
        {
          value: "vector_illustration/line_circuit",
          label: t("juno.input.style.recraft.vectorIllustrationLineCircuit"),
        },
        { value: "vector_illustration/linocut", label: t("juno.input.style.recraft.vectorIllustrationLinocut") },
      ],
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentColor, setCurrentColor] = React.useState({ r: 255, g: 255, b: 255 });

  const menuItems: JSX.Element[] = styleGroups.reduce((acc: JSX.Element[], group, index) => {
    group.options.forEach((option) => {
      acc.push(
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
    if (index < styleGroups.length - 1) {
      acc.push(<Divider key={`divider-${index}`} />);
    }
    return acc;
  }, []);

  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 2 }}>
        <SettingsSuggestIcon sx={{ mr: 1 }} />
        <Typography variant={"subtitle1"} component={"p"}>
          {t("juno.input.basicSettings")}
        </Typography>
      </Box>

      {menu === "txt2img" && (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
            <Typography variant={"body2"}>{t("juno.input.aspectRatio.title")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
            <RenderAspectRatioIcons aspectRatios={aspectRatios} />
          </Box>
        </>
      )}

      {/* スタイル選択 */}
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
        <FormControl fullWidth>
          <InputLabel id="ai-engine-select-label">{t("juno.input.style.title")}</InputLabel>
          <Select
            size={"small"}
            value={style[ai_engine]}
            label={t("juno.input.style.title")}
            onChange={(event) => {
              const newValue = event.target.value as StyleRecraftV3;
              setStyle({ ...style, [ai_engine]: newValue });
            }}
          >
            {menuItems}
          </Select>
        </FormControl>
      </Box>

      {/* カラー選択　複数 */}
      <Box sx={{ mb: 2 }}>
        <Typography variant={"body2"}>{t("juno.input.colorSelection.title")}</Typography>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", mt: 1 }} gap={1}>
          {colorsRecraft.map((color, index) => (
            <Box
              key={index}
              sx={{
                width: 40,
                height: 40,
                backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
                borderRadius: 1,
                position: "relative",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: -10,
                  right: -10,
                  backgroundColor: alpha("#000", 0.5),
                  width: 20,
                  height: 20,
                  color: "white",
                  "&:hover": {
                    backgroundColor: alpha("#000", 0.8),
                  },
                }}
                onClick={() => {
                  const newColors = [...colorsRecraft];
                  newColors.splice(index, 1);
                  setColorsRecraft(newColors);
                }}
              >
                <CloseIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </Box>
          ))}
          <IconButton
            size="large"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            sx={{
              width: 40,
              height: 40,
              borderRadius: 1,
              border: "1px dashed grey",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box p={2}>
            <ChromePicker
              color={currentColor}
              onChange={(color) => {
                setCurrentColor(color.rgb);
              }}
              disableAlpha
            />
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button onClick={() => setAnchorEl(null)}>{t("common.cancel")}</Button>
              <Button
                onClick={() => {
                  setColorsRecraft([...colorsRecraft, currentColor]);
                  setAnchorEl(null);
                }}
              >
                {t("common.add")}
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>

      <SelectPublic />
    </>
  );
}
