import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../context/AlertContext";
import { useUploadImageContext } from "../../../../context/juno/UploadImageContext";
import { useParamsContext } from "../../../../context/juno/ParamsContext";
import { useUpscaleContext } from "../../../../context/juno/UpscaleContext";

export default function ImageUpload() {
  const { t } = useTranslation();
  const { setImage, setOriginalAspectRatio, image, originalAspectRatio } = useUploadImageContext();
  const { width, height, setWidthPartial, setHeightPartial } = useParamsContext();
  const { setUuidParent } = useUpscaleContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [cropArea, setCropArea] = useState({ width: 0, height: 0, offsetX: 0, offsetY: 0 });
  const boxRef = useRef<HTMLDivElement>(null);
  const params = new URLSearchParams(window.location.search);
  const menu = params.get("menu") || "txt2img";
  const ai_engine = params.get("ai_engine");
  const { setAlert } = useAlert();

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      const file = files[0];
      updateImage(file);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      updateImage(file);
      setUuidParent("");
    }
  };

  const updateImage = (file: File) => {
    setUuidParent("");

    // ファイルタイプチェック (JPGまたはPNG)
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      setAlert("error", t("juno.input.uploadError.type"));
      return;
    }

    // ファイルサイズチェック
    const maxSizeInBytes = ai_engine === "SD3" ? 10000000 : 20000000; // 20MB
    if (file.size > maxSizeInBytes) {
      setAlert("error", t("juno.input.uploadError.size", { size: maxSizeInBytes / 1000000 }));
      return;
    }

    // 画像の幅と高さを非同期でチェック
    const checkImageSize = (file: File): Promise<void> => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          if (image.width > 2048 || image.height > 2048) {
            setAlert("error", t("juno.input.uploadError.scale"));
            reject();
          } else {
            resolve();
          }
        };
      });
    };

    checkImageSize(file)
      .then(() => {
        // 画像サイズが条件を満たす場合にのみ実行
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target && e.target.result) {
            setImage(e.target.result as string);
            processImage(e.target.result as string);
          }
        };
        reader.readAsDataURL(file);
      })
      .catch(() => {
        // エラー処理や画像処理の中止
      });
  };

  // 外部から画像が追加された時に画像処理を実行
  useEffect(() => {
    try {
      if (image) {
        processImage(image);
      }
    } catch (e) {
      console.error(e);
    }
  }, [image]);

  // 画像処理
  const processImage = (imageSrc: string) => {
    const img = new Image();
    img.onload = () => {
      let width = img.naturalWidth;
      let height = img.naturalHeight;

      setOriginalAspectRatio(width / height);

      // 最大長辺の長さ
      const maxLength = 1080;

      // 長辺がmaxLengthを超える場合、アスペクト比を維持しつつリサイズ
      if (width > maxLength || height > maxLength) {
        if (width > height) {
          height = Math.round((height * maxLength) / width);
          width = maxLength;
        } else {
          width = Math.round((width * maxLength) / height);
          height = maxLength;
        }
      }
      // 幅と高さを8で割り切れるように調整
      width = width - (width % 8);
      height = height - (height % 8);

      if (ai_engine === "SDXL") {
        setWidthPartial("SDXL", width);
        setHeightPartial("SDXL", height);
      } else {
        setWidthPartial("SD", width);
        setHeightPartial("SD", height);
      }
    };
    img.src = imageSrc;
  };

  // クロップ範囲を視覚化
  const imageBorder = () => {
    try {
      let newAspectRatio = 0;
      if (originalAspectRatio) {
        if (ai_engine === "SDXL") {
          if (!width.SDXL || !height.SDXL) {
            return;
          } else {
            newAspectRatio = width.SDXL / height.SDXL;
          }
        } else if (ai_engine === "SD") {
          if (!width.SD || !height.SD) {
            return;
          } else {
            newAspectRatio = width.SD / height.SD;
          }
        } else {
          return;
        }
      } else {
        return;
      }

      let boxSize = 100;
      if (boxRef.current) {
        boxSize = boxRef.current.offsetWidth;
      }
      let displayWidth, displayHeight, cropWidth, cropHeight, offsetX, offsetY;

      // ボックス内での画像の表示サイズを計算（元の画像のアスペクト比を使用）
      if (originalAspectRatio > 1) {
        // 横長の画像
        displayWidth = boxSize;
        displayHeight = boxSize / originalAspectRatio;
      } else {
        // 縦長の画像
        displayHeight = boxSize;
        displayWidth = boxSize * originalAspectRatio;
      }

      // 新しいアスペクト比に基づいてクロップ領域を計算
      if (newAspectRatio > originalAspectRatio) {
        // 新しいサイズが元の画像より横長の場合
        cropWidth = displayWidth;
        cropHeight = displayWidth / newAspectRatio;
      } else {
        // 新しいサイズが元の画像より縦長の場合
        cropHeight = displayHeight;
        cropWidth = displayHeight * newAspectRatio;
      }

      // クロップ領域が画像の表示領域を超えないように調整
      cropWidth = Math.min(cropWidth, displayWidth);
      cropHeight = Math.min(cropHeight, displayHeight);
      offsetX = (boxSize - cropWidth) / 2;
      offsetY = (boxSize - cropHeight) / 2;

      setCropArea({ width: cropWidth, height: cropHeight, offsetX, offsetY });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    imageBorder();
  }, [width.SD, height.SD, width.SDXL, height.SDXL, originalAspectRatio]);

  const handleAreaClick = () => {
    fileInputRef.current?.click();
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    setUuidParent("");
    setImage(null);
  };

  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleAreaClick}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            aspectRatio: "1 / 1",
            "&:hover": {
              cursor: "pointer",
            },
            position: "relative",
          }}
          ref={boxRef}
        >
          {image ? (
            <>
              <img
                src={image}
                alt="Upload Preview"
                style={{ width: "100%", height: "100%", objectFit: "contain", pointerEvents: "none" }}
                onClick={handleAreaClick}
              />
              {(ai_engine === "SD" ||
                ai_engine === "SDXL" ||
                ai_engine === "Ideogram2" ||
                ai_engine === "Ideogram2Turbo") && (
                <div
                  style={{
                    position: "absolute",
                    border: "2px solid red",
                    width: `${cropArea.width}px`,
                    height: `${cropArea.height}px`,
                    top: `${cropArea.offsetY}px`,
                    left: `${cropArea.offsetX}px`,
                  }}
                />
              )}
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 8,
                  right: 8,
                  backdropFilter: "blur(5px)",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  transition: "0.3s",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                  },
                }}
                onClick={handleDelete}
              >
                <DeleteIcon style={{ color: "white" }} />
              </IconButton>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CloudUploadIcon fontSize={"large"} sx={{ mb: 2 }} />
              Upload an image
            </Box>
          )}
          <input type="file" hidden onChange={handleChange} accept="image/*" ref={fileInputRef} />
        </Box>
      </Paper>
    </Box>
  );
}
