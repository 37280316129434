import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton, TextField } from "@mui/material";
import CopyButtonOrIcon from "../../../components/common/CopyButtonOrIcon";
import CloseIcon from "@mui/icons-material/Close";

const ColorModal = ({
  open,
  handleClose,
  color,
  title,
}: {
  open: boolean;
  handleClose: () => void;
  color: {};
  title: string;
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          p: 4,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant={"subtitle1"} sx={{ mb: 2, mr: 2 }}>
          {title}
        </Typography>
        <IconButton onClick={handleClose} sx={{ position: "absolute", top: 4, right: 4 }} size={"small"}>
          <CloseIcon fontSize={"small"} />
        </IconButton>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
            height: 64,
            mb: 2,
          }}
        >
          {["color1", "color2", "color3", "color4", "color5"].map((colorName, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                height: 64,
                backgroundColor: color[colorName],
              }}
            />
          ))}
        </Box>
        <Box mb={2}>
          {["color1", "color2", "color3", "color4", "color5"].map((colorName, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: 24,
                  height: 24,
                  backgroundColor: color[colorName],
                  mr: 1,
                }}
              />
              <TextField
                sx={{
                  width: "100%",
                }}
                value={color[colorName]}
                size={"small"}
                variant={"standard"}
                InputProps={{
                  endAdornment: <CopyButtonOrIcon displayType={"icon"} textToCopy={color[colorName]} size={"small"} />,
                }}
              />
            </Box>
          ))}
        </Box>

        <Typography variant={"body2"} sx={{ maxHeight: 200, overflow: "auto" }}>
          {color["description"]}
        </Typography>
      </Box>
    </Modal>
  );
};
export default ColorModal;
