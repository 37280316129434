import {
  Add as AddIcon,
  AddLink as AddLinkIcon,
  AddPhotoAlternate as AddPhotoAlternateIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
  CropDin as CropDinIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Square as SquareIcon,
} from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Handle, NodeProps, NodeToolbar, Position } from "reactflow";
import { RowCenteredBox } from "../../../utils/styledBox";
import PreviewImageModal from "../../../components/common/ImageModal";
import ImageModal from "./ImageModal";
import LinkModal from "./LinkModal";
import { useMindMap } from "../../../context/MindmapContext";

interface Link {
  url: string;
  title: string;
}

const CustomNode: React.FC<NodeProps> = ({ id, data, selected }) => {
  const theme = useTheme();
  const nodeRef = useRef<HTMLDivElement>(null);
  const [label, setLabel] = useState(data.label);
  const [color, setColor] = useState({
    name: "",
    lightColor: theme.palette.grey[300],
    darkColor: theme.palette.grey[800],
  });
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [links, setLinks] = useState<Link[]>(data.url || []);
  const [images, setImages] = useState<string[]>(data.images || []);
  const [editingLinkIndex, setEditingLinkIndex] = useState<number | null>(null);
  const [editingLink, setEditingLink] = useState<Link | null>(null);
  const { setSelectedNode, setActionTrigger } = useMindMap();

  useEffect(() => {
    if (selected) setSelectedNode(id);
  }, [selected, id]);

  useEffect(() => {
    setLabel(data.label);
    const initialColor = colorList.find((item) => item.name === data.color);
    if (initialColor) setColor(initialColor);
  }, [data.label, data.color]);

  useEffect(() => {
    if (nodeRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { width, height } = entry.contentRect;
          data.size = { width, height };
        }
      });
      resizeObserver.observe(nodeRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [data]);

  const handleDelete = () => setActionTrigger(id, "delete");
  const handleAdd = () => setActionTrigger(id, "add");
  const handleColorChange = (newColor: typeof color) => {
    setColor(newColor);
    data.color = newColor.name;
  };

  const handleDeleteLink = (event: React.MouseEvent, index: number) => {
    event?.stopPropagation();
    event?.preventDefault();
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
    data.url = updatedLinks;
  };

  const handleEditLink = (event: React.MouseEvent, index: number) => {
    event?.preventDefault();
    event?.stopPropagation();
    setEditingLinkIndex(index);
    setEditingLink({ ...links[index] });
  };

  const handleUpdateLink = (event: React.MouseEvent, index: number, updatedLink: Link) => {
    event?.stopPropagation();
    event?.preventDefault();
    const updatedLinks = [...links];
    updatedLinks[index] = updatedLink;
    setLinks(updatedLinks);
    data.url = updatedLinks;
    setEditingLinkIndex(null);
    setEditingLink(null);
  };

  const handleCancelEditLink = (event: React.MouseEvent) => {
    event?.stopPropagation();
    event?.preventDefault();
    setEditingLinkIndex(null);
    setEditingLink(null);
  };

  const handleDeleteImage = (index: number) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    data.images = updatedImages;
  };

  const colorList = [
    { name: "red", lightColor: "#ffafaf", darkColor: "#eb3434" },
    { name: "blue", lightColor: "#add8e6", darkColor: "#344feb" },
    { name: "green", lightColor: "#77dd77", darkColor: "#228b22" },
    { name: "yellow", lightColor: "#ffffb3", darkColor: "#9e9e00" },
    { name: "orange", lightColor: "#ffdab9", darkColor: "#eb7434" },
    { name: "purple", lightColor: "#b19cd9", darkColor: "#6a0dad" },
  ];

  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const handleImagePreview = (index: number) => {
    setSelectedImageIndex(index);
    setOpenImagePreview(true);
  };

  const renderImages = () => {
    const imageCount = images.length;
    if (imageCount === 0) return null;
    const containerHeight = 240;

    if (imageCount === 1) {
      return (
        <Box sx={{ position: "relative", height: containerHeight, pt: 1 }}>
          <img
            src={images[0]}
            alt="image"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            onClick={() => handleImagePreview(0)}
          />
          {renderDeleteButton(0)}
        </Box>
      );
    }

    if (imageCount === 2) {
      return (
        <Box sx={{ display: "flex", height: containerHeight, pt: 1 }}>
          {images.map((image, index) => (
            <Box key={index} sx={{ position: "relative", width: "50%", height: "100%" }}>
              <img
                src={image}
                alt={`image ${index + 1}`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onClick={() => handleImagePreview(index)}
              />
              {renderDeleteButton(index)}
            </Box>
          ))}
        </Box>
      );
    }

    if (imageCount === 3) {
      return (
        <Box sx={{ display: "flex", height: containerHeight, pt: 1 }}>
          <Box sx={{ position: "relative", width: "50%", height: "100%" }}>
            <img
              src={images[0]}
              alt="image 1"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              onClick={() => handleImagePreview(0)}
            />
            {renderDeleteButton(0)}
          </Box>
          <Box sx={{ width: "50%", height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ position: "relative", height: "50%" }}>
              <img
                src={images[1]}
                alt="image 2"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onClick={() => handleImagePreview(1)}
              />
              {renderDeleteButton(1)}
            </Box>
            <Box sx={{ position: "relative", height: "50%" }}>
              <img
                src={images[2]}
                alt="image 3"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onClick={() => handleImagePreview(2)}
              />
              {renderDeleteButton(2)}
            </Box>
          </Box>
        </Box>
      );
    }

    if (imageCount >= 4) {
      return (
        <Box sx={{ display: "flex", height: containerHeight, pt: 1 }}>
          <Box sx={{ width: "50%", height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ position: "relative", height: "50%" }}>
              <img
                src={images[0]}
                alt="image 1"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onClick={() => handleImagePreview(0)}
              />
              {renderDeleteButton(0)}
            </Box>
            <Box sx={{ position: "relative", height: "50%" }}>
              <img
                src={images[1]}
                alt="image 2"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onClick={() => handleImagePreview(1)}
              />
              {renderDeleteButton(1)}
            </Box>
          </Box>
          <Box sx={{ width: "50%", height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ position: "relative", height: "50%" }}>
              <img
                src={images[2]}
                alt="image 3"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onClick={() => handleImagePreview(2)}
              />
              {renderDeleteButton(2)}
            </Box>
            <Box sx={{ position: "relative", height: "50%" }}>
              <img
                src={images[3]}
                alt="image 4"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onClick={() => handleImagePreview(3)}
              />
              {renderDeleteButton(3)}
            </Box>
          </Box>
        </Box>
      );
    }
  };

  const renderDeleteButton = (index: number) => {
    if (!selected) return null;
    return (
      <IconButton
        onClick={() => handleDeleteImage(index)}
        size={"small"}
        sx={{
          position: "absolute",
          top: 4,
          right: 4,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
        }}
      >
        <CloseIcon sx={{ color: "white", fontSize: "small" }} />
      </IconButton>
    );
  };

  return (
    <>
      <Paper
        elevation={0}
        ref={nodeRef}
        sx={{
          position: "relative",
          borderRadius: 1,
          border: selected
            ? `1px solid ${theme.palette.primary.main}`
            : `1px solid ${theme.palette.mode === "dark" ? color.darkColor : color.lightColor}`,
          width: 400,
        }}
      >
        <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition} />
        <Box sx={{ background: theme.palette.mode === "dark" ? color.darkColor : color.lightColor }}>
          <Box
            sx={{
              display: "flex",
              opacity: selected ? 1 : 0,
              visibility: selected ? "visible" : "hidden",
              transition: "all 0.3s ease-in-out",
              height: selected ? "auto" : 8,
            }}
          >
            <IconButton onClick={handleDelete}>
              <CloseIcon sx={{ fontSize: "medium" }} />
            </IconButton>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
              <IconButton
                onClick={() =>
                  handleColorChange({
                    name: "",
                    lightColor: theme.palette.grey[300],
                    darkColor: theme.palette.grey[800],
                  })
                }
              >
                <CropDinIcon sx={{ fontSize: "medium" }} />
              </IconButton>
              {colorList.map((item, index) => (
                <IconButton key={index} onClick={() => handleColorChange(item)}>
                  <SquareIcon
                    sx={{ fontSize: "medium", color: theme.palette.mode === "dark" ? item.darkColor : item.lightColor }}
                  />
                </IconButton>
              ))}
              <IconButton onClick={() => setIsLinkModalOpen(true)}>
                <AddLinkIcon sx={{ fontSize: "medium" }} />
              </IconButton>
              <IconButton onClick={() => setIsImageModalOpen(true)}>
                <AddPhotoAlternateIcon sx={{ fontSize: "medium" }} />
              </IconButton>
            </Box>
            <IconButton onClick={handleAdd}>
              <AddIcon sx={{ fontSize: "medium" }} />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", py: 1, flexDirection: "column" }}>
          {/* Label */}
          {selected ? (
            <TextField
              multiline
              fullWidth
              value={label}
              variant="standard"
              onChange={(e) => {
                setLabel(e.target.value);
                data.label = e.target.value;
              }}
              sx={{ px: 2 }}
            />
          ) : (
            <Typography variant="body1" sx={{ px: 2, whiteSpace: "break-spaces" }}>
              {label}
            </Typography>
          )}

          {/* Images */}
          {renderImages()}

          {/* Links */}
          {links.length > 0 && (
            <List dense sx={{ p: 0, pt: 1 }}>
              {links.map((link, index) => (
                <ListItem key={`link-${index}`} disablePadding>
                  {editingLinkIndex === index && selected ? (
                    <RowCenteredBox px={2} pt={2} pb={1}>
                      <TextField
                        label="Title"
                        value={editingLink?.title || ""}
                        onChange={(e) => setEditingLink({ ...editingLink!, title: e.target.value })}
                        size="small"
                        sx={{ mr: 1, flexGrow: 1 }}
                      />
                      <TextField
                        label="URL"
                        value={editingLink?.url || ""}
                        onChange={(e) => setEditingLink({ ...editingLink!, url: e.target.value })}
                        size="small"
                        sx={{ mr: 1, flexGrow: 1 }}
                      />
                      <IconButton onClick={(e) => handleUpdateLink(e, index, editingLink!)} size="small">
                        <CheckIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={handleCancelEditLink} size="small">
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </RowCenteredBox>
                  ) : (
                    <ListItemButton component="a" href={link.url} target="_blank" sx={{ py: 0 }}>
                      <ListItemIcon sx={{ mr: 2, minWidth: 0 }}>
                        <LaunchIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography color="primary" variant="button" gutterBottom>
                            {link.title}
                          </Typography>
                        }
                      />
                      {selected && (
                        <ListItemSecondaryAction sx={{ display: "flex", gap: 0.5 }}>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={(e) => handleEditLink(e, index)}
                            size="small"
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(e) => handleDeleteLink(e, index)}
                            size="small"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        {id !== "1" && <Handle type="target" position={Position.Left} />}
        <Handle type="source" position={Position.Right} />
      </Paper>

      <LinkModal
        isLinkModalOpen={isLinkModalOpen}
        setIsLinkModalOpen={setIsLinkModalOpen}
        links={links}
        setLinks={setLinks}
        data={data}
      />
      <ImageModal
        isImageModalOpen={isImageModalOpen}
        setIsImageModalOpen={setIsImageModalOpen}
        images={images}
        setImages={setImages}
        data={data}
      />
      <PreviewImageModal
        src={images[selectedImageIndex || 0]}
        alt="image"
        open={openImagePreview}
        onClose={() => setOpenImagePreview(false)}
      />
    </>
  );
};

export default CustomNode;
