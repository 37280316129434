import React from "react";
import { useTranslation } from "react-i18next";
import { useVideo } from "../../../../context/juno/VideoContext";
import { Typography, Fade, Box } from "@mui/material";
import ParamsKling from "./ParamsKling";
import ParamsLuma from "./ParamsLuma";
import ParamsRunway from "./ParamsRunway";
import { ColumnCenteredBox, RowBox } from "../../../../utils/styledBox";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ParamsHailuo from "./ParamsHailuo";
import ParamsHaiper from "./ParamsHaiper";

const ParamsManager = () => {
  const { t } = useTranslation();
  const { aiEngine } = useVideo();

  return (
    <ColumnCenteredBox mb={4}>
      <RowBox mb={4} sx={{ width: "100%" }}>
        <SettingsApplicationsIcon color={"secondary"} sx={{ mr: 1 }} />
        <Typography variant="subtitle1">{t("juno.video.params.title")}</Typography>
      </RowBox>

      {/* コンテナにposition: relativeを指定して、全ての子要素を重ねる */}
      <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
        <Fade in={aiEngine === "KLING"} timeout={500} unmountOnExit>
          <Box sx={{ position: { xs: "block", md: "absolute" }, width: "100%" }}>
            <ParamsKling />
          </Box>
        </Fade>
        <Fade in={aiEngine === "LUMA"} timeout={500} unmountOnExit>
          <Box sx={{ position: { xs: "block", md: "absolute" }, width: "100%" }}>
            <ParamsLuma />
          </Box>
        </Fade>
        <Fade in={aiEngine === "RUNWAY"} timeout={500} unmountOnExit>
          <Box sx={{ position: { xs: "block", md: "absolute" }, width: "100%" }}>
            <ParamsRunway />
          </Box>
        </Fade>
        <Fade in={aiEngine === "HAIPER"} timeout={500} unmountOnExit>
          <Box sx={{ position: { xs: "block", md: "absolute" }, width: "100%" }}>
            <ParamsHaiper />
          </Box>
        </Fade>
        <Fade in={aiEngine === "HAILUO"} timeout={500} unmountOnExit>
          <Box sx={{ position: { xs: "block", md: "absolute" }, width: "100%" }}>
            <ParamsHailuo />
          </Box>
        </Fade>
      </Box>
    </ColumnCenteredBox>
  );
};
export default ParamsManager;
