import React from "react";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import { GradientTypography } from "../../../../utils/gradientTypography";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { GradationButton } from "../../../../utils/gradationButton";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";

const Seamless = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  return (
    <Grid container spacing={2} sx={{ p: { xs: 2, md: 4 } }}>
      <Grid item xs={12} sm={6}>
        <ColumnCenteredBox gap={2} justifyContent={"center"} height={"100%"}>
          <GradientTypography variant={"h5"} component={"h2"}>
            {t("top.fv.seamless.title")}
          </GradientTypography>
          <Typography sx={{ mb: 2 }}>{t("top.fv.seamless.description")}</Typography>
          <GradationButton sx={{ px: 2 }} onClick={() => navigate("/juno")}>
            {t("top.fv.seamless.button")}
          </GradationButton>
        </ColumnCenteredBox>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            position: "relative",
            height: "100%",
            minHeight: 200,
            maxHeight: 200,
            borderRadius: 4,
            boxShadow: 1,
            overflow: "hidden",
            width: "100%",
            "&:hover img": {
              transform: "scale(1.05)",
            },
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out",
          }}
          onClick={() => navigate("/juno")}
        >
          <Box
            component="img"
            src="/images/juno/about/top.jpg"
            alt="juno"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
export default Seamless;
