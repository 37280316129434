import { Box, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCoins } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useCheckCredit } from "../../../hooks/useCreditCheck";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { setCreditTrigger } from "../../../redux/slices/triggerSlice";
import { RootState } from "../../../redux/store";
import { GradationButton } from "../../../utils/gradationButton";
import { useNotice } from "../../../context/NoticeContext";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const Credit = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { credits, fetchUserCredits } = useCheckCredit();
  const { drawerCreditTrigger } = useSelector((state: RootState) => state.trigger);
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const { triggerNotice } = useNotice();
  const [loading, setLoading] = useState(false);

  const GradientBackground = styled("div")(({ theme }) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: 36,
    height: 36,
    borderRadius: "50%",
    backgroundImage: "linear-gradient(135deg, rgba(107,84,74,1) 0%, rgba(255,216,91,1) 50%, rgba(107,84,74,1) 100%)",
  }));

  useEffect(() => {
    setLoading(true);
    const getCredit = async () => {
      await fetchUserCredits();
    };
    getCredit();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (drawerCreditTrigger) {
      fetchUserCredits();
      dispatch(setCreditTrigger(false));
    }
  }, [drawerCreditTrigger]);

  useEffect(() => {
    // サブスクリプションの購入時に表示するメッセージ
    if (params?.get("subscription") === "success") {
      triggerNotice("success", t("message.subscription.success"));
    } else if (params?.get("subscription") === "cancel") {
      triggerNotice("info", t("message.subscription.cancel"));
    } else if (params?.get("subscription") === "error") {
      triggerNotice("error", t("message.subscription.error"));
    }
  }, [params]);

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={2}
      width={"100%"}
    >
      <Box display={"flex"} alignItems={"center"}>
        <GradientBackground>
          <FaCoins color={"white"} style={{ fontSize: "18px" }} />
        </GradientBackground>
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"} justifyContent={"center"} ml={1}>
          <Typography variant="caption">CREDITS</Typography>
          <Typography variant="body1" fontWeight={600}>
            {!loading ? credits : <CircularProgress size={20} />}
          </Typography>
        </Box>
      </Box>
      <GradationButton
        startIcon={<CreditCardIcon />}
        sx={{ borderRadius: 1, px: 2.4, py: 0.8, width: "100%" }}
        onClick={() => navigate("/preferences/plan-and-billings")}
      >
        {t("drawer.plan")}
      </GradationButton>
    </Box>
  );
};
export default Credit;
