const browsing = {
  title: "Browsing",
  history: "History",
  preTitle: "Browsing with ",
  subtitle: "Utilizing web information to provide the latest and most accurate answers.",
  browsingMode: "Browsing Mode",
  browsingModeDescription: "URL or automatic search to generate answers based on the latest information.",
  search: "Search",
  searchHelp:
    "If the language or region of the search results is different, you can optimize it through the settings page. Please access the settings page and make the necessary changes according to your preferences. This will allow us to provide more relevant information. ",
  send: "Send (Ctrl + Enter)",
  searchPlaceholder: "Ask me anything...",
  stopGenerating: "Stop Generating",
  query: "Message",
  answer: "Answer",
  searchMore: "Search More on Google",
  clear: "Clear conversation history",
  delete: "Delete workspace",
  customize: "Customize",
  new: "NEW",
  displayHistoryDescribe: "30 messages are displayed",
  noHistory: "No Chat History",
  addWorkspace: "Add a workspace",
  createWorkspace: "Created a new workspace",
  deleteWorkspace: {
    title: "Are you sure you want to delete the workspace?",
    description: "Deleting the workspace will remove all data within the workspace. This action cannot be undone.",
    success: "The workspace has been deleted.",
  },
  deleteHistory: {
    title: "Are you sure you want to delete the conversation history?",
    description: "This will delete the conversation history. This action cannot be undone.",
  },
  customizeWorkspace: {
    title: "Customize Workspace",
    description: "You can customize the title of the workspace and the behavior of Tenak AI.",
    workspaceTitle: "Workspace name",
    workspaceTitleDescription: "The title will be displayed in the menu.",
    instructionTitle: "Behavior of Tenak AI",
    instructionDescription: "You can customize the behavior of Tenak AI, such as roles and answer formats.",
    instructionTemplate:
      "# Role \n- You are an excellent AI assistant. \n \n# Constraints\n- Answer concisely\n\n# Output format\n- Always answer in English",
    instructionPlaceholder: "You are an excellent AI assistant.",
    setTemplate: "Apply Sample Template",
  },
  error: {
    search: "No search results were found or the server was overloaded and could not retrieve the search results.",
  },
  url: {
    title: "Reference URL",
    result: {
      success: "Referenced the URL.",
      error: "Failed to reference the URL. Please insert the text directly.",
    },
  },
  sidebar: {
    preSearchTitle: "Search Results",
    preSearchDescription:
      "When Browsing Mode is turned ON, search results will be displayed and used for reference in responses.",
  },
  caution: "AI may give wrong answers. Be sure to double check.",
};
export default browsing;
