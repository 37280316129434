const mindmap = {
  title: "Anything to Mind Map",
  editor: "Mind Map Editor",
  titleToMindMap: "Title",
  library: "Library",
  description: "Tenak AI will brainstorm and generate a mindmap.",
  titleToMindMapDescription: "Generate a mindmap based on the title.",
  URLToMindMap: "URL",
  URLToMindMapDescription: "Analyze the content of the URL with AI and generate a mindmap.",
  YouTubeToMindMap: "YouTube",
  YouTubeToMindMapDescription: "Analyze the content of the YouTube video with AI and generate a mindmap.",
  TextToMindMap: "Text",
  TextToMindMapDescription: "Analyze the text with AI and generate a mindmap.",
  URL: "URL",
  titleInput: "Title",
  titlePlaceholder: 'e.g. "Idea for xxxx", "Task for xxxx", "Cause of xxxx" (Max 100 characters)',
  urlPlaceholder: "https://www.example.com",
  youtubePlaceholder: "https://www.youtube.com/watch?v=xxxxxxxxxxx ( We also support YouTube Shorts. )",
  textPlaceholder: "Please enter the text you want to summarize. (Max 8,000 characters)",
  additional: "Additional Instructions (Optional)",
  additionalPlaceholder: "Please enter additional instructions. (Up to 400 characters)",
  addNode: "Add Node",
  submit: "Generate Mindmap",
  autoLayout: "Automatic layout",
  createNodes: "Create nodes with AI",
  mindmapToText: "Export as Text",
  mindmapToPDF: "Export as PDF",
  mindmapToImage: "Export as Image (PNG)",
  mindmapToSVG: "Export as Image (SVG)",
  edgeTypes: {
    title: "Edge Types",
    default: "Curved",
    straight: "Straight",
    step: "Step",
    smooth: "Smooth Step",
  },
  animation: {
    title: "Animation",
    enable: "Enable Animation",
    disable: "Disable Animation",
  },
  share: {
    title: "Share Mind Map",
    description:
      "Generates a sharing link for the mind map. It can be made public to only those who know the sharing link.",
    public: "Share",
    link: "Sharing Link",
  },
  alert: {
    notDeletedNode: "Cannot delete because at least one node is required.",
    autoLayout: "Applied automatic layout.",
    createNodes: "Created nodes with AI.",
    addNode: "Added node.",
    autoLayoutError: "Automatic layout failed. Please check if the nodes are connected correctly.",
  },
  notice: {
    toText: "The mind map converted to text has been newly added to the text library.",
    error: "Failed to convert the mind map to text.",
  },
  addImage: "Add Image",
  addLink: "Add Link",
};
export default mindmap;
