import EditIcon from "@mui/icons-material/Edit";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SubjectIcon from "@mui/icons-material/Subject";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../context/AlertContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";

const AIEditor = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();

  const [open, setOpen] = React.useState(false);
  const handleNewFile = async (type: number) => {
    try {
      if (type === 1 || type === 2) {
        axios.defaults.withCredentials = true;
        const csrftoken = Cookies.get("csrftoken");
        const config = {
          headers: { "X-CSRFToken": csrftoken },
        };
        const res = await axios.post("/api/v1/text-file/", { type: type }, config);
        navigate("/file/" + res.data.uuid);
        setAlert("success", t("library.text.message.create"));
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };
  return (
    <Paper
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={() => setOpen(!open)}
      sx={{
        borderRadius: 1,
        py: 0.5,
        backgroundColor: (theme) => alpha(theme.palette.action.selected, 0.05),
      }}
      elevation={0}
    >
      <List disablePadding dense>
        <ListItemButton
          sx={{
            "&:hover": {
              backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
            },
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize={"small"} color={"primary"} />
          </ListItemIcon>
          <ListItemText sx={{ mr: 2 }}>
            <Typography variant={"body2"} component={"span"}>
              {t("drawer.aiEditor")}
            </Typography>
          </ListItemText>

          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding component="div" dense>
            <ListItemButton
              onClick={() => handleNewFile(1)}
              sx={{
                pl: 4,
                "&:hover": {
                  backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                },
              }}
            >
              <ListItemIcon>
                <SubjectIcon fontSize={"small"} color={"secondary"} />
              </ListItemIcon>
              <ListItemText
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maskImage: "linear-gradient(to left, transparent 1%, black 30%)",
                }}
              >
                <Typography variant={"body2"} component={"span"}>
                  {t("drawer.createText")}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </Paper>
  );
};
export default AIEditor;
