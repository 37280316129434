import BoltIcon from "@mui/icons-material/Bolt";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Fade,
  Grid,
  Paper,
  Popover,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ProLabel from "../../../../components/common/ProLabel";
import { AiEngineList } from "../../../../types/junoTypes";

import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import { aiEngineColor } from "../../../../utils/aiEngineColor";
import { alpha } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";

export default function SelectAi() {
  const { t } = useTranslation();
  const aiEngineOptions: AiEngineList[] = [
    "DallE3",
    "Flux11ProUltra",
    "Flux11Pro",
    "Flux1Dev",
    "Flux1Schnell",
    "Flux1RealismLoRA",
    "FluxJuno",
    "RecraftV3",
    "Ideogram2",
    "Ideogram2Turbo",
    "SD",
    "SDXL",
    "SD35",
    "SDUltra",
    "AuraSR",
    "ClarityUpscaler",
    "CreativeUpscaler",
  ];
  const params = new URLSearchParams(window.location.search);
  const menu = params.get("menu") || "txt2img";
  const ai_engine = params.get("ai_engine") || "";
  const navigate = useCustomNavigate();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [rotation, setRotation] = useState(0);
  const isMounted = useRef(false);
  const open = Boolean(anchorEl);

  const options = React.useMemo(() => {
    return {
      txt2img: [
        {
          value: "DallE3",
          label: t("juno.input.engine.DallE3"),
          description: t("juno.input.engine.DallE3Description"),
        },
        {
          value: "Flux11ProUltra",
          label: t("juno.input.engine.Flux11ProUltra"),
          description: t("juno.input.engine.Flux11ProUltraDescription"),
        },
        {
          value: "Flux11Pro",
          label: t("juno.input.engine.Flux11Pro"),
          description: t("juno.input.engine.Flux1ProDescription"),
        },
        {
          value: "Flux1Dev",
          label: t("juno.input.engine.Flux1Dev"),
          description: t("juno.input.engine.Flux1DevDescription"),
        },
        {
          value: "Flux1Schnell",
          label: t("juno.input.engine.Flux1Schnell"),
          description: t("juno.input.engine.Flux1SchnellDescription"),
        },
        {
          value: "Flux1RealismLoRA",
          label: t("juno.input.engine.Flux1RealismLoRA"),
          description: t("juno.input.engine.Flux1RealismLoRADescription"),
        },
        {
          value: "FluxJuno",
          label: t("juno.input.engine.FluxJuno"),
          description: t("juno.input.engine.FluxJunoDescription"),
        },
        {
          value: "RecraftV3",
          label: t("juno.input.engine.RecraftV3"),
          description: t("juno.input.engine.RecraftV3Description"),
        },
        {
          value: "Ideogram2",
          label: t("juno.input.engine.Ideogram2"),
          description: t("juno.input.engine.Ideogram2Description"),
        },
        {
          value: "Ideogram2Turbo",
          label: t("juno.input.engine.Ideogram2Turbo"),
          description: t("juno.input.engine.Ideogram2TurboDescription"),
        },
        { value: "SD", label: t("juno.input.engine.SD"), description: t("juno.input.engine.SDDescription") },
        { value: "SDXL", label: t("juno.input.engine.SDXL"), description: t("juno.input.engine.SDXLDescription") },
        { value: "SD35", label: t("juno.input.engine.SD35"), description: t("juno.input.engine.SD35Description") },
        {
          value: "SDUltra",
          label: t("juno.input.engine.SDUltra"),
          description: t("juno.input.engine.SDUltraDescription"),
        },
      ],
      img2img: [
        {
          value: "Flux1Dev",
          label: t("juno.input.engine.Flux1Dev"),
          description: t("juno.input.engine.Flux1DevDescription"),
        },
        {
          value: "FluxJuno",
          label: t("juno.input.engine.FluxJuno"),
          description: t("juno.input.engine.FluxJunoDescription"),
        },
        {
          value: "Ideogram2",
          label: t("juno.input.engine.Ideogram2"),
          description: t("juno.input.engine.Ideogram2Description"),
        },
        {
          value: "Ideogram2Turbo",
          label: t("juno.input.engine.Ideogram2Turbo"),
          description: t("juno.input.engine.Ideogram2TurboDescription"),
        },
        { value: "SD", label: t("juno.input.engine.SD"), description: t("juno.input.engine.SDDescription") },
        { value: "SDXL", label: t("juno.input.engine.SDXL"), description: t("juno.input.engine.SDXLDescription") },
        { value: "SD35", label: t("juno.input.engine.SD35"), description: t("juno.input.engine.SD35Description") },
      ],
      upscale: [
        {
          value: "AuraSR",
          label: t("juno.input.engine.AuraSR"),
          description: t("juno.input.engine.AuraSRDescription"),
        },
        {
          value: "ClarityUpscaler",
          label: t("juno.input.engine.ClarityUpscaler"),
          description: t("juno.input.engine.ClarityUpscalerDescription"),
        },
        {
          value: "CreativeUpscaler",
          label: t("juno.input.engine.CreativeUpscaler"),
          description: t("juno.input.engine.CreativeUpscalerDescription"),
        },
      ],
    };
  }, [t]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAiEngineChange = (value: string) => {
    if (aiEngineOptions.includes(value as AiEngineList)) {
      navigate(`/juno/image-generator?menu=${menu}&ai_engine=${value}`);
    } else {
      console.error("Invalid AI engine value");
    }
    handlePopoverClose();
  };

  useEffect(() => {
    if (isMounted.current) {
      setRotation((prev) => prev + 360);
    } else {
      isMounted.current = true;
    }
  }, [ai_engine]);

  // メニュー変更時にAIエンジンを設定
  useEffect(() => {
    if (options[menu].some((option) => option.value === ai_engine)) {
      // navigate(`/juno/image-generator?menu=${menu}&ai_engine=${ai_engine}`);
    } else {
      if (menu === "txt2img" || menu === "img2img") {
        navigate(`/juno/image-generator?menu=${menu}&ai_engine=Flux1Dev`);
      } else {
        navigate(`/juno/image-generator?menu=${menu}&ai_engine=${options[menu][0].value}`);
      }
    }
  }, [menu, ai_engine, options, navigate]);

  return (
    <Paper elevation={theme.palette.mode === "dark" ? 1 : 0}>
      <Fade in={true}>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Button
            onClick={handlePopoverOpen}
            variant="text"
            startIcon={
              <BoltIcon
                sx={{
                  color: aiEngineColor(ai_engine),
                  transform: `rotate(${rotation}deg)`,
                  transition: "transform 1s ease-in-out",
                }}
              />
            }
            sx={{ width: "100%" }}
          >
            {ai_engine && t(`juno.input.engine.title`) + " - " + t(`juno.input.engine.${ai_engine}`)}
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            elevation={0}
          >
            <Box sx={{ p: 2, width: { xs: "100vw", md: 700 }, backgroundColor: theme.palette.background.custom2 }}>
              <Grid container spacing={2}>
                {options[menu].map((option, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      sx={{
                        width: "100%",
                        border: ai_engine === option.value ? "2px solid" : "0px solid",
                        borderColor: ai_engine === option.value ? theme.palette.primary.main : "transparent",
                      }}
                      onClick={() => handleAiEngineChange(option.value)}
                      elevation={0}
                    >
                      <CardActionArea>
                        <CardContent
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                            p: 0,
                            position: "relative",
                          }}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              top: 4,
                              left: 4,
                              zIndex: 1,
                            }}
                          >
                            {["DallE3", "Flux11ProUltra", "SDUltra", "Ideogram2"].includes(option.value) ? (
                              <ProLabel />
                            ) : null}
                          </Box>

                          <Box
                            sx={{
                              position: "absolute",
                              top: 4,
                              right: 4,
                              zIndex: 1,
                            }}
                          >
                            <Tooltip title={option.description} arrow placement="bottom">
                              <InfoIcon sx={{ opacity: 0.8 }} />
                            </Tooltip>
                          </Box>

                          <CardMedia
                            component="img"
                            height="130"
                            image={`/images/juno/ai-engine/${option["value"]}.png`}
                            alt={option.label}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 8,
                              backgroundColor: alpha(theme.palette.background.paper, 0.8),
                              borderRadius: 4,
                              px: 2,
                              py: 0.5,
                            }}
                          >
                            <Typography variant="caption" fontWeight={"bold"}>
                              {option.label}
                            </Typography>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Popover>
        </Box>
      </Fade>
    </Paper>
  );
}
