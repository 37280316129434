import DeleteIcon from "@mui/icons-material/Delete";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ImageIcon from "@mui/icons-material/Image";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Save";
import ShareIcon from "@mui/icons-material/Share";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import Cookies from "js-cookie";
import jsPDF from "jspdf";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "reactflow/dist/style.css";
import { useAlert } from "../../../context/AlertContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { customLink } from "../../../utils/utils";
import ShareModal from "./ShareModal";
import ProLabel from "../../../components/common/ProLabel";
import useSubscription from "../../../hooks/useSubscription";
import ProPlanModal from "../../../components/common/ProPlanModal";
import { toSvg } from "html-to-image";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import GenerateLock from "../../../components/common/GeneratingLock";
import { useMindMap } from "../../../context/MindmapContext";
import CircularProgress from "@mui/material/CircularProgress";

const MindmapMenu = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { mindMapUuid } = useParams();
  const [shareOpen, setShareOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useCustomNavigate();
  const { isFree } = useSubscription();
  const [openProModal, setOpenProModal] = useState(false);
  const [converting, setConverting] = useState(false);
  const { title, handleSave, setIsDownloadFlowVisible, saving } = useMindMap();

  // マインドマップをテキストに変換する関数
  const handleMindMapToText = async () => {
    const url = "/api/v1/mind-map/" + mindMapUuid + "/maindmap-to-text";
    const csrftoken = Cookies.get("csrftoken");
    const headers = {
      "X-CSRFToken": csrftoken!,
    };
    const res = await axios.get(url, { headers: headers });
    if (res.data.status === "success") {
      setAlert("success", t("mindmap.notice.toText"));
      window.open(customLink("/file/" + res.data.uuid), "_blank");
    } else {
      setAlert("error", t("mindmap.notice.error"));
    }
  };

  const saveMindMap = async () => {
    handleSave();
    handleClose();
  };

  // メニューの表示
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const htmlToImage = require("html-to-image");
  const generateImage = async (downloadFlowElement) => {
    return await htmlToImage.toPng(downloadFlowElement, {
      width: downloadFlowElement.clientWidth,
      height: downloadFlowElement.clientHeight,
      quality: 1,
      fontEmbedCSS: "",
      pixelRatio: 2,
      skipFonts: true,
    });
  };

  const handleDownloadProcess = async (processFunction, errorMessage) => {
    setIsDownloadFlowVisible(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const downloadFlowElement = document.getElementById("downloadFlow");
    if (downloadFlowElement) {
      try {
        await processFunction(downloadFlowElement);
      } catch (error) {
        console.error(`Error: ${errorMessage}`, error);
        setAlert("error", t(errorMessage));
      } finally {
        setIsDownloadFlowVisible(false);
      }
    } else {
      console.error("Download flow element not found");
      setAlert("error", t(errorMessage));
    }
  };

  const handleDownloadSVG = async () => {
    if (isFree) {
      setOpenProModal(true);
      return;
    }

    setConverting(true);

    try {
      await handleDownloadProcess(async (downloadFlowElement) => {
        const svgData = await toSvg(downloadFlowElement, {
          filter: (node) => {
            return !(node instanceof HTMLElement) || !node.classList.contains("react-flow__background");
          },
          width: downloadFlowElement.clientWidth,
          height: downloadFlowElement.clientHeight,
          fontEmbedCSS: "",
          skipFonts: true,
        });

        const svgContent = decodeURIComponent(svgData.split(",")[1]);
        const svgWithXmlDeclaration = '<?xml version="1.0" encoding="UTF-8"?>\n' + svgContent;
        const blob = new Blob([svgWithXmlDeclaration], { type: "image/svg+xml" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${title}.svg`;
        link.click();
      }, "mindmap.notice.downloadError");
    } catch (error) {
      console.error("Error during SVG download:", error);
    } finally {
      setConverting(false);
      handleClose();
    }
  };

  const handleDownloadPNG = async () => {
    if (isFree) {
      setOpenProModal(true);
      return;
    }

    setConverting(true);

    try {
      await handleDownloadProcess(async (downloadFlowElement) => {
        const dataUrl = await generateImage(downloadFlowElement);
        const link = document.createElement("a");
        link.download = title + ".png";
        link.href = dataUrl;
        link.click();
      }, "mindmap.notice.downloadError");
    } catch (error) {
      console.error("Error during PNG download:", error);
    } finally {
      handleClose();
      setConverting(false);
    }
  };

  const handleDownloadPDF = async () => {
    if (isFree) {
      setOpenProModal(true);
      return;
    }

    setConverting(true);

    try {
      await handleDownloadProcess(async (downloadFlowElement) => {
        const dataUrl = await generateImage(downloadFlowElement);
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [downloadFlowElement.clientWidth, downloadFlowElement.clientHeight],
        });
        pdf.addImage(dataUrl, "PNG", 0, 0, downloadFlowElement.clientWidth, downloadFlowElement.clientHeight);
        pdf.save(`${title}.pdf`);
      }, "mindmap.notice.pdfError");
    } catch (error) {
      console.error("Error during PDF download:", error);
    } finally {
      handleClose();
      setConverting(false);
    }
  };

  // 削除確認ダイアログ
  const handleConfirmedDelete = () => {
    handleDelete().then();
    setDialogOpen(false);
    handleClose();
  };

  const handleDelete = async () => {
    axios.defaults.withCredentials = true;
    const csrftoken = Cookies.get("csrftoken");
    // リクエストヘッダーにCSRFトークンを追加
    const config = {
      headers: { "X-CSRFToken": csrftoken },
    };
    const url = "/api/v1/mind-map/";
    await axios.delete(url + mindMapUuid, config);
    setAlert("success", t("file.deleteFile.success"));
    navigate("/library/mind-maps");
    handleClose();
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
        <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={saveMindMap} disabled={saving}>
            {saving ? (
              <CircularProgress size={20} sx={{ mr: 1 }} color="secondary" />
            ) : (
              <SaveIcon fontSize={"small"} sx={{ mr: 1 }} color="secondary" />
            )}
            {t("common.save")}
          </MenuItem>
          <MenuItem onClick={handleMindMapToText}>
            <FormatListBulletedIcon fontSize={"small"} sx={{ mr: 1 }} color="secondary" /> {t("mindmap.mindmapToText")}
          </MenuItem>
          <MenuItem onClick={handleDownloadPNG}>
            <ImageIcon fontSize={"small"} sx={{ mr: 1 }} color="secondary" />
            {t("mindmap.mindmapToImage")}
            <ProLabel sx={{ ml: 2 }} />
          </MenuItem>
          <MenuItem onClick={handleDownloadSVG}>
            <ShapeLineIcon fontSize={"small"} sx={{ mr: 1 }} color="secondary" />
            {t("mindmap.mindmapToSVG")}
            <ProLabel sx={{ ml: 2 }} />
          </MenuItem>
          {/*<MenuItem onClick={handleDownloadPDF}>*/}
          {/*  <PictureAsPdfIcon fontSize={"small"} sx={{mr: 1}} color="secondary"/>*/}
          {/*  {t("mindmap.mindmapToPDF")}*/}
          {/*  <ProLabel sx={{ml: 2}}/>*/}
          {/*</MenuItem>*/}
          <MenuItem
            onClick={() => {
              setShareOpen(true);
              handleClose();
            }}
          >
            <ShareIcon fontSize={"small"} sx={{ mr: 1 }} color="secondary" /> {t("common.share")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDialogOpen(true);
              handleClose();
            }}
          >
            <DeleteIcon fontSize={"small"} sx={{ mr: 1 }} color="error" /> {t("common.delete")}
          </MenuItem>
        </Menu>
        <ShareModal mindMapUuid={mindMapUuid!} open={shareOpen} handleClose={() => setShareOpen(false)} />
        {/* <DeleteDialog open={dialogOpen} handleClose={() => setDialogOpen(false)} /> */}
        {/* 削除確認ダイアログ */}
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>{t("file.deleteFile.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("file.deleteFile.description")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disableElevation onClick={() => setDialogOpen(false)} color="primary">
              {t("common.cancel")}
            </Button>
            <Button onClick={handleConfirmedDelete} color="error">
              {t("common.delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <ProPlanModal open={openProModal} setOpen={setOpenProModal} />
      <GenerateLock show={converting} />
    </>
  );
};
export default MindmapMenu;
