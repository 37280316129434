const pricing = {
  title: "プランと価格",
  pricing: "PRICING",
  description: "Tenak AIのプランと価格",
  legalNotice: "特定商取引法に基づく表記",
  free: {
    title: "Free",
    description: "無料プラン",
    price: "0",
    credit: "50",
  },
  basic: {
    title: "Basic",
    description: "ベーシックプラン",
    price: "18",
    credit: "900",
  },
  standard: {
    title: "Standard",
    description: "スタンダードプラン",
    price: "36",
    credit: "1,800 + 200",
  },
  premium: {
    title: "Premium",
    description: "プレミアムプラン",
    price: "72",
    credit: "3,600 + 600",
  },
  perMonth: "月額",
  credit: "クレジット",
  aiEngine: "パワフルAIエンジン",
  juno: "STUDIO JUNO（画像生成）",
  junoPrivate: "生成画像の非公開設定",
  junoEngine: "全ての画像生成AIエンジン",
  junoEnginePart: "一部の画像生成AIエンジン",
  junoModel: "全ての画像生成モデル・LoRA",
  mindmap: "マインドマップ生成",
  export: "マインドマップのエクスポート",
  template: "全てのAIテンプレート",
  textEditor: "AIテキストエディター",
  voice: "音声生成",
  support: "サポート",
  supportPriority: "優先サポート",
  supportNo: "サポートなし",
  subscribe: "購入する",
  aboutCredit: {
    title: "クレジットについて",
    description:
      "Tenak AIのプランは、クレジット制を採用しています。クレジットは、文字、画像、音声生成などのAI機能を利用するための通貨です。以下に目安の消費クレジットを記載しています。",
    text: {
      title: "テキスト生成",
      description: "システム、インプット、アウトプットトークン（文字）数で算出",
      tooltip: '"速度＆節約モード" 使用時は、0.1 / 40K',
    },
    image: {
      title: "画像生成",
      dalle3: "OPEN AI: DALL-E 3",
      sdxl: "Stable Diffusion (XL)",
      sd3: "Stable Diffusion 3.5",
      sdUltra: "Stable Image Ultra",
      flux1pro: "Flux 1.1 Pro",
      flux1dev: "Flux.1 Dev",
      flux1Schnell: "Flux.1 Schnell",
      flux1lora: "Flux.1 with Realism LoRA",
      flux1juno: "Flux.1 with STUDIO JUNO",
      recraftV3: "Recraft V3",
      ideogram2: "Ideogram 2.0",
      ideogram2turbo: "Ideogram 2.0 Turbo",
      auraSR: "Upscaler AuraSR",
      clarityUpscaler: "Upscaler Clarity Upscaler",
      creativeUpscaler: "Upscaler Creative Upscaler",
    },
    video: {
      title: "動画生成",
      runway: "Runway Gen3 Alpha Turbo",
      kling1standard: "Kling v1.0 Standard",
      kling1pro: "Kling v1.0 Pro",
      dreamMachine15: "Dream Machine v1.5",
      hailuo: "Mini Max",
    },
    voice: {
      title: "音声生成",
      google: "Google TTS（1,000文字）",
      openai: "OPEN AI TTS（1,000トークン）",
    },
    mindmap: {
      title: "マインドマップ生成",
      description: "AIを使用しない場合（AIジェネレーター使用時はテキスト生成に依存）",
    },
    annotation:
      "* この表は目安であり、AIエンジンのアップデートにより予告なしに変更される可能性があります。 * 小数点第二位以下は切り上げて計算されます。",
  },
  faq: {
    title: "よくある質問",
    description: "Tenak AIのプランと価格に関するよくある質問",
    q1: "クレジットは繰り越せますか？",
    a1: "いいえ。クレジットは繰り越せません。",
    q2: "アップグレードしたら料金は日割り計算されますか？",
    a2: "いいえ。クレジット制を導入しているため、全額請求されます。そして、お持ちのクレジットと新しいプランのクレジットが合算され、その日から1ヶ月後がクレジット失効日かつ次回請求日となります。",
    q3: "クレジットだけ購入することはできますか？",
    a3: "いいえ。クレジットはプランに含まれており、プランを購入することでクレジットが付与されます。",
    q4: "プランを解約した場合、クレジットはどうなりますか？",
    a4: "プランを解約した場合、その更新月でクレジットは失効します。",
    q5: "クレジットが足りなくなった場合、どうすればいいですか？",
    a5: "クレジットが足りなくなった場合、プランをアップグレードしてください。",
  },
};
export default pricing;
