// MindMapEditor.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Fade, InputAdornment, Paper, TextField, Typography, useTheme } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import SaveIcon from "@mui/icons-material/Save";
import "reactflow/dist/style.css";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Meta from "../../components/common/Meta";
import { useAlert } from "../../context/AlertContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { uiHeight } from "../../utils/uiHeight";
import Generating from "../TextFile/components/Generating";
import DownloadFlow from "./components/DownloadFlow";
import MindmapMenu from "./components/MindmapMenu";
import Toolbar from "./components/Toolbar";
import MindMap from "./components/MindMap";
import { useMindMap } from "../../context/MindmapContext";
import CircularProgress from "@mui/material/CircularProgress";

const MindMapEditor = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();
  const theme = useTheme();
  const { title, setTitle, status, handleSave, saving } = useMindMap();

  return (
    <>
      <Meta title={t("mindmap.editor")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <DownloadFlow />
      <Fade in={true} timeout={1000}>
        <Box>
          {status !== 3 ? (
            <Box component="div">
              <DrawerHeader />
              <Box sx={{ maxWidth: "100%!important", mx: { xs: 0, md: 2 } }}>
                {/* ヘッダーやツールバーなどのコンポーネント */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 1,
                    whiteSpace: "nowrap",
                    gap: 1,
                  }}
                >
                  <TextField
                    variant="standard"
                    value={title}
                    fullWidth
                    onChange={(e) => setTitle(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant={"subtitle1"} sx={{ mr: 1 }} color={"primary"}>
                            Title:
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "none", sm: "flex" },
                        alignItems: "center",
                      }}
                    >
                      <Button
                        disableElevation
                        onClick={() => navigate("/library/mind-maps")}
                        variant={"outlined"}
                        sx={{ minWidth: "140px" }}
                        startIcon={<FolderIcon fontSize={"small"} />}
                      >
                        <Typography variant={"button"}>{t("mindmap.library")}</Typography>
                      </Button>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={saving}
                        sx={{ ml: 1, alignItems: "center", display: "flex" }}
                        startIcon={
                          saving ? (
                            <CircularProgress size={20} sx={{ color: "white" }} />
                          ) : (
                            <SaveIcon fontSize={"small"} />
                          )
                        }
                      >
                        {t("common.save")}
                      </Button>
                    </Box>
                    <MindmapMenu />
                  </Box>
                </Box>
                <Paper
                  elevation={theme.palette.mode === "dark" ? 1 : 0}
                  sx={{
                    width: "100%",
                    height: {
                      xs: "100vh",
                      md: `calc(${uiHeight()} - 64px)`,
                      position: "relative",
                    },
                    background: "background.paper",
                  }}
                >
                  <Toolbar />
                  <MindMap />
                </Paper>
              </Box>
            </Box>
          ) : (
            <Generating />
          )}
        </Box>
      </Fade>
    </>
  );
};
export default MindMapEditor;
