import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectModelModal from "./SelectModelModal";
import PhotoIcon from "@mui/icons-material/Photo";
import axios from "axios";
import ModelPopover from "./ModelPopover";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { RowBox } from "../../../../utils/styledBox";
import { useParamsContext } from "../../../../context/juno/ParamsContext";

export default function SelectModel() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { modelSDXL, modelSD, loraSD, setLoraSD } = useParamsContext();

  const params = new URLSearchParams(window.location.search);
  const ai_engine = params.get("ai_engine") || "";
  const [openModels, setOpenModels] = React.useState(false);
  const [modalType, setModalType] = React.useState<"SD" | "SDXL" | "lora">("SD");
  const [modelInfo, setModelInfo] = React.useState<any>([]);

  const handleModalOpen = (type: "SD" | "SDXL" | "lora") => {
    setOpenModels(!openModels);
    setModalType(type);
  };
  const [imgError, setImgError] = React.useState<{ [key: string]: boolean }>({});

  const ErrorIcon = () => (
    <Box
      className={"error"}
      sx={{
        width: 64,
        height: 64,
        background: "linear-gradient(135deg, #7f5a83, #0d324d)",
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
          color: theme.palette.common.white,
        },
      }}
    >
      <PhotoIcon />
    </Box>
  );

  useEffect(() => {
    const fetchModelData = (model) => {
      if (model && model.id) {
        const modelExists = modelInfo.some((info) => info.id === model.id);
        if (!modelExists) {
          axios
            .get(`/api/v1/juno/sd-models?model_id=${model.id}`)
            .then((res) => {
              if (!res.data) return;
              if (res.data.civitai_url) {
                updateModelInfo(model.id, res.data);
              }
            })
            .catch((err) => {
              console.error("Error fetching model data:", err);
            });
        }
      }
    };

    // Fetch data for modelSDXL and modelSD
    fetchModelData(modelSDXL);
    fetchModelData(modelSD);

    // Since loraSD is an array, loop through each item and fetch data
    loraSD.forEach((model) => {
      fetchModelData(model);
    });
  }, [modelSDXL, modelSD, loraSD, modelInfo]); // Ensure the effect runs when any of these dependencies change

  const updateModelInfo = (id, newData) => {
    setModelInfo((prev) => [...prev, { id: id, ...newData }]);
  };

  const getModelInfo = () => {
    const model = modelInfo.find((info) => info.id === (ai_engine === "SDXL" ? modelSDXL?.id : modelSD?.id));
    return model;
  };

  const getLoraInfo = (id) => {
    return modelInfo.find((info) => info.id === id);
  };

  const changeLoraStrength = (index, upOrDown) => {
    const newLoraSD = loraSD.map((lora, i) => {
      if (i === index) {
        let value = lora.strength;
        if (upOrDown === "up") {
          value = parseFloat((value + 0.05).toFixed(2));
          if (value > 2) return lora;
        }
        if (upOrDown === "down") {
          value = parseFloat((value - 0.05).toFixed(2));
          if (value < -1) return lora;
        }
        return { ...lora, strength: value };
      }
      return lora;
    });
    setLoraSD(newLoraSD);
  };
  const modelId = modelSDXL?.id || modelSD?.id;

  return (
    <>
      {/* models */}
      <Stack spacing={1} mb={3}>
        <Box sx={{ display: "flex", flexDirection: "column" }} gap={1}>
          <Box sx={{ display: "inline-flex", flexDirection: "column", width: "100%", wordWrap: "break-word" }} gap={2}>
            {/* Model */}
            <Typography variant={"body2"} mb={1}>
              {t("juno.input.models")}
            </Typography>
            <Box
              mb={1}
              onClick={() => handleModalOpen(ai_engine as "SD" | "SDXL")}
              sx={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                alignItems: "center",
                "&:hover img": {
                  transform: "scale(1.1)",
                },
                "&:hover .error": {
                  background: "linear-gradient(180deg, #7f5a83, #0d324d)",
                },
              }}
            >
              {modelId && imgError[modelId] ? (
                <ErrorIcon />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 64,
                    height: 64,
                    minWidth: 64,
                    border:
                      (ai_engine === "SDXL" && modelSDXL?.name) || (ai_engine === "SD" && modelSD?.name)
                        ? "none"
                        : "1px dashed #ccc",
                    borderRadius: 2,
                    position: "relative",
                    overflow: "hidden",
                    bgcolor: theme.palette.background.default,
                    mr: 1,
                  }}
                >
                  {ai_engine === "SDXL" && modelSDXL?.name ? (
                    <img
                      src={modelSDXL.screenShot}
                      alt={modelSDXL.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 4,
                        transition: "all 0.2s ease-in-out",
                      }}
                      onError={() =>
                        setImgError({
                          ...imgError,
                          [modelSDXL.id]: true,
                        })
                      }
                    />
                  ) : ai_engine === "SD" && modelSD?.name ? (
                    <img
                      src={modelSD.screenShot}
                      alt={modelSD.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 4,
                        transition: "all 0.2s ease-in-out",
                      }}
                      onError={() => setImgError({ ...imgError, [modelSD.id]: true })}
                    />
                  ) : (
                    <NoteAddIcon />
                  )}
                </Box>
              )}

              <Typography
                variant={"body2"}
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {ai_engine === "SDXL" ? modelSDXL?.name : modelSD?.name}
              </Typography>

              <Box flexGrow={1} />
              {((ai_engine === "SDXL" && modelSDXL?.name) || (ai_engine === "SD" && modelSD?.name)) && modelInfo ? (
                <>
                  {getModelInfo() && <ModelPopover model={getModelInfo()} size={"small"} />}
                  <IconButton size={"small"}>
                    <ChangeCircleIcon fontSize={"small"} />
                  </IconButton>
                </>
              ) : null}
            </Box>

            {/* Lora */}
            <Typography variant={"body2"} mb={1}>
              {t("juno.input.loras")}
            </Typography>
            {loraSD &&
              loraSD.map((lora, index) => {
                if (!lora) return null;
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      cursor: "pointer",
                      mb: 1,
                      "&:hover img": {
                        transform: "scale(1.1)",
                      },
                      "&:hover .error": {
                        background: "linear-gradient(180deg, #7f5a83, #0d324d)",
                      },
                    }}
                    mb={1}
                    onClick={() => handleModalOpen("lora")}
                    key={index}
                  >
                    <Box
                      sx={{
                        minWidth: 64,
                        height: 64,
                        overflow: "hidden",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: 2,
                        alignItems: "center",
                        mr: 1,
                      }}
                    >
                      {imgError[lora.id] ? (
                        <ErrorIcon />
                      ) : (
                        <img
                          src={lora.screenShot}
                          alt={lora.name}
                          style={{
                            width: 64,
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                            transition: "all 0.2s ease-in-out",
                          }}
                          onError={() => setImgError({ ...imgError, [lora.id]: true })}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant={"body2"}
                        sx={{
                          whiteSpace: "break-spaces",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {lora.name}
                      </Typography>
                      <RowBox>
                        <Typography variant={"caption"} sx={{ whiteSpace: "break-spaces", mr: 1 }}>
                          {t("juno.input.loraStrength") + ":"}
                        </Typography>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            changeLoraStrength(index, "down");
                          }}
                          color={"info"}
                        >
                          <RemoveCircleIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                        <Typography
                          variant={"caption"}
                          sx={{
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                          }}
                        >
                          {lora.strength.toFixed(2)}
                        </Typography>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            changeLoraStrength(index, "up");
                          }}
                          color={"info"}
                        >
                          <AddCircleIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                      </RowBox>
                    </Box>
                    {getLoraInfo(lora.id) && <ModelPopover model={getLoraInfo(lora.id)} size={"small"} />}
                    <IconButton
                      size={"small"}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setLoraSD(loraSD.filter((_, i) => i !== index));
                      }}
                    >
                      <DeleteIcon fontSize={"small"} />
                    </IconButton>
                  </Box>
                );
              })}
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={2} mb={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 64,
                  height: 64,
                  border: "1px dashed #ccc",
                  borderRadius: 2,
                  bgcolor: theme.palette.background.default,
                  cursor: "pointer",
                }}
                onClick={() => handleModalOpen("lora")}
              >
                <NoteAddIcon />
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
      <SelectModelModal open={openModels} setOpen={setOpenModels} type={modalType} />
    </>
  );
}
