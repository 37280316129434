import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useCommonContext } from "../../../../context/juno/CommonContext";

const FilteredButton = () => {
  const { t } = useTranslation();
  const { filtered, setFiltered } = useCommonContext();

  const handleClick = useCallback(() => {
    setFiltered((prev) => !prev);
  }, [setFiltered]);

  return (
    <Box display="flex" flexDirection="row" alignItems="flex-end" gap={1}>
      <Tooltip title={filtered ? t("juno.input.hide") : t("juno.input.show")}>
        <IconButton onClick={handleClick} sx={{ position: "relative" }}>
          <VisibilityIcon sx={{ color: "textPrimary" }} />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: filtered ? "success.main" : "error.main",
              borderRadius: "50%",
              width: 16,
              height: 16,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {filtered ? <CheckIcon sx={{ p: 0.8, color: "white" }} /> : <CloseIcon sx={{ p: 0.8, color: "white" }} />}
          </Box>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default React.memo(FilteredButton);
