import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import { FormControlLabel, FormGroup, Grid, IconButton, Paper, Slider, TextField } from "@mui/material";
import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import DatasetIcon from "@mui/icons-material/Dataset";
import Switch from "@mui/material/Switch";
import Grow from "@mui/material/Grow";
import CircularProgress from "@mui/material/CircularProgress";
import PhotoIcon from "@mui/icons-material/Photo";
import useTheme from "@mui/material/styles/useTheme";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import { StyledTypography } from "../../../../utils/styledTypography";
import { ColumnBox, RowBox } from "../../../../utils/styledBox";
import ModelPopover from "./ModelPopover";
import { aiEngineColor } from "../../../../utils/aiEngineColor";
import { alpha } from "@mui/material/styles";
import { useParamsContext } from "../../../../context/juno/ParamsContext";

const loraLimit = 5;

const SelectModelModal = ({
  open,
  setOpen,
  type,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  type: "SD" | "SDXL" | "lora";
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { loraSD, setLoraSD, setModelSD, setModelSDXL } = useParamsContext();
  const [list, setList] = React.useState<any[]>([]);
  const [hideNsfw, setHideNsfw] = React.useState(true);
  const [onlyRecommended, setOnlyRecommended] = React.useState(true);
  const [query, setQuery] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setQuery("");
    setOpen(false);
    setList([]);
    setPage(0);
  };

  const [page, setPage] = React.useState(1); // ページ番号
  const [hasMore, setHasMore] = React.useState(true); // さらにデータがあるかどうか

  const fetchModels = async () => {
    if (page === 1) setPage(2);
    if (page > 1) {
      try {
        setLoading(true);
        setHasMore(true);

        let category = "";
        switch (type) {
          case "SD":
            category = "stable_diffusion";
            break;
          case "SDXL":
            category = "stable_diffusion_xl";
            break;
          case "lora":
            category = "lora";
            break;
        }

        let url = `/api/v1/juno/sd-models?category=${category}&hideNsfw=${hideNsfw}&onlyRecommended=${onlyRecommended}&page=${page}`;
        if (query) {
          url += "&model_name=" + query;
        }
        const response = await axios.get(url);
        if (response.data.results.length === 0) {
          setHasMore(false);
          setPage(0);
        } else {
          // 新しいデータを既存のリストに追加
          setList((prevList) => [...prevList, ...response.data.results]);
          setPage((prevPage) => prevPage + 1); // ページ番号をインクリメント
        }
      } catch (error) {
        console.error("API call failed:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchModelsInitial = async () => {
    setLoading(true);

    setList([]);
    setPage(1);
    setHasMore(true);

    let category = "";
    switch (type) {
      case "SD":
        category = "stable_diffusion";
        break;
      case "SDXL":
        category = "stable_diffusion_xl";
        break;
      case "lora":
        category = "lora";
        break;
    }

    let url = `/api/v1/juno/sd-models?category=${category}&hideNsfw=${hideNsfw}&onlyRecommended=${onlyRecommended}&page=${1}`;
    if (query) {
      url += "&model_name=" + query;
    }
    try {
      const response = await axios.get(url);
      if (response.data.results.length < 36) {
        setHasMore(false);
      }
      setList(response.data.results);
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // 監視対象がビューポートに入ったかつ、まだ読み込むデータがある、かつ現在ローディング中でない場合にデータをフェッチ
        if (entries[0].isIntersecting && hasMore && !loading) {
          if (page >= 1 && list.length >= 36) {
            fetchModels();
          }
        }
      },
      { threshold: 0.1 } // ビューポートに入る前に発火させたい場合は閾値を調整
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => observer.disconnect();
  }, [loading, hasMore, page]); // pageを依存配列に追加

  const loadingRef = React.useRef(null); // ローディング表示の要素を参照するためのRef

  useEffect(() => {
    // queryが空の場合は即時実行、そうでない場合はディレイを設定
    if (open && hasMore) {
      if (!query) {
        fetchModels();
      } else {
        const timerId = setTimeout(() => {
          fetchModels();
        }, 300); // queryが存在する場合、300ミリ秒のディレイ後に実行

        return () => clearTimeout(timerId); // コンポーネントがアンマウントされるか、依存配列内の値が変更される前にタイマーをクリア
      }
    }
  }, [hideNsfw, onlyRecommended, hasMore]);

  useEffect(() => {
    if (!query) {
      fetchModelsInitial();
    } else {
      const timerId = setTimeout(() => {
        fetchModelsInitial();
      }, 300); // queryが存在する場合、300ミリ秒のディレイ後に実行

      return () => clearTimeout(timerId); // コンポーネントがアンマウントされるか、依存配列内の値が変更される前にタイマーをクリア
    }
  }, [open, type, hideNsfw, onlyRecommended, query]);

  const [imgError, setImgError] = React.useState<{ [key: string]: boolean }>({});

  const CustomCard = ({ model }: { model: any }) => {
    return (
      <Card
        sx={{
          position: "relative",
          height: 240,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "primary.main",
          background: "linear-gradient(135deg, #7f5a83, #0d324d)",
          borderRadius: 2,
          border: loraSD.some((item) => item.id === model.model_id)
            ? "2px solid " + theme.palette.success.main
            : "none",
          "&:hover": {
            background: "linear-gradient(135deg, #0d324d, #7f5a83)",
            cursor: "pointer",
            transition: "0.3s",
          },
          "&:hover img": {
            transform: "scale(1.1)",
          },
        }}
        onClick={() => {
          if (type === "lora") {
            const isSelected = loraSD.some((item) => item.id === model.model_id);
            if (isSelected) {
              // 選択済みの場合はloraSDから削除
              setLoraSD(loraSD.filter((item) => item.id !== model.model_id));
            } else {
              // 未選択の場合はloraSDに追加
              if (loraSD.length >= loraLimit) return;
              setLoraSD([
                ...loraSD,
                {
                  name: model.model_name,
                  id: model.model_id,
                  screenShot: model.screenshot,
                  strength: 0.8,
                },
              ]);
            }
          } else if (type === "SD") {
            setModelSD({
              name: model.model_name,
              id: model.model_id,
              screenShot: model.screenshot,
            });
            handleClose();
          } else if (type === "SDXL") {
            setModelSDXL({
              name: model.model_name,
              id: model.model_id,
              screenShot: model.screenshot,
            });
            handleClose();
          }
        }}
      >
        {/* 選択済みチェックアイコン */}
        {loraSD.some((item) => item.id === model.model_id) && (
          <CheckCircleIcon
            sx={{
              position: "absolute",
              top: 4,
              right: 4,
              color: theme.palette.success.main,
              zIndex: 2,
            }}
          />
        )}

        <RowBox sx={{ position: "absolute", top: 4, left: 4, zIndex: 200 }}>
          {model.civitai_url && <ModelPopover model={model} bg={true} />}
          <Box
            sx={{
              bgcolor: alpha(aiEngineColor(model.base_model), 0.8),
              zIndex: 2,
              color: theme.palette.common.white,
              px: 1,
              borderRadius: 2,
              ml: 0.5,
              pb: 0.5,
            }}
          >
            <Typography variant={"caption"}>{model.base_model}</Typography>
          </Box>
        </RowBox>

        <Box
          sx={{
            height: 212,
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {imgError[model.model_id] ? (
            // 画像が読み込めなかった場合のアイコン表示
            <PhotoIcon />
          ) : (
            // 画像を表示
            <img
              src={model.screenshot}
              alt={model.model_name}
              loading="lazy"
              style={{
                width: "101%",
                height: "101%",
                display: "block",
                objectFit: "cover",
                transition: "transform 0.4s ease-in-out",
              }}
              onError={() =>
                setImgError((prev) => ({
                  ...prev,
                  [model.model_id]: true,
                }))
              }
            />
          )}
        </Box>
        <Typography
          variant="body2"
          sx={{
            textAlign: "left",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
            px: 1,
            py: 0.5,
            // '&:hover': {
            //     textOverflow: 'unset',
            //     overflow: 'unset',
            //     whiteSpace: 'unset',
            // },
            color: theme.palette.common.white,
          }}
        >
          {model.model_name}
        </Typography>
      </Card>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 1200,
          bgcolor: "background.paper" + "D9",
          boxShadow: 24,
          maxHeight: 800,
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          p={4}
          sx={{ overflow: "hidden", position: "relative", height: "100%", display: "flex", flexDirection: "column" }}
        >
          <IconButton onClick={handleClose} sx={{ position: "absolute", top: 4, right: 4 }} size={"small"}>
            <CloseIcon fontSize={"small"} />
          </IconButton>
          <ColumnBox>
            <RowBox sx={{ justifyContent: "space-between", mb: 2 }}>
              <Box>
                <Typography variant="h5" sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <DatasetIcon sx={{ mr: 2 }} color={"primary"} />
                  {t(`juno.input.${type}List`)}
                </Typography>
                <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                  {t(`juno.input.listDescription`)}
                </Typography>
              </Box>
              {/* Search Field */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {/* FilterSwitch */}
                <FormGroup row>
                  <FormControlLabel
                    sx={{ mr: 2 }}
                    control={<Switch checked={onlyRecommended} />}
                    value={onlyRecommended}
                    label={t("juno.input.recommendedModelsOnly")}
                    onChange={(event, checked) => setOnlyRecommended(checked)}
                  />
                  <FormControlLabel
                    sx={{ mr: 2 }}
                    control={<Switch checked={hideNsfw} />}
                    value={hideNsfw}
                    label={t("juno.input.nsfwFilter")}
                    onChange={(event, checked) => setHideNsfw(checked)}
                  />
                </FormGroup>
                <TextField
                  label={t(`juno.input.modelSearch`)}
                  name="model"
                  variant="outlined"
                  sx={{ minWidth: 250 }}
                  onChange={(e) => setQuery(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  autoFocus
                />
              </Box>
            </RowBox>
            <Typography variant="caption" sx={{ display: "flex", alignItems: "center" }}>
              {t(`juno.input.caution`)}
            </Typography>
          </ColumnBox>

          <Grid container spacing={2} height={"100%"} sx={{ pt: 2 }}>
            <Grid
              item
              xs={type === "lora" && loraSD.length > 0 ? 10 : 12}
              height={"100%"}
              sx={{ overflowY: "auto", height: "calc(100% - 76px)" }}
            >
              <Grid container spacing={2}>
                {loading ? <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} /> : ""}
                {list.length > 0 &&
                  list.map((model, index) => {
                    return (
                      <Grow in={true} timeout={Math.min(index * 50, 500)} key={index}>
                        <Grid item xs={type === "lora" && loraSD.length > 0 ? 2.4 : 2} key={index}>
                          <CustomCard model={model} />
                        </Grid>
                      </Grow>
                    );
                  })}
              </Grid>
              {!loading && hasMore && (
                <div ref={loadingRef} style={{ textAlign: "center", margin: "20px 0" }}>
                  {page != 1 && <CircularProgress />}
                </div>
              )}
            </Grid>
            {/* 選択中のLoRA */}
            {type === "lora" && loraSD && loraSD.length > 0 && (
              <Grid item xs={2} sx={{ height: "100%" }}>
                <StyledTypography variant={"subtitle1"} mb={2} sx={{ whiteSpace: "nowrap" }}>
                  {t("juno.input.selectedLora")}
                  <Typography variant={"caption"} ml={2}>
                    {loraSD.length} / {loraLimit}
                  </Typography>
                </StyledTypography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "calc(100% - 120px)",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      height: "calc(100%)",
                      overflowY: "auto",
                      mb: 2,
                      pr: 2,
                    }}
                  >
                    {loraSD.map((model, index) => (
                      <Grow in={true} timeout={Math.min(500)} key={index}>
                        <Box mb={2}>
                          <Box
                            sx={{
                              position: "relative",
                              width: "100%",
                              height: 100,
                              overflow: "hidden",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 2,
                              mb: 1,
                            }}
                          >
                            <IconButton
                              onClick={(e) => setLoraSD(loraSD.filter((_, i) => i !== index))}
                              size={"small"}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                zIndex: 3,
                                color: theme.palette.text.secondary,
                              }}
                            >
                              <CloseIcon fontSize={"small"} sx={{ color: "white" }} />
                            </IconButton>
                            {imgError[model.id] ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100px",
                                  background: "linear-gradient(135deg, #7f5a83, #0d324d)",
                                }}
                              />
                            ) : (
                              <img
                                src={model.screenShot as string}
                                alt={model.name}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  objectFit: "cover",
                                  filter: "blur(4px) brightness(50%)",
                                }}
                              />
                            )}
                            <Typography
                              key={index}
                              variant="body1"
                              sx={{
                                position: "absolute",
                                color: "white",
                                zIndex: 2,
                                p: 1,
                                ml: 1,
                                fontWeight: "bold",
                                whiteSpace: "break-spaces",
                                textOverflow: "ellipsis",
                                width: "100%",
                                overflow: "hidden",
                              }}
                            >
                              {model.name}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"caption"} sx={{ color: theme.palette.text.secondary }}>
                              {t("juno.input.loraStrength")}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              gap={1}
                            >
                              <Slider
                                size={"small"}
                                defaultValue={0.8}
                                value={loraSD[index].strength}
                                onChange={(event, value) => {
                                  const newStrength = Array.isArray(value) ? value[0] : (value as number);
                                  const updatedLoraSD = loraSD.map((item, idx) => {
                                    if (idx === index) {
                                      return { ...item, strength: newStrength };
                                    }
                                    return item;
                                  });
                                  setLoraSD(updatedLoraSD);
                                }}
                                min={-1}
                                max={2}
                                step={0.05}
                              />
                              <Typography
                                sx={{
                                  ml: 1,
                                  width: 40,
                                  textAlign: "center",
                                }}
                              >
                                {loraSD[index].strength.toFixed(2)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grow>
                    ))}
                  </Box>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => handleClose()}
                    fullWidth
                    sx={{ width: "100%" }}
                  >
                    {t("common.apply")}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
};
export default SelectModelModal;
