import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HideImageIcon from "@mui/icons-material/HideImage";
import ReportIcon from "@mui/icons-material/Report";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, ButtonBase, Fade } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ImageInfo } from "../../../../types/junoTypes";
import Like from "./Like";
import { useCommonContext } from "../../../../context/juno/CommonContext";

interface RequestImageSliderProps {
  images: ImageInfo[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  setOpenModal: (open: boolean) => void;
  setSelectedImageUuid: (uuid: string) => void;
}

const HistoryImageSlider = ({
  images,
  activeIndex,
  setActiveIndex,
  setOpenModal,
  setSelectedImageUuid,
}: RequestImageSliderProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { filtered, setFiltered } = useCommonContext();

  const handleImageClick = (uuid: string) => {
    setSelectedImageUuid(uuid);
    setOpenModal(true);
  };

  const handleThumbnailClick = (index: number) => {
    setActiveIndex(index);
  };

  const handlePreviousClick = () => {
    const newIndex = activeIndex > 0 ? activeIndex - 1 : images.length - 1;
    setActiveIndex(newIndex);
  };

  const handleNextClick = () => {
    const newIndex = activeIndex < images.length - 1 ? activeIndex + 1 : 0;
    setActiveIndex(newIndex);
  };

  return (
    <Grow in={true} timeout={2000}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {images[0].request_status === "Success" ? (
          <>
            <Box sx={{ mb: 1, position: "relative", overflow: "hidden" }}>
              <Box
                onClick={() => handleImageClick(images[activeIndex].uuid)}
                sx={{
                  display: "flex",
                  overflow: "hidden",
                  borderRadius: 2,
                  maxHeight: "300px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "inline-flex",
                    transition: "0.2s ease",
                    "&:hover": {
                      cursor: "pointer",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <img
                    alt="GeneratedImage"
                    id={images[activeIndex].uuid}
                    src={
                      images[activeIndex].r2_image ? "/images/oversized-image.jpg" : images[activeIndex].url + "public"
                    }
                    style={{
                      width: "100%",
                      maxHeight: "300px",
                      filter: images[activeIndex].safe_level > 2 && filtered ? "blur(40px)" : "none",
                      transform: images[activeIndex].safe_level > 2 && filtered ? "scale(1.5)" : "none",
                      transition: "0.2s ease",
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <Fade in={true} timeout={200}>
                  <Box sx={{ position: "absolute", bottom: 8, right: 8 }}>
                    <Like uuid={images[activeIndex].uuid} />
                  </Box>
                </Fade>

                {images[activeIndex].safe_level > 2 && filtered && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      zIndex: 2,
                      width: "100%",
                      p: 4,
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <ReportIcon sx={{ mr: 1 }} color={"warning"} />
                        <Typography variant={"body1"}>{t("juno.input.filtered")}</Typography>
                      </Box>
                      <Typography variant={"caption"} whiteSpace={"break-spaces"} sx={{ textAlign: "left" }}>
                        {t("juno.input.filteredDescription")}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {images[activeIndex].safe_level > 2 && (
                  <Tooltip title={filtered ? t("juno.input.hide") : t("juno.input.show")}>
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setFiltered(!filtered);
                      }}
                      sx={{ position: "absolute", top: 8, right: 8, zIndex: 3, color: "white" }}
                      aria-label="toggle blur"
                      size={"small"}
                    >
                      {filtered ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>

            {images.length > 1 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <IconButton onClick={handlePreviousClick} sx={{ mr: 1 }}>
                  <ArrowBackIosNewIcon fontSize={"small"} />
                </IconButton>
                <Box
                  sx={{
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                  gap={0.5}
                >
                  {images.map((image, index) => (
                    <ButtonBase
                      key={index}
                      onClick={() => handleThumbnailClick(index)}
                      sx={{
                        p: 0,
                        minWidth: "auto",
                        overflow: "hidden",
                        border: activeIndex === index ? `2px solid ${theme.palette.primary.main}` : "none",
                      }}
                    >
                      <img
                        src={image.url + "public"}
                        style={{
                          maxHeight: 40,
                          filter: image.safe_level > 2 && filtered ? "blur(8px)" : "none",
                          transform: image.safe_level > 2 ? "scale(1.5)" : "none",
                        }}
                        loading={"lazy"}
                        alt=""
                      />
                    </ButtonBase>
                  ))}
                </Box>
                <IconButton onClick={handleNextClick} sx={{ ml: 1 }}>
                  <ArrowForwardIosIcon fontSize={"small"} />
                </IconButton>
              </Box>
            )}
          </>
        ) : images[0].request_status === "Processing" ? (
          // 生成中画像
          <Box
            sx={{
              p: 2,
              alignItems: "center",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              bgcolor: theme.palette.background.default,
              borderRadius: 2,
            }}
          >
            <Typography variant={"body1"} fontWeight={"500"} sx={{ mb: 2 }}>
              {t("juno.history.generating")}
            </Typography>
            <CircularProgress size={20} thickness={4} sx={{ mb: 2 }} />
            <Typography
              variant={"caption"}
              color={theme.palette.text.secondary}
              sx={{ whiteSpace: "break-spaces", mx: 2 }}
            >
              {t("juno.history.generatingDescription")}
            </Typography>
          </Box>
        ) : (
          // 画像が見つからない場合
          <Box
            sx={{
              p: 2,
              alignItems: "center",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              bgcolor: theme.palette.background.default,
              borderRadius: 2,
            }}
          >
            <Typography variant={"body1"} fontWeight={"500"} sx={{ mb: 2 }}>
              No Images Found
            </Typography>
            <HideImageIcon color={"error"} sx={{ mb: 2 }} />
            <Typography variant={"caption"} paragraph color={theme.palette.text.secondary} sx={{ px: 2 }}>
              Sorry, something went wrong. Please try again later. This error can also occur if you are violating
              policies.
            </Typography>
          </Box>
        )}
      </Box>
    </Grow>
  );
};

export default HistoryImageSlider;
