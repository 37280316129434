import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BiSolidCrown } from "react-icons/bi";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { alpha } from "@mui/material/styles";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";

const ProLabel = (props) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const handleProClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate("/pricing");
  };

  return (
    <Tooltip title={t("common.pro")} arrow>
      <Box
        sx={{
          ...props.sx,
          backgroundColor: alpha("#c29e00", 0.6),
          color: "common.white",
          px: 0.6,
          py: 0.2,
          borderRadius: 1,
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: alpha("#c29e00", 1),
          },
          transition: "background-color 0.3s",
        }}
        onClick={props.disabeledLink ? undefined : handleProClick}
      >
        <BiSolidCrown style={{ marginRight: 4, fontSize: 12 }} />
        <Typography variant={"caption"} sx={{ fontWeight: "bold" }}>
          PRO
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default ProLabel;
