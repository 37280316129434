import { Route, Routes } from "react-router-dom";
import Explore from "../pages/Juno/Explore";
import ImageGenerator from "../pages/Juno/ImageGenerator";
import GalleryAndLikes from "../pages/Juno/GalleryAndLikes";
import ImageTemplates from "../pages/Juno/ImageTemplates";
import React from "react";
import VideoGenerator from "../pages/Juno/VideoGenerator";
import MyVideos from "../pages/Juno/MyVideos";

const JunoRouter = () => {
  return (
    <Routes>
      <Route path="" element={<Explore />} />
      <Route path="image-generator" element={<ImageGenerator />} />
      <Route path="video-generator" element={<VideoGenerator />} />
      <Route path="gallery" element={<GalleryAndLikes />} />
      <Route path="videos" element={<MyVideos />} />
      <Route path="likes" element={<GalleryAndLikes />} />
      <Route path="quick-generator" element={<ImageTemplates />} />
    </Routes>
  );
};
export default JunoRouter;
