import axios from "axios";
import Cookies from "js-cookie";

export const useBrowsingMethods = () => {
  const addNewWorkspace = async () => {
    try {
      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      const res = await axios.post("/api/v1/workspace/", {}, config);
      return res;
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  return {
    addNewWorkspace,
  };
};
