const aboutJuno = {
  title: "Image Generator - STUDIO JUNO",
  subtitle: "Welcome to STUDIO JUNO",
  description: "Enjoy a comfortable image generation experience with STUDIO JUNO",
  meta: {
    description:
      "AI that turns your ideas into beautiful images. Equipped with a wide range of functions from text to image generation to image editing. Even beginners can easily create high-quality images.",
  },
  section1: {
    title: "Generate any image as you like",
    t2i: {
      title: "Text to Image",
      description:
        "Generates images from text. Visualizes your ideas and concepts as visually beautiful, high-quality images.",
    },
    i2i: {
      title: "Image to Image",
      description:
        "Generates new images based on images. You can create new images while maintaining common styles, and adjust the similarity.",
    },
    upscale: {
      title: "Upscale and Enhance Image",
      description:
        "Upscales old photos and illustrations to clear images, and enhances them by complementing missing pixels.",
    },
  },
  section2: {
    title: "Infinite possibilities with a wide range of models",
    card1: {
      title: "Covering popular models",
      description:
        "Stimulate your creativity with a variety of models such as DALL-E 3, Stable Diffusion 3, and FLUX.1.",
    },
    card2: {
      title: "Latest and innovative features",
      description: "Realize higher quality works by utilizing the latest technologies and models such as AuraSR.",
    },
    card3: {
      title: "Quick AI templates",
      description: "Experience AI image generation easily with templates that exclude difficult parameter settings.",
    },
  },
  section3: {
    title: "Turn your imagined images into reality in an instant.",
  },
  section4: {
    title: "Turn any image into a video you imagine.",
    subtitle: "From text to video, from image to video. Visualize your ideas as videos.",
    description: "STUDIO JUNO offers the world's leading video generation AI models",
  },
  try: "Try STUDIO JUNO",
};
export default aboutJuno;
