import { Box, IconButton, Typography } from "@mui/material";
import { AspectRatio } from "../../../../types/junoTypes";
import React from "react";
import { useParamsContext } from "../../../../context/juno/ParamsContext";

interface AspectRatioProps {
  aspectRatios: { ratio: string; icon: React.ElementType; rotate?: string }[];
}

const RenderAspectRatioIcons = ({ aspectRatios }: AspectRatioProps) => {
  const params = new URLSearchParams(window.location.search);
  const ai_engine = params.get("ai_engine") || "";
  const { aspectRatio, setAspectRatio } = useParamsContext();

  return (
    <>
      {aspectRatios.map(({ ratio, icon: Icon, rotate }) => (
        <Box key={ratio} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => setAspectRatio({ ...aspectRatio, [ai_engine]: ratio as AspectRatio })}
            color={aspectRatio[ai_engine] === ratio ? "primary" : "default"}
          >
            <Icon sx={{ rotate: rotate || "0deg" }} />
          </IconButton>
          <Typography
            variant={"caption"}
            onClick={() => setAspectRatio({ ...aspectRatio, [ai_engine]: ratio as AspectRatio })}
            sx={{ cursor: "pointer" }}
          >
            {ratio}
          </Typography>
        </Box>
      ))}
    </>
  );
};
export default RenderAspectRatioIcons;
