import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import CropDinIcon from "@mui/icons-material/CropDin";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Slider,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AspectRatio, StyleFluxJuno } from "../../../../types/junoTypes";

import ImageUpload from "./ImageUpload";
import SelectPublic from "./SelectPublic";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParamsContext } from "../../../../context/juno/ParamsContext";
import { useUploadImageContext } from "../../../../context/juno/UploadImageContext";

const aspectRatios = [
  { ratio: "1:1", icon: CropDinIcon },
  { ratio: "16:9", icon: Crop169Icon },
  { ratio: "4:3", icon: Crop32Icon },
  { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
  { ratio: "3:4", icon: Crop32Icon, rotate: "90deg" },
];

export default function ParamsFlux() {
  const { t } = useTranslation();
  const {
    aspectRatio,
    setAspectRatio,
    sample,
    setSample,
    style,
    setStyle,
    styleWeight,
    setStyleWeight,
    step,
    setStep,
    guidanceScale,
    setGuidanceScale,
    seed,
    setSeed,
    rawMode,
    setRawMode,
  } = useParamsContext();
  const { imageStrength, setImageStrength } = useUploadImageContext();

  const [openAdvancedSettings, setOpenAdvancedSettings] = useState(true);

  const params = new URLSearchParams(window.location.search);
  const ai_engine = params.get("ai_engine") || "";
  const menu = params.get("menu") || "txt2img";
  const icons = Array.from({ length: 4 }, (_, index) => index + 1);

  const renderAspectRatioIcons = () => {
    return aspectRatios.map(({ ratio, icon: Icon, rotate }) => (
      <Box key={ratio} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <IconButton
          onClick={() => setAspectRatio({ ...aspectRatio, [ai_engine]: ratio as AspectRatio })}
          color={aspectRatio[ai_engine] === ratio ? "primary" : "default"}
        >
          <Icon sx={{ rotate: rotate || "0deg" }} />
        </IconButton>
        <Typography
          variant={"caption"}
          onClick={() => setAspectRatio({ ...aspectRatio, [ai_engine]: ratio as AspectRatio })}
          sx={{ cursor: "pointer" }}
        >
          {ratio}
        </Typography>
      </Box>
    ));
  };

  const handleSampleChange = (index: number) => {
    setSample({ ...sample, [ai_engine]: index + 1 });
  };

  const [disableChangeSample, setDisableChangeSample] = useState(false);

  useEffect(() => {
    if (ai_engine === "Flux11Pro" || ai_engine === "Flux11ProUltra" || ai_engine === "Flux1RealismLoRA") {
      setDisableChangeSample(true);
    } else {
      setDisableChangeSample(false);
    }
  }, [ai_engine]);

  return (
    <>
      <Stack spacing={1} mb={3}>
        <Box sx={{ display: "flex", flexDirection: "column" }} gap={1}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography variant={"body2"}>{t("juno.input.sample")}</Typography>
          </Box>
          <Box sx={{ display: "inline-flex", flexDirection: "row" }} gap={1}>
            <ButtonGroup variant="outlined" sx={{ width: "100%" }}>
              {icons.map((icon, index) => (
                <Button
                  disableElevation
                  key={index}
                  onClick={() => handleSampleChange(index)}
                  variant={sample[ai_engine] === index + 1 ? "contained" : "outlined"}
                  disabled={disableChangeSample}
                  sx={{ width: "100%" }}
                >
                  <Typography variant={"button"}>{icon}</Typography>
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </Box>
      </Stack>

      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 2 }}>
        <SettingsSuggestIcon sx={{ mr: 1 }} />
        <Typography variant={"subtitle1"} component={"p"}>
          {t("juno.input.basicSettings")}
        </Typography>
      </Box>

      {menu === "img2img" && (
        <Box mb={2}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
            <Typography variant={"body2"}>{t("juno.input.image")}</Typography>
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <ImageUpload />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
              <Typography variant={"body2"}>{t("juno.input.imageStrength")}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", ml: 0 }} gap={2}>
              <Slider
                value={imageStrength}
                onChange={(e, value) => setImageStrength(value as number)}
                step={0.01}
                min={0.1}
                max={1}
              />
              <Box
                sx={{
                  width: "120px",
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  px: 1,
                  py: 0.5,
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "5px",
                }}
              >
                <Typography variant={"body2"}>{imageStrength}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {menu === "txt2img" && (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
            <Typography variant={"body2"}>{t("juno.input.aspectRatio.title")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
            {renderAspectRatioIcons()}
          </Box>
        </>
      )}

      {/* Style */}
      {ai_engine === "FluxJuno" && (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
            {/* SelextBox */}
            <FormControl fullWidth>
              <InputLabel id="ai-engine-select-label">{t("juno.input.style.title")}</InputLabel>
              <Select
                size={"small"}
                value={style[ai_engine]}
                label={t("juno.input.style.title")}
                onChange={(event) => {
                  const newValue = event.target.value as StyleFluxJuno;
                  setStyle({ ...style, [ai_engine]: newValue });
                }}
              >
                <MenuItem value="ANIME">{t("juno.input.style.fluxJuno.anime")}</MenuItem>
                <MenuItem value="JAPANESE">{t("juno.input.style.fluxJuno.japanese")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
              <Typography variant={"body2"}>{t("juno.input.style.weight")}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
              <Slider
                value={styleWeight[ai_engine]}
                onChange={(e, value) => setStyleWeight({ ...styleWeight, [ai_engine]: value as number })}
                min={0}
                max={1.5}
                step={0.1}
                marks={[{ value: 0.8, label: "" }]}
              />
              <Box
                sx={{
                  width: "120px",
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  px: 1,
                  py: 0.5,
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "5px",
                }}
              >
                <Typography variant={"body2"}>{styleWeight[ai_engine]}</Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {["Flux11ProUltra"].includes(ai_engine) && (
        //   Rawモードのトグルスイッチ
        <FormGroup>
          <Tooltip title={t("juno.input.rawModeDescription")} placement="top">
            <FormControlLabel
              control={
                <Switch checked={rawMode} onChange={() => setRawMode(!rawMode)} name="rawMode" color="primary" />
              }
              label={t("juno.input.rawMode")}
            />
          </Tooltip>
        </FormGroup>
      )}

      <Box>
        <Divider sx={{ my: 2 }} />
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ mb: 2, cursor: "pointer" }}
          onClick={() => setOpenAdvancedSettings(!openAdvancedSettings)}
        >
          {openAdvancedSettings ? <ExpandLess sx={{ mr: 1 }} /> : <ExpandMore sx={{ mr: 1 }} />}
          <Typography variant={"subtitle1"} component={"p"}>
            {t("juno.input.advancedSettings")}
          </Typography>
        </Box>
      </Box>

      <Collapse in={openAdvancedSettings} sx={{ mb: 4 }}>
        {/* ステップ */}
        {["Flux1Dev", "Flux1Schnell", "FluxJuno", "Flux1RealismLoRA"].includes(ai_engine) && (
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
              <Typography variant={"body2"}>{t("juno.input.step")}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
              <Slider
                value={step[ai_engine]}
                onChange={(e, value) => setStep({ ...step, [ai_engine]: value as number })}
                min={ai_engine === "Flux1Schnell" ? 1 : 10}
                max={ai_engine === "Flux1Schnell" ? 12 : 50}
                step={1}
              />
              <Box
                sx={{
                  width: "120px",
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  px: 1,
                  py: 0.5,
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "5px",
                }}
              >
                <Typography variant={"body2"}>{step[ai_engine]}</Typography>
              </Box>
            </Box>
          </Box>
        )}

        {/* CFG Scale */}
        {["Flux1Dev", "Flux1Schnell", "FluxJuno", "Flux1RealismLoRA"].includes(ai_engine) && (
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
              <Typography variant={"body2"}>{t("juno.input.guidanceScale")}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
              <Slider
                getAriaValueText={(value) => `${value}px`}
                value={guidanceScale[ai_engine]}
                onChange={(e, value) => setGuidanceScale({ ...guidanceScale, [ai_engine]: value as number })}
                step={0.5}
                min={1}
                max={20}
              />
              <Box
                sx={{
                  width: "120px",
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  px: 1,
                  py: 0.5,
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "5px",
                }}
              >
                <Typography variant={"body2"}>{guidanceScale[ai_engine]}</Typography>
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
          <TextField
            sx={{ mt: 1, width: "100%" }}
            label={t("juno.input.seedSD")}
            size={"small"}
            variant={"outlined"}
            value={seed[ai_engine]}
            onChange={(event) => {
              if (event.target.value.match(/^[0-9]+$/)) {
                setSeed({ ...seed, [ai_engine]: Number(event.target.value) });
              }
            }}
          />
          <Box>
            <IconButton onClick={() => setSeed({ ...seed, [ai_engine]: 0 })}>
              <RestartAltIcon />
            </IconButton>
          </Box>
        </Box>
      </Collapse>

      <SelectPublic />
    </>
  );
}
