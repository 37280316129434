const pricing = {
  title: "Plans and Pricing",
  pricing: "PRICING",
  description: "Plans and pricing of Tenak AI",
  legalNotice: "Act on specified commercial transactions.",
  free: {
    title: "FREE",
    description: "Free Plan",
    price: "0",
    credit: "50",
  },
  basic: {
    title: "Basic",
    description: "Standard Plan",
    price: "18",
    credit: "900",
  },
  standard: {
    title: "Standard",
    description: "Business Plan",
    price: "36",
    credit: "1,800 + 200",
  },
  premium: {
    title: "Premium",
    description: "Premium Plan",
    price: "72",
    credit: "3,600 + 600",
  },
  perMonth: "Per Month",
  credit: "Credits",
  aiEngine: "Powerful AI Engine",
  juno: "Image Generation",
  junoPrivate: "Private Generated Images",
  junoEngine: "All AI Engines (Images)",
  junoEnginePart: "Part of AI Engines (Images)",
  junoModel: "All Image Models & LoRA",
  mindmap: "Mindmap Generation",
  export: "Export Mindmap",
  template: "All AI Templates",
  textEditor: "AI Text Editor",
  voice: "Voice Generation",
  support: "Support",
  supportPriority: "Priority Support",
  supportNo: "No Support",
  subscribe: "Subscribe",
  aboutCredit: {
    title: "About Credits",
    description:
      "Tenak AI's plans use a credit system. Credits are the currency used for utilizing AI functions such as text, image, and voice generation. Below are the estimated credit consumption guidelines. ",
    text: {
      title: "Text Generation",
      description: "Calculated based on the number of system, input, and output tokens (characters)",
      tooltip: 'When using "Speed Mode", 0.1 per 40K tokens',
    },
    image: {
      title: "Image Generation",
      dalle3: "OPEN AI: DALL-E 3",
      sdxl: "Stable Diffusion (XL)",
      sd3: "Stable Diffusion 3.5",
      sdUltra: "Stable Image Ultra",
      flux1pro: "Flux 1.1 Pro",
      flux1dev: "Flux.1 Dev",
      flux1Schnell: "Flux.1 Schnell",
      flux1lora: "Flux.1 with Realism LoRA",
      flux1juno: "Flux.1 with STUDIO JUNO",
      recraftV3: "Recraft V3",
      ideogram2: "Ideogram 2.0",
      ideogram2turbo: "Ideogram 2.0 Turbo",
      auraSR: "Upscaler AuraSR",
      clarityUpscaler: "Upscaler Clarity Upscaler",
      creativeUpscaler: "Upscaler Creative Upscaler",
    },
    video: {
      title: "動画生成",
      runway: "Runway Gen3 Alpha Turbo",
      kling1standard: "Kling v1.0 Standard",
      kling1pro: "Kling v1.0 Pro",
      dreamMachine15: "Dream Machine v1.5",
      hailuo: "Mini Max",
    },
    voice: {
      title: "Voice Generation",
      google: "Google TTS (1,000 characters)",
      openai: "OPEN AI TTS (1,000 tokens)",
    },
    mindmap: {
      title: "Mindmap Generation",
      description: "When not using AI (depends on text generation when using AI generator)",
    },
    annotation:
      "* The above is a rough estimate and may vary depending on the content and usage. * The calculation will be rounded up to the second decimal place.",
  },
  faq: {
    title: "Frequently Asked Questions",
    description: "Common questions about Tenak AIs plans and pricing",
    q1: "Can credits be carried over?",
    a1: "No. Credits cannot be carried over.",
    q2: "If I upgrade, will the fee be prorated?",
    a2: "No. Since we use a credit system, the full amount will be charged. Then, the credits you have and the credits from the new plan will be combined, and one month from that day will be the expiration date for the credits and also the next billing date.",
    q3: "Can I purchase credits only?",
    a3: "No. Credits are included in the plan, and you will receive credits by purchasing a plan.",
    q4: "What happens to my credits if I cancel the plan?",
    a4: "If you cancel the plan, your credits will expire at the renewal month.",
    q5: "What should I do if I run out of credits?",
    a5: "If you run out of credits, please upgrade your plan.",
  },
};
export default pricing;
