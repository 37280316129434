// Workspaceの削除
import React, { useEffect } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { Box, DialogContentText, Grow, TextField, Tooltip, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import axios from "axios";
import TitleIcon from "@mui/icons-material/Title";
import CreateIcon from "@mui/icons-material/Create";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { triggerWorkspaceFetch } from "../../../redux/slices/browsingSlice";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { useAlert } from "../../../context/AlertContext";

interface DialogsProps {
  historyExists: boolean;
  setHistoryData: (historyData: any[]) => void;
}

export default function Dialogs({ historyExists, setHistoryData }: DialogsProps) {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { workspaceUuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) {
    return <Grow ref={ref} {...props} />;
  });

  // Workspaceの削除
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const DeleteWorkspaceDialog = () => {
    return (
      <div>
        <Dialog open={openDelete} TransitionComponent={Transition} keepMounted onClose={handleCloseDelete}>
          <DialogTitle>{t("browsing.deleteWorkspace.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("browsing.deleteWorkspace.description")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disableElevation color={"primary"} onClick={handleCloseDelete}>
              {t("common.cancel")}
            </Button>
            <Button disableElevation color={"error"} onClick={handleDeleteWorkspace}>
              {t("common.delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const handleDeleteWorkspace = async () => {
    try {
      handleCloseDelete();
      const csrftoken = Cookies.get("csrftoken");
      const headers = {
        "X-CSRFToken": csrftoken!,
      };
      const res = await axios.delete("/api/v1/workspace/" + workspaceUuid, { headers: headers });
      if (res.data) {
        setAlert("success", t("browsing.deleteWorkspace.success"));
        navigate("/workspace/" + res.data.first_workspace_uuid);
        dispatch(triggerWorkspaceFetch());
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  // 履歴の削除
  const [openHistoriesDelete, setOpenHistoriesDelete] = React.useState(false);
  const handleClickOpenHistoriesDelete = () => {
    setOpenHistoriesDelete(true);
  };
  const handleCloseHistoriesDelete = () => {
    setOpenHistoriesDelete(false);
  };

  const DeleteHistoriesDialog = () => {
    return (
      <div>
        <Dialog
          open={openHistoriesDelete}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseHistoriesDelete}
        >
          <DialogTitle>{t("browsing.deleteHistory.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("browsing.deleteHistory.description")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disableElevation color={"primary"} onClick={handleCloseHistoriesDelete}>
              {t("common.cancel")}
            </Button>
            <Button disableElevation color={"error"} onClick={handleHistoriesDelete}>
              {t("common.delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const handleHistoriesDelete = async () => {
    try {
      handleCloseHistoriesDelete();
      const csrftoken = Cookies.get("csrftoken");
      const headers = {
        "X-CSRFToken": csrftoken!,
      };
      const res = await axios.delete("/api/v1/workspace/" + workspaceUuid + "/history", { headers: headers });
      if (res.data) {
        console.log(res.data);
        setHistoryData([]);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  // カスタム指示
  const [openCustomInstructions, setOpenCustomInstructions] = React.useState(false);
  const handleClickOpenCustomInstructions = () => {
    setOpenCustomInstructions(true);
  };
  const handleCloseCustomInstructions = () => {
    setOpenCustomInstructions(false);
  };

  const CustomInstructionsDialog: React.FC = () => {
    const customInstructionFormSchema = z.object({
      workspaceTitle: z
        .string()
        .min(1, { message: "Required" })
        .max(50, { message: "Please enter within 50 characters." }),
      customInstruction: z.string().max(1000, { message: "Please enter within 1000 characters." }),
    });
    type FormData = z.infer<typeof customInstructionFormSchema>;

    const {
      control,
      handleSubmit,
      setValue,
      watch,
      formState: { errors },
    } = useForm<FormData>({
      resolver: zodResolver(customInstructionFormSchema),
    });

    const getCustomInstruction = async () => {
      const res = await axios.get("/api/v1/workspace/" + workspaceUuid);
      if (res.data) {
        setValue("workspaceTitle", res.data.name);
        setValue("customInstruction", res.data.custom_instruction);
      }
    };
    useEffect(() => {
      if (openCustomInstructions) {
        getCustomInstruction();
      }
    }, [openCustomInstructions]);
    const onSubmit: SubmitHandler<FormData> = async (formData) => {
      try {
        handleCloseCustomInstructions();
        const csrftoken = Cookies.get("csrftoken");
        const headers = {
          "X-CSRFToken": csrftoken!,
        };
        const data = {
          name: formData.workspaceTitle,
          custom_instruction: formData.customInstruction,
        };
        await axios.patch("/api/v1/workspace/" + workspaceUuid, data, { headers: headers });
        dispatch(triggerWorkspaceFetch());
      } catch (error) {
        console.error("An unknown error occurred:", error);
      }
    };

    return (
      <Dialog
        open={openCustomInstructions}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseCustomInstructions}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{t("browsing.customizeWorkspace.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("browsing.customizeWorkspace.description")}</DialogContentText>
            <DialogContentText display={"flex"} variant={"body1"} fontWeight={900} mt={6} alignItems={"center"}>
              <TitleIcon color={"primary"} sx={{ mr: 1 }}></TitleIcon>
              {t("browsing.customizeWorkspace.workspaceTitle")}
            </DialogContentText>
            <DialogContentText mt={1} variant={"body2"}>
              {t("browsing.customizeWorkspace.workspaceTitleDescription")}
            </DialogContentText>
            <Controller
              name="workspaceTitle"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={watch("workspaceTitle", "")}
                  autoFocus
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  error={!!errors.workspaceTitle}
                  helperText={errors.workspaceTitle && errors.workspaceTitle.message}
                />
              )}
            />
            <Box display={"flex"} justifyContent={"flex-end"} sx={{ flexGrow: 1 }}>
              <Typography variant="body1" color="textprimary" sx={{ whiteSpace: "nowrap" }}>
                {watch("workspaceTitle", "").length} / 50
              </Typography>
            </Box>

            <DialogContentText display={"flex"} variant={"body1"} fontWeight={900} mt={2} alignItems={"center"}>
              <CreateIcon color={"primary"} sx={{ mr: 1 }}></CreateIcon>
              {t("browsing.customizeWorkspace.instructionTitle")}
            </DialogContentText>
            <DialogContentText mt={1} variant={"body2"}>
              {t("browsing.customizeWorkspace.instructionDescription")}
            </DialogContentText>
            <Controller
              name="customInstruction"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={watch("customInstruction", "")}
                  margin="dense"
                  multiline
                  maxRows={8}
                  fullWidth
                  variant="outlined"
                  error={!!errors.customInstruction}
                  helperText={errors.customInstruction && errors.customInstruction.message}
                />
              )}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <Button
                color={"primary"}
                onClick={() => setValue("customInstruction", t("browsing.customizeWorkspace.instructionTemplate"))}
              >
                {t("browsing.customizeWorkspace.setTemplate")}
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Typography variant="body1" color="textprimary" sx={{ whiteSpace: "nowrap" }}>
                {watch("customInstruction", "").length} / 1000
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button disableElevation color={"primary"} onClick={handleCloseCustomInstructions}>
              {t("common.cancel")}
            </Button>
            <Button disableElevation color={"primary"} type={"submit"} variant="contained">
              {t("common.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  return (
    <>
      <DeleteWorkspaceDialog />
      <DeleteHistoriesDialog />
      <CustomInstructionsDialog />

      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: 1,
          my: 1,
          position: "sticky",
          top: 0,
        }}
      >
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }} justifyContent={"flex-start"}>
          <Tooltip title={t("browsing.customize")}>
            <IconButton
              color="primary"
              onClick={() => {
                handleClickOpenCustomInstructions();
              }}
            >
              <SettingsSuggestIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }} justifyContent={"center"}>
          <Typography variant={"body2"} sx={{ whiteSpace: "nowrap" }} display={historyExists ? "block" : "none"}>
            {t("browsing.displayHistoryDescribe")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", width: "100%", alignItems: "center", gap: 1 }} justifyContent={"flex-end"}>
          <Tooltip title={t("browsing.clear")}>
            <span>
              <IconButton
                color="primary"
                disabled={!historyExists}
                onClick={() => {
                  handleClickOpenHistoriesDelete();
                }}
              >
                <ClearAllIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t("browsing.delete")}>
            <IconButton
              color="error"
              onClick={() => {
                handleClickOpenDelete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
}
