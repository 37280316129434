export const aiEngineColor = (ai_engine) => {
  switch (ai_engine) {
    case "DallE3":
      return "#3e8674";
    case "SD":
      return "#7753ad";
    case "SDXL":
      return "#5365ad";
    case "SD3":
    case "SD35":
      return "#377079";
    case "SDUltra":
      return "#244a50";
    case "AuraSR":
      return "#3e6e67";
    case "FLUX":
    case "Flux1Pro":
    case "Flux11Pro":
    case "Flux11ProUltra":
      return "#3b3b4f";
    case "Flux1Dev":
      return "#443b4f";
    case "Flux1Schnell":
      return "#4f3b4f";
    case "Flux1RealismLoRA":
      return "#3b424f";
    case "FluxJuno":
      return "#a44040";
    case "RecraftV3":
      return "#c78f2f";
    case "Ideogram2":
      return "#32642c";
    case "Ideogram2Turbo":
      return "#5c8345";
    default:
      return "#181818";
  }
};
