import React from "react";
import { Box, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";
import { BoxProps } from "@mui/material/Box";

interface CenteredBorderTextProps {
  typographyProps?: TypographyProps;
  boxProps?: BoxProps;
  children?: React.ReactNode;
}

export const CenteredBorderText: React.FC<CenteredBorderTextProps> = ({ typographyProps, boxProps, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Box
        {...boxProps}
        sx={{
          ...boxProps?.sx,
          flexGrow: 1,
          height: "1px",
          backgroundColor: "grey.600",
        }}
      />
      <Typography {...typographyProps} sx={{ ...typographyProps?.sx, mx: 2 }}>
        {children}
      </Typography>
      <Box
        {...boxProps}
        sx={{
          ...boxProps?.sx,
          flexGrow: 1,
          height: "1px",
          backgroundColor: "grey.600",
        }}
      />
    </Box>
  );
};
