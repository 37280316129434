import { zodResolver } from "@hookform/resolvers/zod";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useAlert } from "../../../context/AlertContext";
import { RowBox } from "../../../utils/styledBox";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { useTranslation } from "react-i18next";

interface Link {
  url: string;
  title: string;
}

// Zodでバリデーションスキーマを定義
const linkSchema = z.object({
  url: z.string().url({ message: "Please enter a valid URL" }),
  title: z.string().min(1, { message: "Please enter a title" }),
});

const LinkModal = ({ isLinkModalOpen, setIsLinkModalOpen, links, setLinks, data }) => {
  const { setAlert } = useAlert();
  const { t } = useTranslation();

  // React Hook Formをセットアップ
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Link>({
    resolver: zodResolver(linkSchema),
  });

  // フォーム送信時のハンドラー
  const onSubmit = (formData: Link) => {
    setLinks([...links, { ...formData, id: data.id }]);
    setIsLinkModalOpen(false);
    data.url = [...links, { ...formData, id: data.id }];
    setAlert("success", "Link added successfully!");
  };

  return (
    <Modal open={isLinkModalOpen} onClose={() => setIsLinkModalOpen(false)}>
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box>
          <IconButton onClick={() => setIsLinkModalOpen(false)} sx={{ position: "absolute", top: 8, right: 8 }}>
            <ClearIcon fontSize="small" />
          </IconButton>
          <RowBox>
            <AddLinkIcon sx={{ mr: 1 }} color="secondary" />
            <Typography component={"h3"}>{t("mindmap.addLink")}</Typography>
          </RowBox>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Link URL"
              fullWidth
              margin="normal"
              error={!!errors.url}
              helperText={errors.url ? errors.url.message : "Please enter a valid URL"}
              {...register("url")}
              autoFocus
              autoComplete="off"
              placeholder="https://example.com"
            />
            <TextField
              label="Link Title"
              fullWidth
              margin="normal"
              error={!!errors.title}
              helperText={errors.title ? errors.title.message : "Please enter a title"}
              {...register("title")}
              autoComplete="off"
              placeholder="Enter a title"
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained">
                Add Link
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default LinkModal;
