import React, { useState } from "react";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ButtonBase, Fade, Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AiEngineList } from "../../../../context/juno/VideoContext";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const Section4 = () => {
  const { t } = useTranslation();
  const [playingVideo, setPlayingVideo] = useState(null);

  const handlePlayVideo = (value) => {
    setPlayingVideo(value);
  };

  const videos = [
    {
      value: "LUMA",
      label: t("juno.video.ai.luma.title"),
      video: "/videos/juno/luma.mp4",
      thumbnail: "/images/juno/companies/luma-thumbnail.jpg",
    },
    {
      value: "KLING",
      label: t("juno.video.ai.kling.title"),
      video: "/videos/juno/kling.mp4",
      thumbnail: "/images/juno/companies/kling-thumbnail.jpg",
    },
    {
      value: "HAILUO",
      label: t("juno.video.ai.hailuo.title"),
      video: "/videos/juno/hailuo.mp4",
      thumbnail: "/images/juno/companies/hailuo-thumbnail.jpg",
    },
    {
      value: "HAIPER",
      label: t("juno.video.ai.haiper.title"),
      video: "/videos/juno/haiper.mp4",
      thumbnail: "/images/juno/companies/haiper-thumbnail.jpg",
    },
    {
      value: "RUNWAY",
      label: t("juno.video.ai.runway.title"),
      video: "/videos/juno/runway.mp4",
      thumbnail: "/images/juno/companies/runway-thumbnail.jpg",
    },
  ];

  return (
    <>
      <Box component={"section"} sx={{ width: "100%" }}>
        <Grid container spacing={{ xs: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant={"h4"}
              component={"h2"}
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              mb={2}
              textAlign={"center"}
            >
              {t("aboutJuno.section4.title")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant={"h6"}
              component={"h5"}
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              mb={2}
              textAlign={"center"}
            >
              {t("aboutJuno.section4.subtitle")}
            </Typography>
          </Grid>

          {videos.map((item, index) => (
            <Fade in={true} key={index} timeout={(index + 1) * 250}>
              <Grid item xs={12} md={2.4}>
                <Box
                  key={item.value}
                  sx={{
                    borderRadius: 2,
                    width: "100%",
                    height: 75,
                    backgroundImage: `url(images/juno/companies/${item.value.toLowerCase()}.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                  }}
                  aria-label={item.label}
                />
              </Grid>
            </Fade>
          ))}
          <Grid item xs={12} mt={2}>
            <Typography
              variant={"body1"}
              component={"p"}
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              mb={2}
              textAlign={"center"}
            >
              {t("aboutJuno.section4.description")}
            </Typography>
          </Grid>

          {/* sample videos */}
          {videos.map((video) => (
            <Grid item key={video.value} xs={6}>
              <Box sx={{ borderRadius: 2, overflow: "hidden" }}>
                {playingVideo === video.value ? (
                  <video width="100%" controls autoPlay style={{ borderRadius: "8px", overflow: "hidden" }}>
                    <source src={video.video} type="video/mp4" />
                    {t("juno.video.error")}
                  </video>
                ) : (
                  <Box style={{ position: "relative", cursor: "pointer" }} onClick={() => handlePlayVideo(video.value)}>
                    <img
                      src={video.thumbnail}
                      alt={video.value}
                      width="100%"
                      style={{ borderRadius: "8px", overflow: "hidden" }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -60%)",
                        color: "white",
                        fontSize: 60,
                      }}
                      size="large"
                      aria-label="play"
                    >
                      <PlayCircleOutlineIcon fontSize="large" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
export default Section4;
