import React from "react";
import { Grid, Stack } from "@mui/material";
import SelectMenu from "./components/image/SelectMenu";
import SelectAi from "./components/image/SelectAi";
import ParamsManager from "./components/image/ParamsManager";
import History from "./components/image/History";

import { uiHeight } from "../../utils/uiHeight";
import Meta from "../../components/common/Meta";
import { useTranslation } from "react-i18next";

const ImageGenerator = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta
        title={t("juno.goToGenerator")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
        route={"Studio JUNO by Tenak AI"}
      />
      <Grid
        container
        spacing={1}
        justifyContent="center"
        sx={{ height: { xs: "100%", sm: `calc(${uiHeight()} - 65px)` }, px: { xs: 0, md: 2 } }}
      >
        {/* Menu */}
        <Grid item xs={12} md={2.5} sx={{ height: "100%" }}>
          <Stack spacing={1} sx={{ height: "100%" }}>
            <SelectMenu />
            <SelectAi />
            <ParamsManager />
          </Stack>
        </Grid>

        {/* Main */}
        <Grid item xs={12} md={9.5} sx={{ height: "100%" }}>
          <History />
        </Grid>
      </Grid>
    </>
  );
};
export default ImageGenerator;
