import React from "react";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import TextField from "@mui/material/TextField";
import SidebarAnswerBox from "./SidebarAnswerBox";
import { useFileSidebarContext } from "../../../../context/FileSidebarContext";
import SidebarButtonList from "./SidebarButtonList";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import { useCheckCredit } from "../../../../hooks/useCreditCheck";
import GenerateButton from "./GenerateButton";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import { marked } from "marked";

export default function Assistant() {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const { fileUuid } = useParams();
  const selection = useSelector((state: RootState) => state.file.selection)[fileUuid!] || { text: "" };
  const fileText = useSelector((state: RootState) => state.file.fileText)[fileUuid!];
  const fileTitle = useSelector((state: RootState) => state.file.fileTitle)[fileUuid!];
  const [target, setTarget] = React.useState<string>("");
  const [chatResponse, setChatResponse] = React.useState<string>("");
  const context = useFileSidebarContext();
  const dispatch = useDispatch();

  const validate = () => {
    let requiredError = false;
    if (context.selectedButton === "") {
      requiredError = true;
    }
    if (
      [
        "createTitles",
        "createIntroduction",
        "createConclusion",
        "createTags",
        "createNarration",
        "createContinue",
      ].includes(context.selectedButton) &&
      fileText.length === 0
    ) {
      requiredError = true;
    }
    if (
      [
        "createSummary",
        "createRephrase",
        "createLonger",
        "createCopyWriting",
        "createVoice",
        "createImages",
        "createTranslate",
        "createTone",
        "createText",
        "createCheckText",
        "createFAQ",
      ].includes(context.selectedButton) &&
      context.originalText.length === 0
    ) {
      requiredError = true;
    }
    if (context.selectedButton === "createTone" && context.selectedTone.length === 0) {
      requiredError = true;
    }
    if (context.selectedButton === "createTags" && context.selectedSns.length === 0) {
      requiredError = true;
    }
    if (context.selectedButton === "createTranslate" && context.language.length === 0) {
      requiredError = true;
    }
    if (context.selectedButton === "createCustom" && context.customMessage.length === 0) {
      requiredError = true;
    }
    if (requiredError) {
      console.log("requiredError");
      context.setProcessing(false);
      return { error: true, message: t("textEditor.sidebar.error.message") };
    }
    return { error: false, message: "" };
  };

  // 見出しか、テキストを返す
  const getHeadingsOrText = () => {
    if (fileText.length < 2000) {
      return fileText;
    } else {
      const fileHTML = marked(fileText);
      const regex = /<h[1-6]>(.*?)<\/h[1-6]>/g;
      const matches = fileHTML.match(regex);
      const heading = matches ? matches.map((match) => match.replace(/<\/?h[1-6]>/g, "")) : [];
      const text = `見出し一覧: ${heading.join(", ")}\n\n${fileText}`;
      return text;
    }
  };

  const handleCreate = async () => {
    try {
      setChatResponse("");
      context.setProcessing(true);

      // 入力チェック
      const valid = validate();
      if (valid.error) {
        setChatResponse(valid.message);
        context.setProcessing(false);
        return;
      }

      if (!(await checkCredit())) {
        context.setProcessing(false);
        return;
      }

      const csrftoken = Cookies.get("csrftoken");
      const headers = {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      };

      // アクションに合わせたコンテンツ処理
      const content = (() => {
        switch (context.selectedButton) {
          case "createTitles":
            return getHeadingsOrText();
          case "createIntroduction":
            return getHeadingsOrText();
          case "createConclusion":
            return getHeadingsOrText();
          case "createTags":
            return getHeadingsOrText();
          case "createContinue":
            // テキストの場合は、選択されたテキストから最後の1000文字を返す
            const index = typeof selection?.index === "number" ? selection.index : 0;
            const startIndex = index - 1000;
            let content = "";

            if (startIndex + 1000 <= 0) {
              const endIndex = fileText.length;
              const content = fileText.substring(startIndex, endIndex);
              return content;
            }
            content = fileText.slice(startIndex, startIndex + 1000);
            return content;
          default:
            return context.originalText;
        }
      })();

      const option = (() => {
        switch (context.selectedButton) {
          case "createIntroduction":
            return fileTitle;
          case "createTags":
            return context.selectedSns;
          case "createTone":
            return context.selectedTone;
          case "createSummary":
            return fileTitle;
          case "createContinue":
            return fileTitle;
          case "createTranslate":
            return context.language;
          case "createCustom":
            return context.customMessage;
          default:
            return null;
        }
      })();

      const data = {
        target: target,
        additionalText: context.selectedButton !== "createCustom" ? context.additionalText : null,
        action: context.selectedButton,
        content: content,
        title: fileTitle,
        option: option,
      };

      const response = await fetch("/api/v1/text-file/" + fileUuid + "/create", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("ReadableStream not supported in this browser.");
      }

      if (response.body !== null) {
        const reader = response.body.getReader();
        new ReadableStream({
          start(controller) {
            console.log("Stream start");

            function push() {
              reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                const text = new TextDecoder().decode(value);
                const jsonParts = text.split("\n\n");

                for (const part of jsonParts) {
                  if (part.trim() === "") continue; // 空のデータをスキップ
                  const parsedData = JSON.parse(part);
                  if (parsedData["data"] && parsedData["data"]["uuid"] === fileUuid) {
                    setChatResponse((prevData) => prevData + parsedData["data"]["content"]);
                  }
                  // メッセージの終了
                  if (parsedData["data"] && parsedData["data"]["end"]) {
                    console.log("Stream complete");
                    controller.close();
                    context.setProcessing(false);
                    dispatch(setCreditTrigger(true));
                    return;
                  }
                }
                controller.enqueue(value);
                push();
              });
            }

            push();
          },
        });
      }
    } catch (error) {
      context.setProcessing(false);
      console.error("An unknown error occurred:", error);
    }
  };

  return (
    <Box my={2}>
      {/* Writing Assistant */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", my: 1 }}>
        <Typography variant={"caption"}>{t("textEditor.sidebar.aiWritingAssistantDescription")}</Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      <ColumnCenteredBox
        ref={context.inputRef}
        sx={{
          width: "100%",
          backgroundColor: "background.paper",
          borderRadius: 2,
          p: 2,
          overflow: "hidden",
          mb: 1,
        }}
      >
        {/* ターゲット入力 */}
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", mb: 2 }}>
          <TextField
            size={"small"}
            value={target}
            variant={"standard"}
            inputProps={{ maxLength: 100 }}
            onChange={(e) => setTarget(e.target.value)}
            label={t("textEditor.sidebar.target")}
            sx={{ width: "100%" }}
          />
        </Box>

        {/* アクションボタンリスト */}
        <SidebarButtonList />

        {/* Generate Button */}
        <GenerateButton handleCreate={handleCreate} />
      </ColumnCenteredBox>

      <SidebarAnswerBox chatResponse={chatResponse} />
    </Box>
  );
}
