import React from "react";
import Meta from "../../components/common/Meta";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import LatestVideoList from "./components/video/LatestVideoList";

const MyVideos = () => {
  const { t } = useTranslation();
  return (
    <>
      <Meta
        title={t("juno.videos")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
        route={"Studio JUNO by Tenak AI"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          px: 1,
          pb: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            px: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <LatestVideoList grid={2.4} size={10} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default MyVideos;
