import LoginIcon from "@mui/icons-material/Login";
import { Button, Hidden } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ModalsManager from "../../login/ModalsManager";
import { useDarkMode } from "../../../context/DarkModeContext";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { GradationButton } from "../../../utils/gradationButton";
import { RowCenteredBox } from "../../../utils/styledBox";
import AccountPopup from "./AccountPopup";
import DarkModeToggle from "./DarkModeToggle";
import LanguageSwitcher from "./LanguageSwitcher";
import CenterContents from "./CenterContents";

interface DynamicBackgroundProps {
  isLoggedIn: boolean | null;
  isDarkMode: boolean;
}

interface LogoProps {
  activePath: string;
  darkColor: string;
}

const DynamicBackground: React.FC<DynamicBackgroundProps> = React.memo(({ isLoggedIn, isDarkMode }) => {
  return (
    <div
      style={{
        opacity: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: isDarkMode ? "transparent" : isLoggedIn ? "transparent" : "rgba(255,255,255,1)",
        transition: "background-color 0.3s ease",
      }}
    />
  );
});

const Logo: React.FC<LogoProps> = React.memo(({ activePath, darkColor }) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  if (activePath === "notes-nebula") {
    return (
      <RowCenteredBox onClick={() => navigate("/notes-nebula")} sx={{ cursor: "pointer" }}>
        <img src="/images/logo/NN.png" style={{ width: 35, height: 35, marginRight: 8 }} alt="Notes Nebula" />
        <Hidden mdDown>
          <Typography variant="h5" component="p" sx={{ fontWeight: "bold" }}>
            Notes Nebula
          </Typography>
        </Hidden>
      </RowCenteredBox>
    );
  }
  if (activePath === "juno") {
    return (
      <RowCenteredBox onClick={() => navigate("/juno")} sx={{ cursor: "pointer" }}>
        <img src="/images/logo/juno-logo-mini2.png" style={{ width: 35, height: 35, marginRight: 8 }} alt="Juno" />
        <Hidden mdDown>
          <Typography variant="h5" component="p" sx={{ fontWeight: "bold" }}>
            STUDIO JUNO
          </Typography>
        </Hidden>
      </RowCenteredBox>
    );
  }
  return (
    <RowCenteredBox onClick={() => navigate("")} sx={{ cursor: "pointer" }}>
      <img src="/images/logo/logoZI-small.png" style={{ width: 35, height: 35, marginRight: 8 }} alt="Tenak.ai" />
      <Hidden mdDown>
        <Typography
          variant="h5"
          component="p"
          noWrap
          sx={{
            flexGrow: 1,
            color: darkColor,
            fontFamily: "'Pacifico', cursive",
            fontSize: "1.8rem!important",
            p: 0.5,
          }}
        >
          {t("title2")}
        </Typography>
      </Hidden>
    </RowCenteredBox>
  );
});

const Header: React.FC = React.memo(() => {
  const theme = useTheme();
  const { isLoggedIn } = useLoginStatus();
  const { t } = useTranslation();
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(true);
  const activePath = useCallback(() => {
    const path = location.pathname.split("/")[2];
    switch (path) {
      case "notes-nebula":
        return "notes-nebula";
      case "juno":
        return "juno";
      default:
        return "tenak-ai";
    }
  }, [location.pathname]);

  useEffect(() => {
    // スクロール時のヘッダー表示制御
    if (window.innerWidth <= 768) {
      let lastScrollY = window.scrollY;
      const handleScroll = () => {
        if (
          window.scrollY < lastScrollY ||
          window.scrollY + window.innerHeight >= document.body.scrollHeight ||
          window.scrollY <= 100
        ) {
          setShowHeader(true);
        } else {
          setShowHeader(false);
        }
        lastScrollY = window.scrollY;
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setShowHeader(true);
    }
  }, []);

  const CustomToolbar = React.memo(
    styled(Toolbar)(({ theme }) => ({
      minHeight: "56px!important",
      display: "flex",
      background:
        theme.palette.mode === "dark" ? "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)" : "",
      boxSizing: "content-box",
    }))
  );

  const { darkMode, toggleDarkMode } = useDarkMode();
  const loginModalContext = useLoginModalContext();
  const navigate = useCustomNavigate();
  const handleNavigate = useCallback((path) => () => navigate(path), [navigate]);
  const darkColor = theme.palette.text.primary;

  return (
    <>
      <Slide appear={true} direction="down" in={showHeader} timeout={1000}>
        <MuiAppBar
          position="fixed"
          id={"header"}
          sx={{ backgroundColor: "transparent", width: "100%", zIndex: 1300, boxShadow: "none" }}
          elevation={0}
        >
          <DynamicBackground isLoggedIn={isLoggedIn} isDarkMode={theme.palette.mode === "dark"} />
          <CustomToolbar>
            <Grid container sx={{ alignItems: "center", width: "100%" }}>
              {/* Left */}
              <Grid item xs={2} sm={4} md={3} lg={2}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: theme.palette.text.primary,
                    }}
                  >
                    <Logo activePath={activePath()} darkColor={darkColor} />
                  </Box>
                </Box>
              </Grid>

              {/* Center */}
              <Grid item xs={0} sm={0} md={0} lg={5}>
                <CenterContents />
              </Grid>

              {/* Right */}
              <Grid item xs={10} sm={8} md={9} lg={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  <Box flexGrow={1} />
                  {activePath() !== "notes-nebula" && (
                    <Hidden smDown>
                      <Button
                        onClick={handleNavigate("/pricing")}
                        variant={"text"}
                        sx={{
                          color: location.pathname.split("/")[2] === "pricing" ? "primary.light" : darkColor,
                        }}
                      >
                        <Typography
                          fontWeight={location.pathname.split("/")[2] === "pricing" ? 700 : 400}
                          variant={"button"}
                        >
                          {t("pricing.title")}
                        </Typography>
                      </Button>
                    </Hidden>
                  )}

                  {isLoggedIn ? (
                    <Stack direction="row" spacing={theme.breakpoints.down("sm") ? 0 : 1}>
                      <LanguageSwitcher color={darkColor} />
                      <DarkModeToggle darkMode={darkMode} onDarkModeToggle={toggleDarkMode} color={darkColor} />
                      <AccountPopup />
                    </Stack>
                  ) : (
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                      <LanguageSwitcher color={darkColor} />
                      <DarkModeToggle darkMode={darkMode} onDarkModeToggle={toggleDarkMode} color={darkColor} />
                      <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={() => loginModalContext.setActiveModal("login")}
                        sx={{ display: { xs: "none", md: "flex" } }}
                        startIcon={<LoginIcon />}
                      >
                        {t("top.login")}
                      </Button>
                      <GradationButton
                        disableElevation
                        color="inherit"
                        onClick={() => loginModalContext.setActiveModal("signup")}
                      >
                        {t("top.signupHeader")}
                      </GradationButton>
                    </Stack>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CustomToolbar>
        </MuiAppBar>
      </Slide>
      <ModalsManager />
    </>
  );
});

export default Header;
