import * as React from "react";
import { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getFileDateName } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Cookies from "js-cookie";
import SpeedIcon from "@mui/icons-material/Speed";
import HeightIcon from "@mui/icons-material/Height";
import { useLoginStatus } from "../../context/LoginStatusContext";
import InfoIcon from "@mui/icons-material/Info";
import DescriptionModal from "./components/DescriptionModal";
import { useAlert } from "../../context/AlertContext";
import FolderIcon from "@mui/icons-material/Folder";
import { useLocation } from "react-router-dom";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import { setCreditTrigger } from "../../redux/slices/triggerSlice";
import { useDispatch } from "react-redux";
import { useCheckCredit } from "../../hooks/useCreditCheck";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { useTheme } from "@mui/material/styles";
import { GradationButton } from "../../utils/gradationButton";
import { ColumnCenteredBox, RowCenteredBox } from "../../utils/styledBox";
import Stack from "@mui/material/Stack";
import Meta from "../../components/common/Meta";
import { GradientTypography } from "../../utils/gradientTypography";

interface VoiceData {
  text: string;
  voice_name: string;
  speed: number;
  gender?: string;
  provider: string;
  language_code?: string;
  pitch?: number;
  number?: number;
  type?: string;
}

export default function VoiceGenerator() {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [processing, setProcessing] = React.useState(false);
  const [content, setContent] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const [voiceName, setVoiceName] = React.useState("");
  const [speed, setSpeed] = React.useState(1);
  const [pitch, setPitch] = React.useState(0);
  const [languageList, setLanguageList] = React.useState([] as any[]);
  const [googleVoiceList, setGoogleVoiceList] = React.useState([] as any[]);
  const [openAIVoiceList, setOpenAIVoiceList] = React.useState([] as any[]);
  const { user } = useLoginStatus();
  const [open, setOpen] = React.useState(false);
  const [voiceType, setVoiceType] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [voiceIndexNumber, setVoiceIndexNumber] = React.useState(0);
  const [provider, setProvider] = React.useState("openai" as string);
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useCustomNavigate();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const text = location.state?.textEditor;
    if (text) {
      setContent(text);
    }
  }, []);

  const csrftoken = Cookies.get("csrftoken");
  const headers = new Headers({
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken!,
  });

  const fetchJson = async (url: string, method: string, body: any = {}) => {
    try {
      const options: RequestInit = {
        method,
        headers,
      };
      if (method !== "GET") {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchVoiceNames = () =>
    fetchJson("/api/v1/voice-generator/list-voices", "POST", {
      provider: provider,
      language_code: language,
    });
  const fetchLanguageList = () => fetchJson("/api/v1/voice-generator/list-languages", "GET");

  // Generate Voice
  // Extracted method for generating downloadable link
  const downloadBlobAsMP3 = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `voice-${getFileDateName()}.mp3`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const generateVoice = async () => {
    try {
      if (!(await checkCredit())) return;
      setProcessing(true);

      let voiceData: VoiceData = {
        text: content,
        voice_name: voiceName,
        speed: speed,
        provider: provider,
      };

      if (provider === "google") {
        voiceData = {
          ...voiceData,
          language_code: language,
          gender: gender,
          pitch: pitch,
          number: voiceIndexNumber,
          type: voiceType,
        };
      }

      const json = await fetchJson("/api/v1/voice-generator/generate", "POST", voiceData);

      if (!json.success) {
        throw new Error();
      }
      const generated_voice_uuid = await json.data;

      // Download generated voice
      const downloadResponse = await fetch(`/api/v1/voice-generator/download/${generated_voice_uuid}`, {
        method: "GET",
      });
      if (!downloadResponse.ok) {
        throw new Error();
      }
      const blob = await downloadResponse.blob();
      downloadBlobAsMP3(blob);
      dispatch(setCreditTrigger(true));
      setAlert("success", t("voice.message.success"));
    } catch (error) {
      setAlert("error", t("common.error.somethingWentWrong"));
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  // AI言語設定に合わせて言語を設定
  useEffect(() => {
    fetchLanguageList().then((json: any) => {
      setLanguageList(json.data);
      // languageListが更新された後に言語を設定
      if (user?.ai_language === "ja") {
        setLanguage("ja-JP");
      } else {
        setLanguage("en-US");
      }
    });
  }, []);

  // 言語に合わせてボイスリストを更新
  useEffect(() => {
    async function updateVoiceData() {
      setLoading(true); // ローディングを開始

      if (language) {
        try {
          switch (provider) {
            case "openai":
              const openAIResponse = await fetchVoiceNames(); // openai用のボイスデータを取得
              setOpenAIVoiceList(openAIResponse.data);
              setVoiceName(openAIResponse.data[0].value);
              setVoiceType(openAIResponse.data[0].type);
              setVoiceIndexNumber(openAIResponse.data[0].voice_number);
              setLoading(false); // ローディングを終了
              break;

            case "google":
              const googleResponse = await fetchVoiceNames(); // google用のボイスデータを取得
              setGoogleVoiceList(googleResponse.data);
              setVoiceName(googleResponse.data[0].value);
              setGender(googleResponse.data[0].gender);
              setVoiceType(googleResponse.data[0].type);
              setVoiceIndexNumber(googleResponse.data[0].voice_number);
              setLoading(false); // ローディングを終了
              break;
          }
        } catch (error) {
          setLoading(false); // ローディングを終了
          console.error("Failed to fetch voice data:", error);
        }
      }
    }

    updateVoiceData();
  }, [provider, language]);

  const googleVoiceLabel = (gender: string, type: string, voice_number: string) => {
    return t(`voice.googleVoiceLabel.${type}`) + " - " + t(`voice.gender.${gender}`) + " " + voice_number;
  };

  const openaiVoiceLabel = (name: string, gender: string) => {
    return t(`voice.openaiVoiceLabel.${name}`) + " - " + t(`voice.gender.${gender}`);
  };

  // ボイス名が変更されたら、ラベル用のボイスタイプと性別を更新
  const handleVoiceNameChange = (event: SelectChangeEvent) => {
    setVoiceName(event.target.value as string);
    googleVoiceList.forEach((item) => {
      if (item.value === event.target.value) {
        setVoiceType(item.type);
        setGender(item.gender);
        setVoiceIndexNumber(item.voice_number);
      }
    });
  };

  // プロバイダーが変更されたら、ボイス名をリセット
  const handleProviderChange = (event: SelectChangeEvent) => {
    setProvider(event.target.value as string);
    setVoiceName("");
  };
  const theme = useTheme();

  return (
    <>
      <Meta title={t("voice.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Container maxWidth={"lg"}>
        <Grow in={true}>
          <Box sx={{ height: "100%", mb: 2 }}>
            <DrawerHeader />
            <Paper elevation={theme.palette.mode === "dark" ? 1 : 0} sx={{ my: 4 }}>
              <Box
                sx={{
                  background: (theme) => theme.custom.gradient.main,
                  borderRadius: "4px 4px 0 0",
                  p: { xs: 1 },
                }}
              />

              <ColumnCenteredBox
                sx={{
                  position: "relative",
                  p: { xs: 2, md: 4 },
                  width: "100%",
                }}
              >
                <RowCenteredBox sx={{ justifyContent: "center", mb: 2 }}>
                  <Box display={{ xs: "none", md: "block" }} mr={2}>
                    <img alt={"logo"} src="/images/logo/logo.png" width={"40px"} />
                  </Box>
                  <GradientTypography variant="h5" component={"h2"} gutterBottom>
                    {t("voice.title")}
                  </GradientTypography>
                </RowCenteredBox>
                <RowCenteredBox sx={{ mb: 4 }}>
                  <FlashOnIcon sx={{ mr: 1 }} color={"secondary"} />
                  <Typography variant={"body1"} gutterBottom>
                    {t("voice.description")}
                  </Typography>
                </RowCenteredBox>

                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* Provider */}
                    <Box mb={4}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">{t("voice.provider.title")}</FormLabel>
                        <RadioGroup row onChange={handleProviderChange} value={provider}>
                          <FormControlLabel value="openai" control={<Radio />} label={t("voice.provider.openai")} />
                          <FormControlLabel value="google" control={<Radio />} label={t("voice.provider.google")} />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    {/* Params */}
                    {/* Language */}
                    {loading ? (
                      <ColumnCenteredBox sx={{ height: "100%" }}>
                        <CircularProgress size={24} />
                      </ColumnCenteredBox>
                    ) : (
                      <Fade in={true} timeout={500}>
                        <Stack spacing={2}>
                          {provider === "google" && (
                            <FormControl variant="standard" sx={{ width: "100%" }}>
                              <InputLabel>Language</InputLabel>
                              <Select value={language} onChange={(e) => setLanguage(e.target.value)} label="Language">
                                {languageList.map((item, index) => (
                                  <MenuItem key={index} value={item.code}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}

                          {/*  Voice Name  */}
                          <Box
                            flexDirection={"row"}
                            display={"flex"}
                            alignItems="baseline"
                            justifyContent="space-between"
                          >
                            <FormControl variant="standard" sx={{ width: "100%" }}>
                              <InputLabel shrink>Voice</InputLabel>
                              <Select
                                value={voiceName}
                                onChange={handleVoiceNameChange}
                                label="Voice"
                                disabled={provider === "google" && googleVoiceList.length === 0}
                              >
                                {provider === "openai" &&
                                  openAIVoiceList.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {openaiVoiceLabel(item.value, item.gender)}
                                    </MenuItem>
                                  ))}
                                {provider === "google" &&
                                  googleVoiceList.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {googleVoiceLabel(item.gender, item.type, item.voice_number)}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            <Tooltip title={t("voice.typeDescription")}>
                              <IconButton onClick={() => setOpen(true)}>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          {/*  Speed Slider  */}
                          <Box>
                            <Box display={"flex"} flexDirection={"row"} alignItems="center">
                              <Typography variant={"body2"} gutterBottom>
                                {t("voice.speed")}
                              </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"row"} alignItems="center">
                              <SpeedIcon />
                              <Slider
                                sx={{ mx: 2 }}
                                value={speed || 0}
                                onChange={(e, newValue) => setSpeed(newValue as number)}
                                aria-labelledby="input-slider"
                                defaultValue={1}
                                step={0.05}
                                min={0.25}
                                max={4}
                              />
                              <TextField
                                size={"small"}
                                variant={"standard"}
                                value={speed}
                                sx={{ width: 50 }}
                                inputProps={{ style: { textAlign: "center" } }}
                              >
                                {speed}
                              </TextField>
                            </Box>
                          </Box>
                          {provider === "google" && (
                            <Box>
                              {/*  Pitch Slider  */}
                              <Box display={"flex"} flexDirection={"row"} alignItems="center">
                                <Typography variant={"body2"} gutterBottom>
                                  {t("voice.pitch")}
                                </Typography>
                              </Box>
                              <Box display={"flex"} flexDirection={"row"} alignItems="center">
                                <HeightIcon />
                                <Slider
                                  sx={{ mx: 2 }}
                                  value={pitch || 0}
                                  onChange={(_, newValue) => setPitch(newValue as number)}
                                  defaultValue={0}
                                  min={-20}
                                  max={20}
                                />
                                <TextField
                                  size={"small"}
                                  variant={"standard"}
                                  value={pitch}
                                  sx={{ width: 50 }}
                                  inputProps={{ style: { textAlign: "center" } }}
                                >
                                  {speed}
                                </TextField>
                              </Box>
                            </Box>
                          )}
                        </Stack>
                      </Fade>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {/* テキストフィールド */}
                    <Box>
                      <TextField
                        label={t("voice.content")}
                        fullWidth
                        multiline
                        rows={Math.round((window.innerHeight - 420) / 25) ?? 18}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        autoFocus
                        inputProps={{ maxLength: 4000 }}
                        variant={"filled"}
                      />
                      <Typography
                        variant={"caption"}
                        sx={{
                          mt: 1,
                          justifyContent: "flex-end",
                          display: "flex",
                          mb: -2,
                        }}
                      >
                        {content.length}/ 4000
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    {/* Submit */}
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Button disableElevation variant={"text"} onClick={() => navigate("/library/voices")}>
                        <FolderIcon sx={{ mr: 1 }} />
                        <Typography variant={"button"}>{t("voice.library")}</Typography>
                      </Button>
                      <GradationButton
                        variant="contained"
                        onClick={generateVoice}
                        disabled={processing || content.trim().length === 0}
                        sx={{ width: 300 }}
                      >
                        {!processing && (
                          <>
                            <AutoAwesomeIcon sx={{ mr: 1 }} fontSize={"small"} />
                            {t("voice.submit")}
                          </>
                        )}
                        {processing && (
                          <>
                            <Typography variant={"button"}>{"Generating..."}</Typography>
                            <CircularProgress size={20} sx={{ ml: 1 }} />
                          </>
                        )}
                      </GradationButton>
                    </Box>
                  </Grid>
                </Grid>
              </ColumnCenteredBox>
            </Paper>
            <DescriptionModal open={open} setOpen={setOpen} provider={provider} />
          </Box>
        </Grow>
      </Container>
    </>
  );
}
