import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import { AspectRatio } from "../../../../types/junoTypes";
import Typography from "@mui/material/Typography";
import React from "react";
import { AspectRatioIconType, useVideo } from "../../../../context/juno/VideoContext";
import { RowBox } from "../../../../utils/styledBox";

const AspectRatioIcons = ({ aspectRatioList }: { aspectRatioList: AspectRatioIconType[] }) => {
  const { aiEngine, updateParams, aspectRatios } = useVideo();

  return (
    <RowBox gap={1}>
      {aspectRatioList.map(({ ratio, icon: Icon, rotate }) => (
        <Box key={ratio} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => updateParams(aiEngine, "aspectRatio", ratio as AspectRatio)}
            color={aspectRatios[aiEngine] === ratio ? "primary" : "default"}
          >
            <Icon sx={{ rotate: rotate || "0deg" }} />
          </IconButton>
          <Typography
            variant={"caption"}
            onClick={() => updateParams(aiEngine, "aspectRatio", ratio as AspectRatio)}
            sx={{ cursor: "pointer" }}
          >
            {ratio}
          </Typography>
        </Box>
      ))}
    </RowBox>
  );
};

export default AspectRatioIcons;
