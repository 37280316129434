import AddBoxIcon from "@mui/icons-material/AddBox";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { ColumnBox } from "../../../utils/styledBox";
import ScrollableBox from "../../../components/common/ScrollableBox";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useAlert } from "../../../context/AlertContext";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useBrowsingMethods } from "../../../hooks/useBrowsingMethods";

type WorkspaceType = {
  name: string;
  path: string;
  last_history: string;
};

const BrowsingListSidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();
  const [workspaces, setWorkspaces] = useState<WorkspaceType[]>([]);
  const updatedWorkspace = useSelector((state: RootState) => state.browsing.triggerFetch);
  const { addNewWorkspace } = useBrowsingMethods();

  const newWorkspace = async () => {
    try {
      const res = await addNewWorkspace();
      if (!res) return;
      workspaces.push(res.data);
      setOpenBrowsing(true);
      get_workspaces().then(() => {
        setAlert("success", t("browsing.createWorkspace"));
        setWorkspaces(workspaces);
        navigate(res.data.path);
      });
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  useEffect(() => {
    get_workspaces();
  }, [updatedWorkspace]);

  const get_workspaces = async () => {
    try {
      const res = await axios.get("/api/v1/workspace/");
      setWorkspaces(res.data.data);
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };
  const theme = useTheme();
  const isSmOrLarger = useMediaQuery(theme.breakpoints.up("sm"));

  const [openBrowsing, setOpenBrowsing] = React.useState(false);

  return (
    <ScrollableBox
      component={Paper}
      onMouseOver={!isSmOrLarger ? () => setOpenBrowsing(true) : undefined}
      onMouseLeave={!isSmOrLarger ? () => setOpenBrowsing(false) : undefined}
      onClick={!isSmOrLarger ? () => setOpenBrowsing(!openBrowsing) : undefined}
      elevation={theme.palette.mode === "dark" ? 2 : 0}
      sx={{
        borderRadius: 1,
        width: { xs: "100%" },
        maxHeight: { xs: "100vh" },
      }}
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <ScheduleIcon fontSize={"small"} color={"primary"} />
          </ListItemIcon>
          <ListItemText sx={{ mr: 2 }}>
            <Typography variant={"body2"} component={"span"}>
              {t("browsing.history")}
            </Typography>
          </ListItemText>
          {!isSmOrLarger && (openBrowsing ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {isSmOrLarger ? (
          <List component="div">
            {workspaces.map((workspace) => (
              <ListItemButton
                key={workspace.path}
                onClick={() => navigate(workspace.path)}
                selected={location.pathname.split("/")[3] === workspace.path.split("/")[2]}
                sx={{
                  "&:hover": {
                    backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                  },
                }}
              >
                <ListItemIcon>
                  <ChatBubbleOutlineIcon fontSize={"small"} color={"secondary"} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maskImage: "linear-gradient(to left, transparent 1%, black 30%)",
                  }}
                >
                  <ColumnBox>
                    <Typography
                      variant={"body2"}
                      component={"span"}
                      fontWeight={location.pathname.split("/")[3] === workspace.path.split("/")[2] ? 600 : 400}
                    >
                      {workspace.name}
                    </Typography>
                    <Typography
                      variant={"caption"}
                      component={"span"}
                      fontWeight={location.pathname.split("/")[3] === workspace.path.split("/")[2] ? 600 : 400}
                    >
                      {workspace.last_history ? workspace.last_history : t("browsing.noHistory")}
                    </Typography>
                  </ColumnBox>
                </ListItemText>
              </ListItemButton>
            ))}

            <ListItemButton
              onClick={() => newWorkspace()}
              sx={{
                "&:hover": {
                  backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                },
              }}
              disabled={workspaces.length >= 5}
            >
              <ListItemIcon>
                <AddBoxIcon fontSize={"small"} color={"primary"} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant={"body2"} component={"span"}>
                  {t("browsing.addWorkspace")}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        ) : (
          <Collapse in={openBrowsing} timeout="auto" unmountOnExit>
            <List component="div">
              {workspaces.map((workspace) => (
                <ListItemButton
                  key={workspace.path}
                  onClick={() => navigate(workspace.path)}
                  selected={location.pathname.split("/")[3] === workspace.path.split("/")[2]}
                  sx={{
                    "&:hover": {
                      backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                    },
                  }}
                >
                  <ListItemIcon>
                    <ChatBubbleOutlineIcon fontSize={"small"} color={"secondary"} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maskImage: "linear-gradient(to left, transparent 1%, black 30%)",
                    }}
                  >
                    <ColumnBox>
                      <Typography
                        variant={"body2"}
                        component={"span"}
                        fontWeight={location.pathname.split("/")[3] === workspace.path.split("/")[2] ? 600 : 400}
                      >
                        {workspace.name}
                      </Typography>
                      <Typography
                        variant={"caption"}
                        component={"span"}
                        fontWeight={location.pathname.split("/")[3] === workspace.path.split("/")[2] ? 600 : 400}
                      >
                        {workspace.last_history ? workspace.last_history : t("browsing.noHistory")}
                      </Typography>
                    </ColumnBox>
                  </ListItemText>
                </ListItemButton>
              ))}
              <ListItemButton
                onClick={() => newWorkspace()}
                sx={{
                  "&:hover": {
                    backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                  },
                }}
                disabled={workspaces.length >= 5}
              >
                <ListItemIcon>
                  <AddBoxIcon fontSize={"small"} color={"primary"} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant={"body2"} component={"span"}>
                    {t("browsing.addWorkspace")}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </List>
          </Collapse>
        )}
      </List>
      <Box sx={{ flexGrow: 1 }} />
    </ScrollableBox>
  );
};
export default BrowsingListSidebar;
