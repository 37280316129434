import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import ShareIcon from "@mui/icons-material/Share";
import SubjectIcon from "@mui/icons-material/Subject";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControl,
  Grow,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Meta from "../../components/common/Meta";
import { useAlert } from "../../context/AlertContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { GradationButton } from "../../utils/gradationButton";
import { localDate } from "../../utils/utils";

type TextFileType = {
  uuid: string;
  title: string;
  public_status: number;
  public_uuid: string;
  created_at: string;
  updated_at: string;
};

type TextFileResponseType = {
  count: number;
  next: string | null;
  previous: string | null;
  results: TextFileType[];
};

export default function Text() {
  const { t } = useTranslation();
  const csrftoken = Cookies.get("csrftoken");
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const queryParam = searchParams.get("query") || "";
  const sortParam = searchParams.get("sort") || "-updated_at";
  const publicStatusParam = searchParams.getAll("public_status[]");
  const [page, setPage] = useState<number>(parseInt(pageParam || "1", 10));
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [multiDeleteDialogOpen, setMultiDeleteDialogOpen] = useState(false); // 追加
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);
  const [multiSelect, setMultiSelect] = useState<string[]>([]);
  const [query, setQuery] = useState(queryParam);
  const [sort, setSort] = useState(sortParam);
  const [publicStatus, setPublicStatus] = useState<string[]>(publicStatusParam);
  const { setAlert } = useAlert();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const openCreate = Boolean(anchorEl);
  const [menuWidth, setMenuWidth] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const itemBoxRef = useRef<HTMLDivElement>(null);
  const [textFileLists, setTextFileLists] = useState<TextFileResponseType>({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
  const uiLanguage = Cookies.get("ui_language") || "en";
  const navigate = useCustomNavigate();

  const handleCreateClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleCreateClose = (type: number) => {
    handleNewFile();
    setAnchorEl(null);
  };

  // ディレイを入れるためのカスタムフック
  function useDebounce(value: string, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedQuery = useDebounce(query, 500);

  const getSortIcon = (field: string) => {
    if (sort === field) {
      return <ArrowDownwardIcon fontSize="small" />;
    } else if (sort === `-${field}`) {
      return <ArrowUpwardIcon fontSize="small" />;
    }
    return <SwapVertIcon fontSize="small" />;
  };

  const getTextFileLists = async () => {
    try {
      const res = await axios.get("/api/v1/text-file/", {
        params: {
          page,
          items_per_page: itemsPerPage,
          query: debouncedQuery,
          sort,
          public_status: publicStatus,
        },
      });
      setTextFileLists(res.data);
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  useEffect(() => {
    if (page && itemsPerPage) {
      getTextFileLists();
    }
  }, [page, itemsPerPage, debouncedQuery, sort, publicStatus]);

  useEffect(() => {
    if (buttonRef.current) {
      setMenuWidth(buttonRef.current.offsetWidth);
    }
  }, [buttonRef.current]);

  useEffect(() => {
    const TableHeight = itemBoxRef.current!.offsetHeight - 75;
    const items = Math.floor(TableHeight / 75);
    setItemsPerPage(items);
  }, [itemBoxRef]);

  // 依存関係の更新時にページをリセットするためのuseEffect
  useEffect(() => {
    setPage(1);
    setSearchParams({
      page: "1",
      query,
      sort,
      "public_status[]": publicStatus,
    });
  }, [query, sort, publicStatus]);

  const handleDialogOpen = (uuid: string) => {
    setSelectedUuid(uuid);
    setDialogOpen(true);
  };

  const publicStatusLabel = (type: number) => {
    switch (type) {
      case 1:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#5d9f68",
              px: 1.6,
              py: 0.8,
              whiteSpace: "nowrap",
              borderRadius: 1,
              color: "white",
            }}
          >
            <PublicIcon sx={{ mr: 1 }} fontSize={"small"} />
            {t("library.text.publicStatus.public")}
          </Box>
        );
      case 2:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#7e7e7e",
              px: 1.6,
              py: 0.8,
              whiteSpace: "nowrap",
              borderRadius: 1,
              color: "white",
            }}
          >
            <PublicOffIcon sx={{ mr: 1 }} fontSize={"small"} />
            {t("library.text.publicStatus.private")}
          </Box>
        );
      case 3:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#61abaf",
              px: 1.6,
              py: 0.8,
              whiteSpace: "nowrap",
              borderRadius: 1,
              color: "white",
            }}
          >
            <ShareIcon sx={{ mr: 1 }} fontSize={"small"} />
            {t("library.text.publicStatus.shared")}
          </Box>
        );
    }
  };

  const [disabledDeleteButton, setDisabledDeleteButton] = useState<{ [key: string]: boolean }>({});

  const handleConfirmedDelete = async (uuid: string) => {
    try {
      setDisabledDeleteButton({ ...disabledDeleteButton, [uuid]: true });
      const response = await fetch("/api/v1/text-file/" + uuid, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken!,
        },
      });

      if (!response.ok) {
        setAlert("error", t("library.text.delete.error"));
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await response.json();
      setDisabledDeleteButton({ ...disabledDeleteButton, [uuid]: false });
      setAlert("success", t("library.text.delete.success"));
      getTextFileLists();
    } catch (error) {
      console.error("Error during the fetch operation:", error);
      setAlert("error", t("library.text.delete.error"));
      setDisabledDeleteButton({ ...disabledDeleteButton, [uuid]: false });
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setSearchParams({
      page: value.toString(),
      query,
      sort,
      "public_status[]": publicStatus,
    });
    setPage(value);
  };

  const handleNewFile = async () => {
    try {
      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      const res = await axios.post("/api/v1/text-file/", {}, config);
      navigate("/file/" + res.data.uuid);
      setAlert("success", t("library.text.message.create"));
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const handleSort = (sortField: string) => {
    const newSort = sort === sortField ? `-${sortField}` : sortField;
    setSearchParams({
      page: "1",
      query,
      sort: newSort,
      "public_status[]": publicStatus,
    });
    setSort(newSort);
    setPage(1);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const theme = useTheme();

  // マルチセレクト
  const handleMultiSelect = (uuid: string) => {
    if (multiSelect.includes(uuid)) {
      setMultiSelect(multiSelect.filter((id) => id !== uuid));
    } else {
      setMultiSelect([...multiSelect, uuid]);
    }
  };

  // 一括選択
  const handleAllSelect = () => {
    if (multiSelect.length === textFileLists.results.length) {
      setMultiSelect([]);
    } else {
      setMultiSelect(textFileLists.results.map((textFile) => textFile.uuid));
    }
  };

  // 一括削除の確認モーダルを表示
  const handleMultiDeleteOpen = () => {
    setMultiDeleteDialogOpen(true);
  };

  // 一括削除の確認モーダルを閉じる
  const handleMultiDeleteClose = () => {
    setMultiDeleteDialogOpen(false);
  };

  // 一括削除の確認後の処理
  const handleConfirmedMultiDelete = async () => {
    try {
      const response = await fetch("/api/v1/text-file/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken!,
        },
        body: JSON.stringify({ textfile_uuids: multiSelect }),
      });

      if (!response.ok) {
        setAlert("error", t("library.text.delete.error"));
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await response.json();

      setAlert("success", t("library.text.delete.success"));
      getTextFileLists();
      setMultiSelect([]); // 一括削除後に選択をクリア
      handleMultiDeleteClose(); // モーダルを閉じる
    } catch (error) {
      console.error("Error during the fetch operation:", error);
      setAlert("error", t("library.text.delete.error"));
    }
  };

  const locationPath = (publicStatus: string, uuid: string, publicUuid?: string) => {
    if (publicStatus === "3" && publicUuid) {
      return `/notes-nebula/articles/${uuid}/${publicUuid}`;
    }
    return `/notes-nebula/articles/${uuid}`;
  };

  return (
    <>
      <Meta
        title={t("library.text.title") + " " + t("drawer.library")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
      />
      <DrawerHeader />
      <Grow in={true}>
        <Box
          sx={{
            height: "100%",
            backgroundColor: "background.paper",
            mx: { xs: 0, sm: 2 },
            borderRadius: { xs: 0, sm: 2 },
          }}
        >
          <Box sx={{ mx: { xs: 1, md: 2 }, display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                justifyContent: "space-between",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                mt: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SubjectIcon sx={{ mr: 1 }} color={"secondary"} />
                <Typography variant="h6" component={"h2"} sx={{ position: "relative" }}>
                  {t("library.text.title")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" } }} gap={1}>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={handleCreateClick}
                  ref={buttonRef}
                >
                  <AddCircleIcon sx={{ mr: 1 }} />
                  <Typography variant="button">{t("library.text.create")}</Typography>
                </Button>
                <Menu anchorEl={anchorEl} open={openCreate} onClose={handleCreateClose}>
                  <MenuItem
                    style={{ minWidth: menuWidth }}
                    sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    onClick={() => handleCreateClose(1)}
                  >
                    <SubjectIcon sx={{ mr: 1 }} fontSize={"small"} />
                    {t("library.text.type.text")}
                  </MenuItem>
                </Menu>

                <GradationButton onClick={() => navigate("/writing-ai/article")} variant="contained">
                  <AutoAwesomeIcon sx={{ mr: 1 }} fontSize={"small"} />
                  Writing AI
                </GradationButton>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2, my: 1 }}>
              <Box sx={{ width: { xs: "100%", sm: 240 } }}>
                <TextField
                  label={"Search"}
                  variant="outlined"
                  size="small"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </Box>
              <Box sx={{ width: { xs: "100%", sm: 240 } }}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel>{t("library.text.publicStatus.title")}</InputLabel>
                  <Select
                    multiple
                    value={publicStatus}
                    onChange={(e) => setPublicStatus(e.target.value as string[])}
                    input={<OutlinedInput label={t("library.text.publicStatus.title")} />}
                    renderValue={(selected) =>
                      selected
                        .map((value) => {
                          switch (value) {
                            case "1":
                              return t("library.text.publicStatus.public");
                            case "2":
                              return t("library.text.publicStatus.private");
                            case "3":
                              return t("library.text.publicStatus.shared");
                            default:
                              return value;
                          }
                        })
                        .join(", ")
                    }
                  >
                    <MenuItem value="1">
                      <Checkbox checked={publicStatus.indexOf("1") > -1} />
                      <ListItemText primary={t("library.text.publicStatus.public")} />
                    </MenuItem>
                    <MenuItem value="2">
                      <Checkbox checked={publicStatus.indexOf("2") > -1} />
                      <ListItemText primary={t("library.text.publicStatus.private")} />
                    </MenuItem>
                    <MenuItem value="3">
                      <Checkbox checked={publicStatus.indexOf("3") > -1} />
                      <ListItemText primary={t("library.text.publicStatus.shared")} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {multiSelect.length > 0 && (
                <>
                  <Box flexGrow={1} />
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Fade in={true}>
                      <Button onClick={handleMultiDeleteOpen} variant="contained" color="error" disableElevation>
                        <DeleteIcon sx={{ mr: 1 }} fontSize={"small"} />
                        {t("common.deleteSelected", { length: multiSelect.length })}
                      </Button>
                    </Fade>
                  </Box>
                </>
              )}
            </Box>
            <TableContainer ref={itemBoxRef} sx={{ height: { xs: `calc(100vh - 229px)` } }}>
              <Table sx={{ minWidth: 650, maxWidth: "100vw" }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "background.paper",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Checkbox
                        checked={multiSelect.length === textFileLists.results.length}
                        onChange={handleAllSelect}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "background.paper",
                        whiteSpace: "nowrap",
                      }}
                    >
                      No
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "background.paper",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      align="left"
                      onClick={() => handleSort("title")}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {t("library.text.fileTitle")}
                        {getSortIcon("title")}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "background.paper",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      align="left"
                      onClick={() => handleSort("public_status")}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {t("library.text.publicStatus.title")} {getSortIcon("public_status")}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "background.paper",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      align="left"
                      onClick={() => handleSort("created_at")}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {t("library.text.createdAt")} {getSortIcon("created_at")}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "background.paper",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      align="left"
                      onClick={() => handleSort("updated_at")}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {t("library.text.updatedAt")} {getSortIcon("updated_at")}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper", whiteSpace: "nowrap" }} align="left">
                      {t("library.text.action")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {textFileLists.results.map((textFile: TextFileType, index) => (
                    <Fade in={true} key={textFile.uuid} timeout={index * 150}>
                      <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <StyledTableCell align="left">
                          <Checkbox
                            checked={multiSelect.includes(textFile.uuid)}
                            onChange={() => handleMultiSelect(textFile.uuid)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">{(page - 1) * itemsPerPage + index + 1}</StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{
                            cursor: "pointer",
                            color: theme.palette.primary.main,
                            "&:hover": { color: theme.palette.primary.light },
                          }}
                          onClick={() =>
                            navigate(
                              locationPath(textFile.public_status.toString(), textFile.uuid, textFile.public_uuid)
                            )
                          }
                        >
                          <Box
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              width: { xs: 300, sm: 600, md: 900 },
                              display: "block",
                            }}
                          >
                            {textFile.title}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: 120 }} align="left">
                          {publicStatusLabel(textFile.public_status)}
                        </StyledTableCell>
                        <StyledTableCell align="left">{localDate(textFile.created_at, uiLanguage)}</StyledTableCell>
                        <StyledTableCell align="left">{localDate(textFile.updated_at, uiLanguage)}</StyledTableCell>
                        <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          <Button
                            disableElevation
                            startIcon={<EditIcon fontSize={"small"} />}
                            onClick={() => navigate("/file/" + textFile.uuid)}
                            size="small"
                            variant="outlined"
                            sx={{ mr: 1 }}
                          >
                            {t("common.edit")}
                          </Button>
                          <Button
                            disabled={disabledDeleteButton[textFile.uuid]}
                            disableElevation
                            startIcon={
                              disabledDeleteButton[textFile.uuid] ? (
                                <CircularProgress size={20} />
                              ) : (
                                <DeleteIcon fontSize={"small"} />
                              )
                            }
                            onClick={() => handleDialogOpen(textFile.uuid)}
                            size="small"
                            variant="outlined"
                            color="error"
                          >
                            {t("common.delete")}
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    </Fade>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box flexGrow={1} />
            <Pagination
              sx={{ my: 1, display: "flex", justifyContent: "center" }}
              count={textFileLists.count && itemsPerPage ? Math.ceil(textFileLists.count / itemsPerPage) : 0}
              page={page}
              onChange={handleChangePage}
            />
          </Box>

          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>{t("library.text.delete.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("library.text.delete.description")}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disableElevation onClick={() => setDialogOpen(false)} color="primary">
                {t("common.cancel")}
              </Button>
              <Button
                color="error"
                onClick={() => {
                  handleConfirmedDelete(selectedUuid!);
                  setDialogOpen(false);
                }}
              >
                {t("common.delete")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={multiDeleteDialogOpen} onClose={handleMultiDeleteClose}>
            <DialogTitle>{t("library.text.delete.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("library.text.delete.description", { count: multiSelect.length })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disableElevation onClick={handleMultiDeleteClose} color="primary">
                {t("common.cancel")}
              </Button>
              <Button color="error" onClick={handleConfirmedMultiDelete}>
                {t("common.delete")}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Grow>
    </>
  );
}
