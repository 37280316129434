// UploadImageContext.tsx
import React, { createContext, useContext, useState } from "react";

type UploadImageContextType = {
  image: string | null;
  setImage: React.Dispatch<React.SetStateAction<string | null>>;
  imagePrompt: string | null;
  setImagePrompt: React.Dispatch<React.SetStateAction<string | null>>;
  imagePromptStrength: number;
  setImagePromptStrength: React.Dispatch<React.SetStateAction<number>>;
  imageStrength: number;
  setImageStrength: React.Dispatch<React.SetStateAction<number>>;
  originalAspectRatio: number | null;
  setOriginalAspectRatio: React.Dispatch<React.SetStateAction<number | null>>;
};

const UploadImageContext = createContext<UploadImageContextType | undefined>(undefined);

export const UploadImageProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [image, setImage] = useState<string | null>(null);
  const [imagePrompt, setImagePrompt] = useState<string | null>(null);
  const [imagePromptStrength, setImagePromptStrength] = useState<number>(0.35);
  const [imageStrength, setImageStrength] = useState<number>(0.35);
  const [originalAspectRatio, setOriginalAspectRatio] = useState<number | null>(null);

  return (
    <UploadImageContext.Provider
      value={{
        image,
        setImage,
        imagePrompt,
        setImagePrompt,
        imagePromptStrength,
        setImagePromptStrength,
        imageStrength,
        setImageStrength,
        originalAspectRatio,
        setOriginalAspectRatio,
      }}
    >
      {children}
    </UploadImageContext.Provider>
  );
};

export const useUploadImageContext = (): UploadImageContextType => {
  const context = useContext(UploadImageContext);
  if (!context) {
    throw new Error("useUploadImageContext must be used within an UploadImageProvider");
  }
  return context;
};
