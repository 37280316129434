// UpscaleContext.tsx
import React, { createContext, useContext, useState } from "react";
import { AiEngineNumber } from "../../types/junoTypes";

type UpscaleContextType = {
  upscaleFactor: AiEngineNumber;
  setUpscaleFactor: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  upscaleDetail: AiEngineNumber;
  setUpscaleDetail: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  upscaleCreativity: AiEngineNumber;
  setUpscaleCreativity: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  upscaleResemblance: AiEngineNumber;
  setUpscaleResemblance: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  uuidParent: string;
  setUuidParent: React.Dispatch<React.SetStateAction<string>>;
};

const UpscaleContext = createContext<UpscaleContextType | undefined>(undefined);

export const UpscaleProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [upscaleFactor, setUpscaleFactor] = useState<AiEngineNumber>({
    AuraSR: 4,
    ClarityUpscaler: 2,
    CreativeUpscaler: 1,
  });
  const [upscaleDetail, setUpscaleDetail] = useState<AiEngineNumber>({
    CreativeUpscaler: 1,
  });
  const [upscaleCreativity, setUpscaleCreativity] = useState<AiEngineNumber>({
    AuraSR: 1,
    ClarityUpscaler: 0.35,
    CreativeUpscaler: 0.5,
  });
  const [upscaleResemblance, setUpscaleResemblance] = useState<AiEngineNumber>({
    ClarityUpscaler: 0.6,
    CreativeUpscaler: 0.25,
  });
  const [uuidParent, setUuidParent] = useState<string>("");

  return (
    <UpscaleContext.Provider
      value={{
        upscaleFactor,
        setUpscaleFactor,
        upscaleDetail,
        setUpscaleDetail,
        upscaleCreativity,
        setUpscaleCreativity,
        upscaleResemblance,
        setUpscaleResemblance,
        uuidParent,
        setUuidParent,
      }}
    >
      {children}
    </UpscaleContext.Provider>
  );
};

export const useUpscaleContext = (): UpscaleContextType => {
  const context = useContext(UpscaleContext);
  if (!context) {
    throw new Error("useUpscaleContext must be used within an UpscaleProvider");
  }
  return context;
};
