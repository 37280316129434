// CommonContext.tsx
import React, { createContext, useContext, useState } from "react";

type CommonContextType = {
  processing: boolean;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTrigger: number;
  setRefreshTrigger: React.Dispatch<React.SetStateAction<number>>;
  filtered: boolean;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
};

const CommonContext = createContext<CommonContextType | undefined>(undefined);

export const CommonProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
  const [filtered, setFiltered] = useState<boolean>(true);

  return (
    <CommonContext.Provider
      value={{
        processing,
        setProcessing,
        refreshTrigger,
        setRefreshTrigger,
        filtered,
        setFiltered,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export const useCommonContext = (): CommonContextType => {
  const context = useContext(CommonContext);
  if (!context) {
    throw new Error("useCommonContext must be used within a CommonProvider");
  }
  return context;
};
