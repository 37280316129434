import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { styleTranslate } from "../../../../utils/styleTranslate";
import CopyButtonOrIcon from "../../../../components/common/CopyButtonOrIcon";
import React from "react";
import { useTranslation } from "react-i18next";

interface HistoryParamsProps {
  params: any;
  grid: number;
}

const HistoryParams = ({ params, grid }: HistoryParamsProps) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} sx={{ mb: 4 }}>
      {/* サイズ */}
      {params?.["width"] && params?.["height"] && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={`W: ${params["width"]}  x  H: ${params["height"]}`}
            variant={"standard"}
            label={t("juno.history.size")}
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: true }}
            size={"small"}
            fullWidth
          />
        </Grid>
      )}

      {/* アスペクト比 */}
      {params?.["aspect_ratio"] && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={params["aspect_ratio"]}
            variant={"standard"}
            label={t("juno.input.aspectRatio.title")}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            size={"small"}
            fullWidth
          />
        </Grid>
      )}

      {/* Upscale */}
      {params?.["action"] === "upscale" && (
        <>
          {/* スケールサイズ*/}
          <Grid item xs={12} sm={grid}>
            <TextField
              value={params["factor"]}
              variant={"standard"}
              label={t("juno.input.scale")}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start" color="secondary">
                    x
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              size={"small"}
              fullWidth
            />
          </Grid>
          {params?.["creativity"] && (
            <Grid item xs={12} sm={grid}>
              <TextField
                value={params["creativity"]}
                variant={"standard"}
                label={t("juno.input.creativity")}
                InputProps={{ readOnly: true }}
                size={"small"}
                fullWidth
              />
            </Grid>
          )}
          {params?.["detail"] && (
            <Grid item xs={12} sm={grid}>
              <TextField
                value={params["detail"]}
                variant={"standard"}
                label={t("juno.input.detail")}
                InputProps={{ readOnly: true }}
                size={"small"}
                fullWidth
              />
            </Grid>
          )}
          {params?.["resemblance"] && (
            <Grid item xs={12} sm={grid}>
              <TextField
                value={params["resemblance"]}
                variant={"standard"}
                label={t("juno.input.resemblance")}
                InputProps={{ readOnly: true }}
                size={"small"}
                fullWidth
              />
            </Grid>
          )}
        </>
      )}

      {/* スタイル */}
      {params?.["style"] && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={t(styleTranslate(params["style"]))}
            variant={"standard"}
            label={t("juno.input.style.title")}
            InputProps={{ readOnly: true }}
            size={"small"}
            fullWidth
          />
        </Grid>
      )}

      {/* raw */}
      {params?.["raw"] !== null && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={params?.["raw"] ? "ON" : "OFF"}
            variant={"standard"}
            label={t("juno.input.rawMode")}
            fullWidth
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      )}

      {/* スタイル重さ */}
      {params?.["style_weight"] !== undefined && params?.["style_weight"] !== null && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={params["style_weight"]}
            variant={"standard"}
            label={t("juno.input.style.weight")}
            InputProps={{ readOnly: true }}
            size={"small"}
            fullWidth
          />
        </Grid>
      )}

      {/*  img2img  */}
      {params?.["image_strength"] && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={params["image_strength"]}
            variant={"standard"}
            label={t("juno.input.imageStrength")}
            InputProps={{ readOnly: true }}
            size={"small"}
            fullWidth
          />
        </Grid>
      )}

      {/* 画像プロンプトの影響度 */}
      {params?.["image_prompt_strength"] && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={params["image_prompt_strength"]}
            variant={"standard"}
            label={t("juno.history.ip.strength")}
            InputProps={{ readOnly: true }}
            size={"small"}
            fullWidth
          />
        </Grid>
      )}

      {/* Lora影響度 */}
      {params?.["lora"] && params?.["lora"]["name"] && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={params["lora_strength"]}
            variant={"standard"}
            label={t("juno.input.loraStrength")}
            InputProps={{ readOnly: true }}
            size={"small"}
            fullWidth
          />
        </Grid>
      )}

      {/*  ステップ数  */}
      {params?.["step"] && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={params["step"]}
            variant={"standard"}
            label={t("juno.input.step")}
            InputProps={{ readOnly: true }}
            size={"small"}
            fullWidth
          />
        </Grid>
      )}

      {/*  guidanceScale  */}
      {params?.["guidance_scale"] && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={params["guidance_scale"]}
            variant={"standard"}
            label={t("juno.input.guidanceScale")}
            InputProps={{ readOnly: true }}
            size={"small"}
            fullWidth
          />
        </Grid>
      )}

      {/*  seed  */}
      {params?.["seed"] > 0 && (
        <Grid item xs={12} sm={grid}>
          <TextField
            value={params["seed"]}
            variant={"standard"}
            label={t("juno.input.seedSD")}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" color="secondary">
                  <CopyButtonOrIcon displayType={"icon"} textToCopy={params["seed"]} size={"small"} />
                </InputAdornment>
              ),
              readOnly: true,
            }}
            size={"small"}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default HistoryParams;
