import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Cookies from "js-cookie";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grow from "@mui/material/Grow";
import Pagination from "@mui/material/Pagination";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { GradientTypography } from "../../utils/gradientTypography";

type ImageInfo = {
  uuid: string;
  request_uuid: string;
  url: string;
  params: string;
  created_at: string;
};

interface GeneratedImageModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setImage: (image: string) => void;
}

const GeneratedImageModal: React.FC<GeneratedImageModalProps> = ({ open, setOpen, setImage }) => {
  const [images, setImages] = useState<ImageInfo[]>([]);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // 画像一覧取得
  const fetchImageList = async (pageNum: number) => {
    try {
      const url = `/api/v1/juno/gallery?page=${pageNum}&r2_image=false`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const newImages = await response.json();
      setLoading(false);
      return newImages;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  // 初期画像一覧取得
  useEffect(() => {
    if (!open) return;
    const loadInitialImages = async () => {
      setLoading(true);
      const newImages = await fetchImageList(1); // 最初のページのみロード
      if (newImages && newImages.length > 0) {
        setImages(newImages);
        setPage(1); // 最初のページに設定
        setTotalPages(newImages[0].total_pages);
      }
      setLoading(false);
    };
    loadInitialImages();
  }, [open]);

  const imageCache: Record<string, string> = {};

  // 画像をキャッシュ
  const loadImage = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (imageCache[url]) {
        resolve(imageCache[url]);
      } else {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          imageCache[url] = img.src;
          resolve(img.src);
        };
        img.onerror = reject;
      }
    });
  };

  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  // 画像のURLをキャッシュ
  useEffect(() => {
    images.forEach(async (image) => {
      const url = `${image.url}w300`;
      try {
        const cachedUrl = await loadImage(url);
        setImageUrls((prevUrls) => ({ ...prevUrls, [image.uuid]: cachedUrl }));
      } catch (error) {
        console.error("Error loading image", error);
      }
    });
  }, [images]);

  const handleChangePage = async (newPage: number) => {
    setLoading(true);
    const newImages = await fetchImageList(newPage);
    if (newImages && newImages.length > 0) {
      setImages(newImages);
      setPage(newPage);
    }
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Paper
        sx={{
          position: "relative",
          width: { xs: "100vw", md: 800 },
          height: { xs: "100vh", md: 700 },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: theme.palette.background.paper + "D9",
          backdropFilter: "blur(5px)",
          boxShadow: 24,
        }}
      >
        <IconButton sx={{ position: "absolute", top: 8, right: 8 }} size={"small"} onClick={() => setOpen(false)}>
          <CloseIcon fontSize={"small"} />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            py: 2,
          }}
        >
          <Grid container spacing={1} sx={{ px: 2, width: "100%" }}>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <GradientTypography variant={"h6"} component={"h3"} sx={{ mb: 1 }}>
                Generated Images
              </GradientTypography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                overflowY: "auto",
                height: "calc(100% - 80px)",
              }}
            >
              <Grid container spacing={1} sx={{ px: 2, width: "100%" }}>
                {images.map((image) => (
                  <Grid item xs={4} md={3} lg={2.4} key={image.uuid}>
                    <Grow in={true} timeout={1000}>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          paddingBottom: "100%",
                          overflow: "hidden",
                          transition: "transform 0.3s ease",
                          cursor: "pointer",
                          boxSizing: "border-box",
                        }}
                        onClick={() => {
                          setImage(image.url);
                          setOpen(false);
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                          e.currentTarget.style.zIndex = "100";
                          e.currentTarget.style.outline = `solid 2px ${theme.palette.primary.main}`;
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.zIndex = "1";
                          e.currentTarget.style.outline = `solid 2px transparent`;
                        }}
                      >
                        <img
                          src={imageUrls[image.uuid] ?? `${image.url}w300`}
                          alt=""
                          loading="lazy"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </Grow>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Pagination count={totalPages} page={page} onChange={(event, newPage) => handleChangePage(newPage)} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
};
export default GeneratedImageModal;
