import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Typography } from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import { MenuList, useVideo } from "../../../../context/juno/VideoContext";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const SelectMenu = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { menu, setMenu } = useVideo();

  const mainMenu = [
    { value: "t2v", label: t("juno.video.menu.t2v"), icon: <TextFieldsIcon /> },
    { value: "i2v", label: t("juno.video.menu.i2v"), icon: <ImageIcon /> },
    { value: "v2v", label: t("juno.video.menu.v2v"), icon: <MovieIcon /> },
  ];
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ButtonGroup variant="outlined" orientation={isMobile ? "vertical" : "horizontal"}>
      {mainMenu.map((item) => (
        <Button
          disableElevation
          key={item.value}
          onClick={() => setMenu(item.value as MenuList)}
          sx={{ whiteSpace: "nowrap", px: 2 }}
          startIcon={item.icon}
          size={"small"}
          variant={menu === item.value ? "contained" : "outlined"}
          disabled={item.value === "v2v"}
        >
          <Typography variant={"button"}>{item.label}</Typography>
        </Button>
      ))}
    </ButtonGroup>
  );
};
export default SelectMenu;
