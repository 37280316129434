const junoTemplate = {
  title: "Quick AI Templates",
  description:
    "Templates for complex Stable Diffusion (XL) models and LoRA, and DallE3 prompts. Please make your preferred adjustments after selection.",
  set: "Set for image generation",
  label: {
    dalle3: {
      title: "DALL·E 3",
      description:
        "Use OpenAI's DALL·E 3 model to generate images. With flexible prompts, you can easily create the images you want.",
      placeholder: "Text for the image you want to generate",
    },
    sdultra: {
      title: "Stable Image Ultra",
      description:
        "Ultra, created from the most advanced models including Stable Diffusion 3, excels in typography, complex compositions, dynamic lighting, vibrant colors, overall structure, and artwork.",
      placeholder: "Text for the image you want to generate",
    },
    flux11ProUltra: {
      title: "FLUX1.1 Pro Ultra",
      description:
        "FLUX.1 Pro Ultra is capable of generating high-resolution images up to 4MP. It also allows you to select RAW mode for natural image generation.",
      placeholder: "Text for the image you want to generate",
    },
    flux1Pro: {
      title: "FLUX1.1 Pro",
      description:
        "FLUX.1 Pro is capable of generating high-resolution images within the FLUX.1 series, with high prompt flexibility. It can also generate higher resolution images than MidjourneyV6 and SD Ultra.",
      placeholder: "Text for the image you want to generate",
    },
    flux1Dev: {
      title: "FLUX.1 Dev",
      description: "Use FLUX.1 Dev, which balances speed and image quality at a high quality.",
      placeholder: "Text for the image you want to generate",
    },
    flux1Schnell: {
      title: "FLUX.1 Schnell",
      description: "FLUX.1 Schnell is the fastest model, with lower image quality than FLUX.1 Dev.",
      placeholder: "Text for the image you want to generate",
    },
    flux1RealismLoRA: {
      title: "FLUX.1 with Realism LoRA",
      description: "Realism model of the FLUX.1 series. Capable of generating images that emphasize realism.",
      placeholder: "Text for the image you want to generate",
    },
    fluxJunoAnime: {
      title: "FLUX with STUDIO JUNO - Anime",
      description:
        "A collaborative model between FLUX and STUDIO JUNO. A style of anime learned using STUDIO JUNO's technology.",
      placeholder: "Text for the image you want to generate",
    },
    fluxJunoJapanese: {
      title: "FLUX with STUDIO JUNO - Japanese Portrait",
      description:
        "A collaborative model between FLUX and STUDIO JUNO. A style of Japanese portrait learned using STUDIO JUNO's technology.",
      placeholder: "Text for the image you want to generate",
    },
    recraftV3: {
      title: "Recraft V3",
      description:
        "Use Recraft V3 to generate high-resolution images. It is capable of generating beautiful images that are faithful to the prompt.",
      placeholder: "Text for the image you want to generate",
    },
    ideogram2: {
      title: "Ideogram 2.0",
      description:
        "A model that excels in text insertion, capable of generating images with text that is impossible with other models.",
      placeholder: "Text for the image you want to generate",
    },
    ideogram2turbo: {
      title: "Ideogram 2.0 Turbo",
      description: "Faster than Ideogram 2.0, but with lower quality. It can be used for simple text insertion.",
      placeholder: "Text for the image you want to generate",
    },
    sd3: {
      title: "Stable Diffusion 3.5 Large",
      description:
        "The latest version of Stable Diffusion, Stable Diffusion 3.5. With 8 billion parameters, excellent quality, and rapid compliance, the quality is lower than FLUX.1 Dev, but the prompt accuracy is high.",
      placeholder: "Text for the image you want to generate",
    },
    sdxl: {
      title: "SDXL 1.0",
      description:
        "Using the Stable Diffusion XL 1.0 model developed by Stability AI, this generates images. It is the most versatile and commonly used model.",
      placeholder: "Text for the image you want to generate",
    },
    art: {
      title: "2.5D Concept Art\nIllustration",
      description: "Designed to create fantastic concept art with a 2.5D model",
      placeholder: "Lots of jellyfish, colorful",
    },
    real: {
      title: "Photoreal",
      description:
        "A model designed for creating wonderfully detailed images, specializing in photo-like image generation.",
      placeholder: "Close-up of an eye, texture of wood",
    },
    cinematic: {
      title: "Cinematic",
      description: "A model and template using LoRa aimed at achieving an authentic cinematic taste.",
      placeholder: "Astronaut is looking up at a beautiful planet",
    },
    midjourney: {
      title: "Midjourney mimic",
      description:
        "Midjourney is a generative AI aimed at proficiently handling everything including photos, art, anime, manga, etc. This model faithfully reproduces Midjourney and combines the strengths of SDXL.",
      placeholder: "Text of the image you want to generate",
    },
    dreamShaperXL: {
      title: "Allmighty - \nDream Shaper XL",
      description:
        "DreamShaper is a versatile SD model designed to adeptly handle everything including photos, art, anime, manga, etc. It is designed to compete against other versatile models and pipelines like Midjourney or DALL-E.\n",
      placeholder: "Text of the image you want to generate",
    },
    juggernautXL: {
      title: "Cinematic Portraits",
      description:
        "Specializes in photorealism using the most popular model in SDXL. It has learned many cinematic images and excels at generating cinematic imagery.",
      placeholder: "Woman in a cafe",
    },
    film: {
      title: "1980s Film Camera Style",
      description: "Generates images that look like they were shot with a film camera.",
      placeholder: "Dim streetlights, silhouette",
    },
    logo: {
      title: "Logo",
      description:
        "Generates logos and icons. If you don't specify a specific prompt, a very simple logo will be generated.",
      placeholder: "Logo for a space company, purple, black, dark blue",
    },
    text: {
      title: "Text",
      description:
        "Uses LoRa to insert or generate text itself. Due to frequent failures, short English words are recommended. Input is needed like the word 'MONSTER'.",
      placeholder:
        "(Example): ((A wall inscribed with the word 'MONSTER' written in blood)), cinematic lighting, raw photo",
    },
    pixelArt: {
      title: "Pixel Art",
      description:
        "Generates pixel art in the style of 90s video games. Combine with your favorite models and finish as pixel art.",
      placeholder: "Synthwave, Jupiter",
    },
    anime: {
      title: "Anime Animagine",
      description:
        "Generates images using the classic anime illustration model SDXL. It's optimized for a wide range of famous anime characters, set the prompt as follows.\n'1girl/1boy, character name, from what series'",
      placeholder: "Female, yukata",
    },
    anime2: {
      title: "Anime Ghost XL",
      description: "Generates detailed anime images. Uses GhostXL, an SDXL version of the popular GhostMix SD1.5.",
      placeholder: "Female, colorful, black and gold",
    },
    anime3: {
      title: "Anime anima pencil XL",
      description: "A model merging popular models Animagine and Blue pencil.",
      placeholder: "Female, colorful, black and gold",
    },
    flat: {
      title: "Flat Color Illustration",
      description: "Generates flat illustrations similar to those drawn by an illustrator.",
      placeholder: "Wolf, blue theme",
    },
    utagawa: {
      title: "Utagawa Kuniyoshi Style",
      description: "Generates images in the style of the famous Japanese painter Utagawa Kuniyoshi.",
      placeholder: "Landscape, Mount Fuji",
    },
    katsushika: {
      title: "Katsushika Hokusai Style",
      description: "Generates images in the style of the famous Japanese painter Katsushika Hokusai.",
      placeholder: "Waves, Mount Fuji",
    },
    minimalist: {
      title: "Illustrator Style Landscape",
      description: "Generates minimalist designs of landscapes like those drawn by an illustrator.",
      placeholder: "Tree, mountain, lake, blue theme",
    },
    japaneseWoman1: {
      title: "Beautiful Japanese-style Woman",
      description:
        "Generates natural-looking images of a beautiful woman in Japanese style, which are typically hard to achieve with general models.",
      placeholder: "Yukata, shrine",
    },
    japaneseWoman2: {
      title: "Cinematic Portrait (Asian)",
      description:
        "A Japanese model. Generates striking images of a beautiful woman in Japanese style with high contrast.",
      placeholder: "Yukata, shrine",
    },
    japaneseWoman3: {
      title: "Urban Japanese Woman",
      description: "Generates images of a beautiful woman in Japanese style suited to urban settings.",
      placeholder: "T-shirt, medium hair",
    },
    beautiful3D: {
      title: "Beautiful 3D Woman",
      description: "Generates a beautiful 3D woman using models commonly used in generative AI.",
      placeholder: "T-shirt, medium hair",
    },
    ghibli: {
      title: "Studio Ghibli Style Landscape",
      description:
        "Generates backgrounds in the style of Studio Ghibli. If including a person, do not forget to exclude the negative prompt ((human)).",
      placeholder: "Japan",
    },
    ghibli2: {
      title: "Studio Ghibli style",
      description: "It's a Studio Ghibli-style anime style. Suitable for SDXL, allowing for more flexible prompts.",
      placeholder: "Woman looking away from the camera",
    },
    octans: {
      title: "Mystical Beautiful Sky",
      description:
        "Generates anime-style beautiful skies. If you want to include characters, don't forget to exclude the negative prompt ((human, girl)).",
      placeholder: "Japan",
    },
  },
};
export default junoTemplate;
