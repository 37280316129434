import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Box, Button, ButtonGroup, Collapse, Divider, IconButton, Slider, TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ImageUpload from "./ImageUpload";
import ParamsSDNegativePrompt from "./ParamsSDNegativePrompt";
import ParamsSDSize from "./ParamsSDSize";
import SelectModel from "./SelectModel";
import SelectPublic from "./SelectPublic";
import { useParamsContext } from "../../../../context/juno/ParamsContext";
import { useUploadImageContext } from "../../../../context/juno/UploadImageContext";

export default function ParamsSD() {
  const { t } = useTranslation();
  const { sample, setSample, step, setStep, guidanceScale, setGuidanceScale, seed, setSeed } = useParamsContext();

  const { setImageStrength, imageStrength } = useUploadImageContext();

  const icons = Array.from({ length: 4 }, (_, index) => index + 1);
  const [openAdvancedSettings, setOpenAdvancedSettings] = useState(true);
  const params = new URLSearchParams(window.location.search);
  const menu = params.get("menu") || "txt2img";
  const ai_engine = params.get("ai_engine") || "";

  const handleSampleChange = (index: number) => {
    setSample({ ...sample, [ai_engine]: index + 1 });
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 2 }}>
        <SettingsSuggestIcon sx={{ mr: 1 }} />
        <Typography variant={"subtitle1"} component={"p"}>
          {t("juno.input.basicSettings")}
        </Typography>
      </Box>

      {/* 生成枚数 */}
      <Stack spacing={1} mb={3}>
        <Box sx={{ display: "flex", flexDirection: "column" }} gap={1}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography variant={"body2"}>{t("juno.input.sample")}</Typography>
          </Box>
          <Box sx={{ display: "inline-flex", flexDirection: "row" }} gap={1}>
            <ButtonGroup variant="outlined" sx={{ width: "100%" }}>
              {icons.map((icon, index) => (
                <Button
                  disableElevation
                  key={index}
                  onClick={() => handleSampleChange(index)}
                  variant={sample[ai_engine] === index + 1 ? "contained" : "outlined"}
                  sx={{ width: "100%" }}
                >
                  <Typography variant={"button"}>{icon}</Typography>
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </Box>
      </Stack>

      <SelectModel />

      {/*img2img  */}
      {menu === "img2img" && (
        <Box mb={2}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
            <Typography variant={"body2"}>{t("juno.input.image")}</Typography>
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <ImageUpload />
          </Box>

          {/* 元画像の影響度 */}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
              <Typography variant={"body2"}>{t("juno.input.imageStrength")}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", ml: 0 }} gap={2}>
              <Slider
                value={imageStrength}
                onChange={(e, value) => setImageStrength(value as number)}
                step={0.01}
                min={0.1}
                max={1}
              />
              <Box
                sx={{
                  width: "120px",
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  px: 1,
                  py: 0.5,
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "5px",
                }}
              >
                <Typography variant={"body2"}>{imageStrength}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <ParamsSDSize />

      <Box>
        <Divider sx={{ my: 2 }} />
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ mb: 2, cursor: "pointer" }}
          onClick={() => setOpenAdvancedSettings(!openAdvancedSettings)}
        >
          {openAdvancedSettings ? <ExpandLess sx={{ mr: 1 }} /> : <ExpandMore sx={{ mr: 1 }} />}

          <Typography variant={"subtitle1"} component={"p"}>
            {t("juno.input.advancedSettings")}
          </Typography>
        </Box>
      </Box>

      <Collapse in={openAdvancedSettings} sx={{ mb: 4 }}>
        {/* ネガティブプロンプト */}
        <ParamsSDNegativePrompt />

        {/* ステップ */}
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
            <Typography variant={"body2"}>{t("juno.input.step")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
            <Slider
              value={step[ai_engine]}
              onChange={(e, value) => setStep({ ...step, [ai_engine]: value as number })}
              min={20}
              max={30}
              step={10}
            />
            <Box
              sx={{
                width: "120px",
                bgcolor: "rgba(0, 0, 0, 0.1)",
                px: 1,
                py: 0.5,
                justifyContent: "center",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <Typography variant={"body2"}>{step[ai_engine]}</Typography>
            </Box>
          </Box>
        </Box>

        {/* CFG Scale */}
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
            <Typography variant={"body2"}>{t("juno.input.guidanceScale")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
            <Slider
              getAriaValueText={(value) => `${value}px`}
              value={guidanceScale["SD"]}
              onChange={(e, value) => setGuidanceScale({ ...guidanceScale, SD: value as number })}
              step={0.5}
              min={4}
              max={14}
            />
            <Box
              sx={{
                width: "120px",
                bgcolor: "rgba(0, 0, 0, 0.1)",
                px: 1,
                py: 0.5,
                justifyContent: "center",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <Typography variant={"body2"}>{guidanceScale["SD"]}</Typography>
            </Box>
          </Box>
        </Box>

        {/* seed */}
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
          <TextField
            sx={{ mt: 1, width: "100%" }}
            label={t("juno.input.seedSD")}
            size={"small"}
            variant={"outlined"}
            value={seed[ai_engine]}
            onChange={(event) => {
              if (event.target.value.match(/^[0-9]+$/)) {
                setSeed({ ...seed, [ai_engine]: Number(event.target.value) });
              }
            }}
          />
          <Box>
            <IconButton onClick={() => setSeed({ ...seed, [ai_engine]: 0 })}>
              <RestartAltIcon />
            </IconButton>
          </Box>
        </Box>
      </Collapse>

      {/*  公開設定  */}
      <SelectPublic />
    </>
  );
}
