import React from "react";
import { Box, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

interface url_content {
  success: boolean;
  data: {
    title: string;
    url: string;
  }[];
}

interface metadata_content {
  data: {
    title: string;
    description: string;
    image: string;
    url: string;
  }[];
}

export default function UrlBox({ content }: { content: url_content }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { workspaceUuid } = useParams();
  const [metadata, setMetadata] = React.useState<metadata_content | null>(null);

  const fetchUrlMetadata = async (url: string) => {
    const response = await fetch(`/api/v1/workspace/meta?url=${url}`);
    const data = await response.json();
    return data;
  };

  React.useEffect(() => {
    if (content && content.success) {
      Promise.all(content.data.map((item) => fetchUrlMetadata(item.url))).then((dataList) => {
        const temp_metadata: metadata_content = { data: [] };
        dataList.forEach((data, index) => {
          if (data && data.success) {
            temp_metadata.data[index] = {
              title: data.data.title,
              description: data.data.description,
              image: data.data.image,
              url: content.data[index].url,
            };
          }
        });
        setMetadata(temp_metadata);
      });
    } else {
      setMetadata(null);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", justifyContent: "flex-start", p: 2 }}>
      {content && content.success ? (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
          <CheckCircleIcon fontSize="small" sx={{ color: theme.palette.success.main }} />
          <Typography variant="body1">{t("browsing.url.result.success")}</Typography>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
          <CancelIcon fontSize="small" sx={{ color: theme.palette.error.main }} />
          <Typography variant="body1">{t("browsing.url.result.error")}</Typography>
        </Box>
      )}

      {content &&
        content.data &&
        content.data.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              gap: 1,
              mb: 2,
            }}
          >
            {/* タイトル */}
            <Box sx={{ alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
              <Typography variant="subtitle1" noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.title ? item.title : "No title"}
              </Typography>
            </Box>

            {/* URL */}
            <Link href={item.url} target="_blank" underline="hover">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  minWidth: 0,
                  mb: 2,
                }}
              >
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "fit-content",
                  }}
                >
                  {item.url}
                </Typography>
                <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
              </Box>
            </Link>

            {/* 取得したMetadata */}
            {metadata && metadata.data[index] && (
              <Link
                href={item.url}
                target="_blank"
                underline="none"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    width: "100%",
                    gap: 2,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "none",
                    bgcolor: theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[100],
                  }}
                >
                  <Box sx={{ alignItems: "center", display: "flex", flexDirection: "row", gap: 2 }}>
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                      {metadata.data[index].image ? (
                        <img
                          src={metadata.data[index].image}
                          alt={metadata.data[index].title}
                          style={{ maxWidth: 120, height: "fit-content" }}
                        />
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 120,
                            height: 64,
                            bgcolor: theme.palette.grey[300],
                          }}
                        >
                          <Typography variant="body1" sx={{ color: theme.palette.grey[600] }}>
                            No image
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2,
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: "vertical",
                          textOverflow: "ellipsis",
                          // maxHeight: 120,
                        }}
                      >
                        {metadata.data[index].description.trim()}
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          flexShrink: 1,
                        }}
                      >
                        <Typography
                          variant="caption"
                          noWrap
                          sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                            flexGrow: 1,
                          }}
                        >
                          {metadata.data[index].url.replace(/^(https?:\/\/)?(www\.)?/, "").split("/")[0]}
                        </Typography>
                        <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Link>
            )}
          </Box>
        ))}
    </Box>
  );
}
