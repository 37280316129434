export const styleTranslate = (styleParam) => {
  switch (styleParam) {
    case "any":
      return "juno.input.style.recraft.any";
    case "realistic_image":
      return "juno.input.style.recraft.realisticImage";
    case "realistic_image/b_and_w":
      return "juno.input.style.recraft.realisticImageBW";
    case "realistic_image/hard_flash":
      return "juno.input.style.recraft.realisticImageHardFlash";
    case "realistic_image/hdr":
      return "juno.input.style.recraft.realisticImageHDR";
    case "realistic_image/natural_light":
      return "juno.input.style.recraft.realisticImageNaturalLight";
    case "realistic_image/studio_portrait":
      return "juno.input.style.recraft.realisticImageStudioPortrait";
    case "realistic_image/enterprise":
      return "juno.input.style.recraft.realisticImageEnterprise";
    case "realistic_image/motion_blur":
      return "juno.input.style.recraft.realisticImageMotionBlur";
    case "digital_illustration":
      return "juno.input.style.recraft.digitalIllustration";
    case "digital_illustration/pixel_art":
      return "juno.input.style.recraft.digitalIllustrationPixelArt";
    case "digital_illustration/hand_drawn":
      return "juno.input.style.recraft.digitalIllustrationHandDrawn";
    case "digital_illustration/grain":
      return "juno.input.style.recraft.digitalIllustrationGrain";
    case "digital_illustration/infantile_sketch":
      return "juno.input.style.recraft.digitalIllustrationInfantileSketch";
    case "digital_illustration/2d_art_poster":
      return "juno.input.style.recraft.digitalIllustration2DArtPoster";
    case "digital_illustration/2d_art_poster_2":
      return "juno.input.style.recraft.digitalIllustration2DArtPoster2";
    case "digital_illustration/handmade_3d":
      return "juno.input.style.recraft.digitalIllustrationHandmade3D";
    case "digital_illustration/hand_drawn_outline":
      return "juno.input.style.recraft.digitalIllustrationHandDrawnOutline";
    case "digital_illustration/engraving_color":
      return "juno.input.style.recraft.digitalIllustrationEngravingColor";
    case "vector_illustration":
      return "juno.input.style.recraft.vectorIllustration";
    case "vector_illustration/engraving":
      return "juno.input.style.recraft.vectorIllustrationEngraving";
    case "vector_illustration/line_art":
      return "juno.input.style.recraft.vectorIllustrationLineArt";
    case "vector_illustration/line_circuit":
      return "juno.input.style.recraft.vectorIllustrationLineCircuit";
    case "vector_illustration/linocut":
      return "juno.input.style.recraft.vectorIllustrationLinocut";
    default:
      return styleParam;
  }
};
