import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../context/AlertContext";
import { Box, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface ImageUploadProps {
  image: string;
  setImage: (image: string) => void;
}

const ImageUpload = ({ image, setImage }: ImageUploadProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const { setAlert } = useAlert();

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      const file = files[0];
      updateImage(file);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      updateImage(file);
    }
  };

  const updateImage = (file: File) => {
    // ファイルタイプチェック (JPGまたはPNG)
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      setAlert("error", t("juno.input.uploadError.type"));
      return;
    }

    // ファイルサイズチェック
    const maxSizeInBytes = 16000000; // 16MB
    if (file.size > maxSizeInBytes) {
      setAlert("error", t("juno.input.uploadError.size", { size: "16" }));
      return;
    }

    // 画像の幅と高さを非同期でチェック
    const checkImageSize = (file: File): Promise<void> => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        const imageURL = URL.createObjectURL(file);
        image.src = imageURL;
        image.onload = () => {
          if (image.width > 2048 || image.height > 2048) {
            setAlert("error", t("juno.input.uploadError.scale"));
            URL.revokeObjectURL(imageURL); // 使用後にURLを解放
            reject();
          } else {
            resolve();
          }
        };
        image.onerror = () => {
          URL.revokeObjectURL(imageURL); // エラー発生時にもURLを解放
          reject();
        };
      });
    };

    checkImageSize(file)
      .then(() => {
        // 画像サイズが条件を満たす場合にのみ実行
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target && e.target.result) {
            setImage(e.target.result as string);
          }
        };
        reader.readAsDataURL(file);

        // ファイル選択をリセットすることで同じ画像を選択可能にする
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      })
      .catch(() => {
        // エラー処理や画像処理の中止
      });
  };

  const handleAreaClick = () => {
    fileInputRef.current?.click();
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    setImage("");
  };

  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
          overflow: "hidden",
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleAreaClick}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            aspectRatio: "1 / 1",
            "&:hover": {
              cursor: "pointer",
            },
            position: "relative",
          }}
          ref={boxRef}
        >
          {image ? (
            <>
              <img
                src={image}
                alt="Upload Preview"
                style={{ width: "100%", height: "100%", objectFit: "cover", pointerEvents: "none" }}
                onClick={handleAreaClick}
              />

              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 8,
                  right: 8,
                  backdropFilter: "blur(5px)",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  transition: "0.3s",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                  },
                }}
                onClick={handleDelete}
              >
                <DeleteIcon style={{ color: "white" }} />
              </IconButton>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CloudUploadIcon fontSize={"large"} sx={{ mb: 2 }} />
              Upload an image
            </Box>
          )}
          <input type="file" hidden onChange={handleChange} accept="image/*" ref={fileInputRef} />
        </Box>
      </Paper>
    </Box>
  );
};
export default ImageUpload;
