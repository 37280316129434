import Box from "@mui/material/Box";
import { Popover, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useAlert } from "../../../../context/AlertContext";
import { StyledTypography } from "../../../../utils/styledTypography";
import { useUploadImageContext } from "../../../../context/juno/UploadImageContext";

const InputImagePrompt = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setImagePrompt, setImagePromptStrength, imagePrompt, imagePromptStrength } = useUploadImageContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setAlert } = useAlert();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const loadImageAsBase64 = async (file: File) => {
    // ファイルタイプチェック
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      setAlert("error", t("juno.input.uploadError.type"));
      return;
    }

    // ファイルサイズチェック
    const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
    if (file.size > maxSizeInBytes) {
      setAlert("error", t("juno.input.uploadError.size", { size: maxSizeInBytes / 1000000 }));
      return;
    }

    // 画像の幅と高さを非同期でチェック
    try {
      await checkImageSize(file);
    } catch {
      // checkImageSize で reject が呼ばれた場合、ここで処理が中断される
      return;
    }

    // 上記のチェックを全てパスした場合のみ、画像をBase64エンコード
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result as string;
      setImagePrompt(base64);
    };
    reader.readAsDataURL(file);
  };

  const checkImageSize = (file: File): Promise<void> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        if (image.width > 2000 || image.height > 2000) {
          setAlert("error", t("juno.input.uploadError.scale"));
          reject();
        } else {
          resolve();
        }
      };
    });
  };

  const uploadImagePrompt = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*"; // 画像ファイルのみを許可

    input.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      const file = target.files ? target.files[0] : null;
      if (!file) return;
      loadImageAsBase64(file);
    };

    input.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // デフォルトの挙動を防止
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // デフォルトの挙動を防止
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type.startsWith("image/")) {
        loadImageAsBase64(file);
      }
    }
  };

  const handleDeleteImage = () => {
    setImagePrompt(null); // 画像の削除
  };

  return (
    <>
      <Tooltip title={t("juno.history.ip.tooltip")}>
        <span>
          <IconButton
            onClick={(event) => {
              handlePopoverOpen(event);
            }}
          >
            {imagePrompt ? <CloudDoneIcon color={"primary"} /> : <AddPhotoAlternateIcon />}
          </IconButton>
        </span>
      </Tooltip>

      <Popover
        id="image-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box maxWidth={300}>
            <StyledTypography variant={"h6"} sx={{ mb: 2 }}>
              {t("juno.history.ip.title")}
            </StyledTypography>
            <IconButton onClick={handlePopoverClose} sx={{ position: "absolute", top: 4, right: 4 }}>
              <CloseIcon fontSize={"small"} />
            </IconButton>
            <Typography variant={"body2"} sx={{ mb: 2, color: theme.palette.text.secondary }}>
              {t("juno.history.ip.description")}
            </Typography>

            {/* Preview */}
            <Box position={"relative"} bgcolor={theme.palette.background.default} mb={2} height={300}>
              {imagePrompt ? (
                <img src={imagePrompt} alt="Preview" style={{ height: "100%", objectFit: "cover", width: "100%" }} />
              ) : (
                <Box
                  onClick={uploadImagePrompt}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <CloudUploadIcon fontSize={"large"} sx={{ mb: 2 }} />
                  Upload an image
                </Box>
              )}
              {imagePrompt && (
                <>
                  <IconButton
                    onClick={uploadImagePrompt}
                    size={"small"}
                    sx={{
                      position: "absolute",
                      top: 4,
                      left: 4,
                      opacity: 0.8,
                      backgroundColor: "background.paper",
                      "&:hover": {
                        opacity: 0.8,
                        backgroundColor: "background.paper",
                      },
                    }}
                  >
                    <ChangeCircleIcon fontSize={"small"} />
                  </IconButton>

                  <IconButton
                    onClick={handleDeleteImage}
                    size={"small"}
                    sx={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      opacity: 0.8,
                      backgroundColor: "background.paper",
                      "&:hover": {
                        color: theme.palette.error.main,
                        opacity: 0.8,
                        backgroundColor: "background.paper",
                      },
                    }}
                  >
                    <Tooltip title={t("common.delete")}>
                      <DeleteIcon fontSize={"small"} />
                    </Tooltip>
                  </IconButton>
                </>
              )}
            </Box>

            {/* Strength */}
            {imagePrompt && (
              <>
                <Typography variant={"body2"}>{t("juno.history.ip.strength")}</Typography>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <Slider
                    defaultValue={0.5}
                    step={0.05}
                    min={0.1}
                    max={1}
                    onChange={(e, value) => {
                      setImagePromptStrength(value as number);
                    }}
                    value={imagePromptStrength}
                  />
                  <Typography
                    variant={"body2"}
                    sx={{
                      ml: 1,
                      width: 30,
                      textAlign: "center",
                    }}
                  >
                    {imagePromptStrength}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
export default InputImagePrompt;
