import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import Crop75Icon from "@mui/icons-material/Crop75";
import CropDinIcon from "@mui/icons-material/CropDin";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Box, Collapse, Divider, IconButton, Slider, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AspectRatio } from "../../../../types/junoTypes";

import ImageUpload from "./ImageUpload";
import ParamsSDNegativePrompt from "./ParamsSDNegativePrompt";
import SelectPublic from "./SelectPublic";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useParamsContext } from "../../../../context/juno/ParamsContext";
import { useUploadImageContext } from "../../../../context/juno/UploadImageContext";

const aspectRatios = [
  { ratio: "1:1", icon: CropDinIcon },
  { ratio: "16:9", icon: Crop169Icon },
  { ratio: "3:2", icon: Crop32Icon },
  { ratio: "4:3", icon: Crop75Icon },
  { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
  { ratio: "2:3", icon: Crop32Icon, rotate: "90deg" },
  { ratio: "3:4", icon: Crop75Icon, rotate: "90deg" },
];

export default function ParamsIdeogram() {
  const { t } = useTranslation();
  const {
    aspectRatio,
    setAspectRatio,
    style: contextStyle,
    setStyle: contextSetStyle,
    seed,
    setSeed,
    revisedPrompt,
    setRevisedPrompt,
  } = useParamsContext();
  const { imageStrength, setImageStrength } = useUploadImageContext();

  const [openAdvancedSettings, setOpenAdvancedSettings] = useState(true);

  const params = new URLSearchParams(window.location.search);
  const ai_engine = params.get("ai_engine") || "";
  const menu = params.get("menu") || "txt2img";

  const renderAspectRatioIcons = () => {
    return aspectRatios.map(({ ratio, icon: Icon, rotate }) => (
      <Box key={ratio} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <IconButton
          onClick={() => setAspectRatio({ ...aspectRatio, [ai_engine]: ratio as AspectRatio })}
          color={aspectRatio[ai_engine] === ratio ? "primary" : "default"}
        >
          <Icon sx={{ rotate: rotate || "0deg" }} />
        </IconButton>
        <Typography
          variant={"caption"}
          onClick={() => setAspectRatio({ ...aspectRatio, [ai_engine]: ratio as AspectRatio })}
          sx={{ cursor: "pointer" }}
        >
          {ratio}
        </Typography>
      </Box>
    ));
  };

  const style: { label: string; value: "GENERAL" | "REALISTIC" | "DESIGN" | "ANIME" | "RENDER_3D" }[] = [
    { label: "general", value: "GENERAL" },
    { label: "realistic", value: "REALISTIC" },
    { label: "design", value: "DESIGN" },
    { label: "anime", value: "ANIME" },
    { label: "render3d", value: "RENDER_3D" },
  ];

  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 2 }}>
        <SettingsSuggestIcon sx={{ mr: 1 }} />
        <Typography variant={"subtitle1"} component={"p"}>
          {t("juno.input.basicSettings")}
        </Typography>
      </Box>

      {menu === "img2img" && (
        <Box mb={2}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
            <Typography variant={"body2"}>{t("juno.input.image")}</Typography>
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <ImageUpload />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
              <Typography variant={"body2"}>{t("juno.input.imageStrength")}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", ml: 0 }} gap={2}>
              <Slider
                value={imageStrength}
                onChange={(e, value) => setImageStrength(value as number)}
                step={0.01}
                min={0.1}
                max={1}
              />
              <Box
                sx={{
                  width: "120px",
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  px: 1,
                  py: 0.5,
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "5px",
                }}
              >
                <Typography variant={"body2"}>{imageStrength}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
        <Typography variant={"body2"}>{t("juno.input.aspectRatio.title")}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
        {renderAspectRatioIcons()}
      </Box>

      {/* style */}
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
        <Typography variant={"body2"}>{t("juno.input.style.title")}</Typography>
      </Box>
      <Grid container spacing={2}>
        {style.map(({ label, value }) => (
          <Grid item key={label}>
            <Button
              variant={contextStyle[ai_engine] === value ? "outlined" : "text"}
              onClick={() => contextSetStyle({ ...contextStyle, [ai_engine]: value })}
            >
              <Typography variant="caption">{t(`juno.input.style.ideogram.${label}`)}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
      {/* 拡張プロンプト */}
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mt={2}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={revisedPrompt} />}
            label={t("juno.input.dallE3RevisedPrompt")}
            onChange={(event, checked) => setRevisedPrompt(checked)}
          />
        </FormGroup>
      </Box>

      <Box>
        <Divider sx={{ my: 2 }} />
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ mb: 2, cursor: "pointer" }}
          onClick={() => setOpenAdvancedSettings(!openAdvancedSettings)}
        >
          {openAdvancedSettings ? <ExpandLess sx={{ mr: 1 }} /> : <ExpandMore sx={{ mr: 1 }} />}
          <Typography variant={"subtitle1"} component={"p"}>
            {t("juno.input.advancedSettings")}
          </Typography>
        </Box>
      </Box>

      <Collapse in={openAdvancedSettings} sx={{ mb: 4 }}>
        <ParamsSDNegativePrompt />

        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
          <TextField
            sx={{ mt: 1, width: "100%" }}
            label={t("juno.input.seedSD")}
            size={"small"}
            variant={"outlined"}
            value={seed[ai_engine]}
            onChange={(event) => {
              if (event.target.value.match(/^[0-9]+$/)) {
                setSeed({ ...seed, [ai_engine]: Number(event.target.value) });
              }
            }}
          />
          <Box>
            <IconButton onClick={() => setSeed({ ...seed, [ai_engine]: 0 })}>
              <RestartAltIcon />
            </IconButton>
          </Box>
        </Box>
      </Collapse>

      <SelectPublic />
    </>
  );
}
