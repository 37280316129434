import { useTheme } from "@mui/material";
import { useEffect, useMemo } from "react";
import ReactFlow, { Background, ReactFlowProvider, useReactFlow } from "reactflow";
import "reactflow/dist/style.css";
import { useMindMap } from "../../../context/MindmapContext";

const DownloadFlowInner = () => {
  const { fitView } = useReactFlow();
  const theme = useTheme();
  const { nodes, edges, nodeTypes, isDownloadFlowVisible } = useMindMap();

  useEffect(() => {
    if (isDownloadFlowVisible) {
      setTimeout(() => {
        fitView({ padding: 0.2 });
      }, 100);
    }
  }, [isDownloadFlowVisible, fitView]);

  const unselectedNodes = useMemo(() => {
    return nodes.map((node) => ({
      ...node,
      selected: false,
      data: {
        ...node.data,
        toolbarVisible: false,
      },
    }));
  }, [nodes]);

  const getBoundingBox = (nodes) => {
    const xPositions = nodes.map((node) => node.position.x);
    const yPositions = nodes.map((node) => node.position.y);

    const minX = Math.min(...xPositions);
    const maxX = Math.max(...xPositions);
    const minY = Math.min(...yPositions);
    const maxY = Math.max(...yPositions);

    return {
      width: maxX - minX + 0,
      height: maxY - minY + 0,
    };
  };
  const { width, height } = getBoundingBox(nodes);

  return (
    <div
      id="downloadFlow"
      style={{
        display: isDownloadFlowVisible ? "Block" : "none",
        height: `${height}px`,
        width: `${width}px`,
        position: "absolute",
        top: 0,
        left: 0,
        padding: "24px",
        background: theme.palette.background.paper,
        zIndex: -10000,
      }}
    >
      <ReactFlow
        nodes={unselectedNodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        maxZoom={1.5}
        minZoom={0.1}
        proOptions={{ hideAttribution: true }}
        fitViewOptions={{ padding: 0.2 }}
        nodesDraggable={true}
        nodesConnectable={true}
        elementsSelectable={false}
      >
        <Background />
      </ReactFlow>
    </div>
  );
};

const DownloadFlow = (props) => (
  <ReactFlowProvider>
    <DownloadFlowInner {...props} />
  </ReactFlowProvider>
);

export default DownloadFlow;
