import ReportIcon from "@mui/icons-material/Report";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Fade, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ImageInfo } from "../../types/junoTypes";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import ImageModal from "./components/image/ImageModal";

import Meta from "../../components/common/Meta";
import { aiEngineColor } from "../../utils/aiEngineColor";
import { uiHeight } from "../../utils/uiHeight";
import Like from "./components/image/Like";
import { useCommonContext } from "../../context/juno/CommonContext";

export default function GalleryAndLikes() {
  const { t } = useTranslation();
  const { filtered, setFiltered, refreshTrigger } = useCommonContext();
  const [images, setImages] = useState<ImageInfo[]>([]);
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const [page, setPage] = useState<number>(Number(params.get("page")) || 1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useCustomNavigate();
  const location = useLocation();
  const path = location.pathname.includes("/juno/likes") ? "likes" : "gallery";
  const [grow, setGrow] = useState<boolean>(false);
  const [gridNum, setGridNum] = useState<number>(3); // 画像の表示数
  const [hover, setHover] = useState<number | null>(null);

  // 画像一覧取得
  const fetchImageList = async (pageNum: number) => {
    try {
      const height = containerRef.current?.offsetHeight;
      const width = containerRef.current?.offsetWidth;
      let num = 0;

      if (height && width) {
        if (width > 600) {
          const widthNum = Math.floor(width / 230);
          const heightNum = Math.floor(height / 210);
          setGridNum(12 / widthNum);
          num = widthNum * heightNum;
        } else {
          setGridNum(4);
          num = 12;
        }
      }

      setGrow(false);
      let url = `/api/v1/juno/gallery?page=${pageNum}&num=${num}`;
      if (path === "likes") {
        url += "&likes=true";
      }
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const newImages = await response.json();
      setLoading(false);
      setGrow(true);
      return newImages;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  // 初期画像一覧取得
  useEffect(() => {
    const loadInitialImages = async () => {
      setLoading(true);
      const newImages = await fetchImageList(page);
      if (newImages) {
        setImages(newImages);
        if (newImages.length > 0) {
          setTotalPages(newImages[0].total_pages);
        }
      }
      setLoading(false);
    };
    loadInitialImages();
  }, [path, refreshTrigger]);

  const [openModal, setOpenModal] = React.useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  // 画像のURLをキャッシュ
  useEffect(() => {
    images.forEach(async (image) => {
      const url = `${image.url}public`;
      try {
        setImageUrls((prevUrls) => ({ ...prevUrls, [image.uuid]: url }));
      } catch (error) {
        console.error("Error loading image", error);
      }
    });
  }, [images]);

  const handleChangePage = async (newPage: number) => {
    setLoading(true);
    const newImages = await fetchImageList(newPage);
    if (newImages && newImages.length > 0) {
      setImages(newImages);
      setPage(newPage);
      if (path === "likes") {
        navigate(`/juno/likes?page=${newPage}`);
      } else {
        navigate(`/juno/gallery?page=${newPage}`);
      }
    }
    setLoading(false);
  };

  // 画像の読み込み状態を管理するステート
  const [loaded, setLoaded] = useState({});

  // 画像の読み込み
  useEffect(() => {
    images.forEach((image) => {
      if (image.r2_image) {
        return;
      }
      const img = new Image();
      img.src = imageUrls[image.uuid] ?? `${image.url}public`;
      img.onload = () => {
        setLoaded((prev) => ({
          ...prev,
          [image.uuid]: true,
        }));
      };
    });
  }, [images, imageUrls]);

  return (
    <>
      <Meta
        title={path === "likes" ? t("juno.likes") : t("juno.myGallery")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
        route={"Studio JUNO by Tenak AI"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: { xs: 600, sm: `calc(${uiHeight()} - 65px)` },
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", height: "100%" }} ref={containerRef}>
          <Grid container spacing={1} sx={{ px: 2, width: "100%" }}>
            {images.map((image, index) => (
              <Grid item xs={gridNum} key={image.uuid}>
                <Grow in={grow} timeout={Math.min(index * 100, 1500)}>
                  <Box sx={{ width: "100%", height: "100%" }}>
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        borderRadius: 1,
                        boxSizing: "border-box",
                        maxHeight: 300,
                        overflow: "hidden",
                        transition: "transform 0.5s ease", // transitionを設定
                      }}
                      onClick={() => {
                        setActiveIndex(index);
                        setOpenModal(true);
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.zIndex = "100";
                        e.currentTarget.style.outline = `solid 2px ${theme.palette.primary.main}`;
                        setHover(index);
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.zIndex = "1";
                        e.currentTarget.style.outline = "none";
                        setHover(null);
                      }}
                    >
                      <Fade in={hover === index} timeout={300}>
                        <Box sx={{ position: "absolute", bottom: 8, right: 8, zIndex: 2 }}>
                          <Like uuid={image.uuid} />
                        </Box>
                      </Fade>
                      <Fade in={hover === index} timeout={300}>
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 12,
                            left: 12,
                            px: 0.6,
                            py: 0.2,
                            background: alpha(aiEngineColor(image["params"]["ai_engine"]), 0.8),
                            borderRadius: 1,
                            zIndex: 2,
                          }}
                        >
                          <Typography variant={"caption"} component={"p"} sx={{ color: "white" }}>
                            {t(`juno.input.engine.${image["params"]["ai_engine"]}` as const)}
                          </Typography>
                        </Box>
                      </Fade>

                      <img
                        src={imageUrls[image.uuid] ?? `${image.url}public`}
                        alt=""
                        loading="lazy"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          filter: image.safe_level > 2 && filtered ? "blur(20px)" : "none",
                          transform: image.safe_level > 2 && filtered ? "scale(1.5)" : "none",
                          transition: "transform 0.2s ease, filter 0.2s ease", // transitionを設定
                          display: loaded[image.uuid] ? "block" : "none",
                        }}
                      />
                      {loaded[image.uuid] ? (
                        image.safe_level > 2 &&
                        filtered && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              color: "white",
                              zIndex: 2,
                              width: "100%",
                              p: 4,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  mb: 2,
                                }}
                              >
                                <ReportIcon sx={{ mr: 1 }} color={"warning"} />
                                <Typography variant={"body1"}>{t("juno.input.filtered")}</Typography>
                              </Box>
                              <Typography variant={"caption"} whiteSpace={"break-spaces"}>
                                {t("juno.input.filteredDescription")}
                              </Typography>
                            </Box>
                          </Box>
                        )
                      ) : (
                        <>
                          {image.r2_image ? (
                            <img
                              src={"/images/oversized-image.jpg"}
                              alt=""
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                          )}
                        </>
                      )}
                      {image.safe_level > 2 && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setFiltered(!filtered);
                          }}
                          sx={{ position: "absolute", top: 8, right: 8, color: "white" }}
                          aria-label="toggle blur"
                        >
                          {filtered ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Grow>
              </Grid>
            ))}
            {images.length === 0 && (
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={"h5"} sx={{ display: "flex", justifyContent: "center" }}>
                  No images
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>

        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2, height: "100%", alignItems: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && page > 0 && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
            <Pagination count={totalPages} page={page} onChange={(event, newPage) => handleChangePage(newPage)} />
          </Box>
        )}
      </Box>
      <ImageModal
        images={images}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        open={openModal}
        setOpen={setOpenModal}
      />
    </>
  );
}
